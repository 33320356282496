import React from 'react';
import styles from './BoomerColumn.module.scss'
import SimpleBoomer from '../../../Boomer/SimpleBoomer'

const BoomerColumn = ({ name, avatarPath, groups }) => {

  // const renderAssignmentCard = () => {
    
  //   if(assignmentsArr[0])return <AssignmentCard title={assignmentsArr[0].title} teacher={assignmentsArr[0].teacher.displayName} deadline={assignmentsArr[0].currentUnitData.deadline} />
    
  //   if(assignmentsArr[1])return <AssignmentCard title={assignmentsArr[1].title} teacher={assignmentsArr[1].teacher.displayName} deadline={assignmentsArr[1].currentUnitData.deadline} />
  
  // }


  return (
    <div className={styles.boomerColumn}>
      <div className={styles.columnHeader}>
        <figure className={styles.bigBoomerDecoration}>
          <SimpleBoomer mutation='dashboard'/>
        </figure>
        <p>{name}</p>
      </div>
    </div>
  );
}

export default BoomerColumn;