import { gql } from 'apollo-boost';


export const STARCAST_ENTRY_MUTATION = gql`
mutation starCastProjectUnitEntry($projectUnitEntryId: ID!, $stars: Int!) {
    starCastProjectUnitEntry(projectUnitEntryId: $projectUnitEntryId, stars: $stars) {
        errors
        projectUnit {
            id
            currentStudentNextEntryToStarCast {
                id
                entryText
                wordsCount
            }
        }
    }
}
`;