import React from 'react';
import styles from './BreakoutPartner.module.scss'
const BreakoutPartner = ({down,name,img}) => {
    return (
        <div className={down?`${styles.partnerContainer} ${styles.down}`: styles.partnerContainer}>
            <img src={img} alt='' />
            <div>
                <p className={styles.heading}>Writing Partner</p>
                <p>{name}</p>
            </div>
        </div>
    );
}

export default BreakoutPartner;