import React, { Fragment } from 'react';

import _ from 'lodash';
import grading from '../../../images/grading-alt.png';

const SideRubric = ({ rubric, showFullRubric, source = null, modifier = null, showScore = false }) => {

	return (
        <div className={`instruction u-m-base-40 ${source === 'review' && 'instruction--review'} ${modifier !== null && modifier}`}>
            <div className={`instruction__side ${source === 'review' && 'instruction__side--review'}`}>
                <img src={grading} alt="Grading" className="instruction__img" />
            </div>
            
            <div className="instruction__main">
                <p>Grading</p>
                {rubric.overallScore && showScore ?
                    <Fragment>
                        <p className="instruction__detail u-m-top-10"><span className="u-bold">{rubric.name}</span></p>
                        <p className="instruction__detail u-m-top-10">You overall score is: <span className="u-bold">{rubric.overallScore}</span></p>
                        <ul className="u-m-top-10">
                            {_.map(rubric.skills, skill => {
                                return (
                                    <li className="instruction__rubric-skill" key={`rubricSkill${skill.id}`}>
                                        {skill.description}
                                        {skill.studentScore === null ? 
                                            <span className={`instruction__rubric-skill-score`}>-</span> :
                                            <span className={`instruction__rubric-skill-score instruction__rubric-skill-score--${skill.studentScore}`}>{skill.studentScore}</span>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                        <button className="open-rubric open-rubric--centered u-m-top-10" onClick={() => showFullRubric(true)}>View Full Breakdown</button>
                    </Fragment>
                :
                    <Fragment>
                        <p className="instruction__detail u-m-top-5 u-m-base-5">Your work will be graded against the following rubric:</p>
                        <button className="open-rubric" onClick={() => showFullRubric(true)}>
                            <span className="open-rubric__name">{rubric.name}</span>
                            <span className="open-rubric__meta">View</span>
                        </button>
                    </Fragment>
                }
            </div>
        </div>
    )

}


export default SideRubric;