import React, { useEffect, useState } from 'react';

import { DRAFT, ARCHIVED, START_NEXT, SCHEDULED } from '../../../../utils/globals';
import styles from './Breadcrumbs.module.scss';

const BreadcrumbDropdown = ({ units, isStory, displayIdx }) => {

  const [selectedText, setSelectedText] = useState(units[displayIdx.current].title)
  const [show, setShow] = useState(false)
  const [displayArray, setDisplayArray] = useState(false)

  useEffect(()=>{
    if(units&&units[displayIdx.current]){
      setSelectedText(units[displayIdx.current].title)
    }
  },[displayIdx])

  useEffect(()=>{
    const itemsToDisplay = [];
    units.map((i, idx) =>{
      if (toDisplay(i.phase, idx)) { itemsToDisplay.push(i) }
    })
    setDisplayArray(itemsToDisplay)
  },[])

  const handleChange = (e) => {
    displayIdx.set(parseInt(e.target.dataset.value));
    setSelectedText(e.target.dataset.title)
    setShow(false)
  }

  const toDisplay = (phase, idx) => {
    if (phase === SCHEDULED ||
        phase === DRAFT ||
        phase === ARCHIVED ||
        phase === START_NEXT) {
        return false
    } else { return true }
  }

  return (
    displayArray.length===1 ?
    
        <span className={`${styles.breadcrumb} ${styles.current}`}>
          {displayArray[0].title}
          </span>

        :
        
          <div  className={styles.selectedPill} 
                onMouseLeave={()=>setShow(false)} 
                onClick={()=>setShow(!show)} >

            <h3> {selectedText} </h3>

            { show &&

              <div onMouseLeave={()=>setShow(false)} className={styles.dropDown}>
                {
                  units.map((i, idx) => {
                    return (
                      toDisplay(i.phase, idx) &&
                        <div 
                          className={idx === parseInt(displayIdx.current) ? 
                            `${styles.dropdownItem} ${styles.selected}` : 
                            styles.dropdownItem}
                          onClick={(e)=>handleChange(e)} 
                          data-value={i.index - 1} 
                          data-title={i.title} >{i.title}</div>
                    )
                  })
                }
              </div>

            }

          </div>
      
  )

}

export default BreadcrumbDropdown;