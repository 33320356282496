  import React from 'react';
import { Field, Form } from 'formik';

const JoinSchoolForm = ({ errors, isSubmitting, isValid, touched }) => {
    return (
        <Form className="basic-form">
            <div className="basic-form__group">
                <label className="basic-form__label u-m-base-10">Class ID:</label>
                <Field
                    className={`basic-form__text-box ${touched.classCode && errors.classCode ? 'textError' : ''}`}
                    name="classCode"
                    placeholder="Class ID" 
                    type="text"
                />
                { touched.classCode && errors.classCode && <p className="basic-form__hint">{errors.classCode}</p> }
            </div>
            <div>
                <button type="submit" className="button button--bordered button--full-width" disabled={!isValid || isSubmitting}>{ isSubmitting ? 'Loading...' : 'Join Class' }</button>
            </div>
        </Form>
    );
}

export default JoinSchoolForm;