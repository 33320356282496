import React from 'react';
import styles from './UnitCard.module.scss'

import DayCounter from '../../Structural/DayCounter/DayCounter';
import { DRAFT, WRITING, REVIEW, VOTING, TIE_BREAK, COMPLETED, ARCHIVED, START_NEXT, SCHEDULED } from '../../../../utils/globals';

import Status from './Status/Status';
import { Link } from 'react-router-dom';
import StartAndDeadline from '../../Structural/StartAndDeadline/StartAndDeadline';
import { useQuery } from '@apollo/react-hooks';
import { STATUS_VOTED_QUERY } from '../../../../queries';

const UnitCard = ({ title, deadline, startedAt, idx, numberOfUnits, phase, studentId, projectId, currentUserEntry, currentUserVoted, unitId, storyStart }) => {

  let entriesToVote, isSeen

  const isRejected = currentUserEntry && currentUserEntry.rejected && (currentUserEntry.revisionStatus === 1 || currentUserEntry.revisionStatus === 2);


  if (phase === VOTING) {
    const { data } = useQuery(STATUS_VOTED_QUERY, { variables: { id: projectId, unitId } })
    entriesToVote = data && data.project.currentUnitData.currentUserNextEntriesToVote
  }
  const actualDate = new Date()

  let days = Date.parse(deadline) - Date.parse(actualDate)

  days = Math.trunc(days / (60 * 60 * 24 * 1000))

  const renderStatus = () => {


    //order is important
    if (storyStart) return <Status unitStatus='storyStart' />
    if (phase === SCHEDULED) return <Status unitStatus='scheduled' />
    if (!currentUserEntry) { return null } // this is for drafted units
    if (phase === VOTING) {
      if (currentUserVoted) return <Status unitStatus='voted' />
      if (entriesToVote && entriesToVote.length > 0) {
        entriesToVote.forEach(elm => elm.seenByCurrentUser ? isSeen = true : isSeen = false);
        return isSeen ? <Status unitStatus='finishWriting' /> : <Status unitStatus='startVoting' />
      }
    }
    if (phase === COMPLETED) return <Status unitStatus='completed' />
    if (currentUserEntry.approved) return <Status unitStatus='approved' />
    if (currentUserEntry.rejected) return <Status unitStatus='review' />
    if (currentUserEntry.submitted) return <Status unitStatus='submitted' />
    if (currentUserEntry.hasStarted) {
      return <Status unitStatus='finishWriting' />
    }
    if (!currentUserEntry.hasStarted) {
      return <Status unitStatus='startWriting' />
    }

    if (phase === REVIEW) return <Status unitStatus='review' />
    if (phase === TIE_BREAK) return <Status unitStatus='tiebreak' /> //TODO
    if (phase === ARCHIVED) return "Archived"
    if (phase === START_NEXT) return <Status phase='draft' />

  }

  const renderDayCounter = () => {

    if (phase === COMPLETED) return null

    if (phase === SCHEDULED) return <DayCounter deadline={deadline} unit scheduledFor={startedAt} />

    if (phase === VOTING && !currentUserVoted) {
      return entriesToVote && entriesToVote.length > 0 ? <DayCounter deadline={deadline} unit /> : null
    }
    if (currentUserEntry && !currentUserEntry.submitted) return <DayCounter deadline={deadline} unit />

  }


  const annexEditParam = () => {


    if (phase === WRITING) {
      // if(!currentUserEntry.hasStarted) return `/unit/${projectId}/${idx + 1}`
      if (currentUserEntry.approved) return `/unit/${projectId}/${idx + 1}`
      if (isRejected) return `/unit/${projectId}/${idx + 1}/edit`
      if (currentUserEntry.submitted) return `/unit/${projectId}/${idx + 1}/edit`
      return `/unit/${projectId}/${idx + 1}/edit`
    }
    if (phase === REVIEW) {
      if (currentUserEntry.approved) return `/unit/${projectId}/${idx + 1}`
      if (isRejected) return `/unit/${projectId}/${idx + 1}/edit`
      if (currentUserEntry.submitted) return `/unit/${projectId}/${idx + 1}/edit`
      if (days <= 0) return `/unit/${projectId}/${idx + 1}/edit`
    }

    return `/unit/${projectId}/${idx + 1}`
  }


  return (
    <Link to={annexEditParam()} className={phase === DRAFT ? styles.none : phase === SCHEDULED? styles.inactiveLink:undefined} >


      <div className={styles.unitCard}>

        <h3>{title}</h3>
        <p className={styles.margin}>section {idx + 1}/{numberOfUnits}</p>

        <div className={styles.dateContainer}>

          {phase !== COMPLETED && <StartAndDeadline deadline={deadline} startedAt={startedAt} dark />}


          {renderDayCounter()}

          {renderStatus()}

        </div>

      </div>
    </Link>
  );
}

export default UnitCard;