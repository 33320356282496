import { gql } from 'apollo-boost';
// Queries and mutations for the user dashboard page

export const USER_PORTFOLIO_DATA = gql`
query userPortfolioDataQuery {
    currentUser {
        id
        pendingSchool {
            id
            colourCode
            location
            logoImagePath
            name
        }
        projects {
            id
            completed
            coverImageUrl
            currentUnit
            title
            type
            journal
            currentUnitData {
                id
                index
                phase
            }
            teacher {
                id
                displayName
                profilePicturePath
            }
        }
        school {
            id
            colourCode
            logoImagePath
            name
        }
    }
}
`;

export const PORTFOLIO_PROJECT_DATA = gql`
query portfolioProjectDataQuery($id: ID!) {
    project(id: $id) {
        id
        title
        bookCode
        currentUnit
        type
        journal
        teacher {
            id
            displayName
            profilePicturePath
        }
        schools {
            id
            name
        }
        book {
            id
            bookTemplate{
                id
                bee
            }
        }
        groups {
            id
            name
        }
        units {
            id
            currentUserEntry {
                id
                approved
                entryText
                hasStarted
                hasViewedWinner
                rejected
                revisionStatus
                submitted
            }
            roomPartnerEntry {
                id
                approved
                entryText
                hasStarted
                submitted
                authorId
                authorName
                authorAvatarPath
            }
            currentUserNextEntriesToVote {
                id
                seenByCurrentUser
            }
            storyStart {
                id
                authorName
                content
            }
            winner {
                id
                entryText
                name
                studentId
            }
            index
            deadline
            currentUserBoomerCredits
            currentUserVoted
            currentUserTicklesCounter(tickleTypes: [7, 6])
            editable
            phase
            teacherUnit
            title
            images
        }
    }
}
`;

export const PORTFOLIO_ENTRY_READ = gql`
query portfolioEntryReadQuery($id: ID!) {
    project(id: $id) {
        id
        completed
        title
        type
        journal
        teacher {
            id
            displayName
            profilePicturePath
        }
        schools {
            id
            name
        }
        units {
            id
            index
            instructions
            currentUserVoted
            phase
            currentUserTicklesCounter(tickleTypes: [7])
            shareGrading
            teacherUnit
            title
            deadline
            storyStart {
                id
                authorName
                content
            }
            currentUserEntry {
                id
                approved
                authorId
                currentFeedback
                entryText
                hasViewedWinner
                submitted
                author {
                    id
                    firstName
                    lastName
                }
                feedbackHistory {
                    id
                    date
                    description
                }
                rubric {
                    id
                    name
                    overallScore
                    skills {
                        id
                        description
                        studentScore
                        criterias {
                            id 
                            description
                            score
                        }                        
                    }
                }
            }
            documents {
                id
                name
                url
            }
            winner {
                id
                entryText
                name
                studentId
            }
            vocab {
                id
                word
            }
        }
    }
}
`;