import axios from 'axios';
import { store } from '../storeConfig';
import ls from 'local-storage';



const url = (env,query) => {

  return `${ls('chatUrl')}/api/students/${query}`

  // if (env === 'dev') {
  //   return `http://localhost:3007/api/students/${query}`
  // } else if (env === 'stage') {
  //   return `https://sand-schools.boomwriter.com/api/students/${query}`
  // } else if (env === 'prod') {
  //   return `https://schools.boomwriter.com/api/students/${query}`
  // }
}

const autoFeedbackEndPoint = (env,query) => {
  return url(env, `auto_feedback?entry_id=${query}`)
}

// export const getFromApi = async (query, action, object) => {


//   // const token = store.getState().userObject.token;


//   try {
//     const { data } = await axios.get( url(env, query), {headers: { 'Authorization': token} } )
//     console.log(url(env, query))
//     console.log(data)

//     return data;

//   } catch (error) {
//     console.log(error)
//   }
// }

export const getAutoFeedback = async (query) => {

  const env = ls('env');
  const token = ls('token');
  
  try {
    const data = await axios.get(autoFeedbackEndPoint(env,query), { headers: { 'Authorization': token || "" } })
    return data.data;
  } catch (error) {
    console.log(error)
  }
}

export const sendToApi = (query, newData, action, details) => {

  const token = store.getState().userObject.token;
  const env = ls('env');


  axios.post(url(env, query), newData, { headers: { 'Authorization': token || '' } })
    .then(response => {



      if (action === 'test') {

      }
    })
    .catch((error) => {

    })

}