import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Confirm from '../Shared/Confirm';
import { API_WS_ROOT } from '../../utils/chatConstants';
import ls from 'local-storage';
import styles from './NavBar.module.scss'
import ActionCableProvider from '../Cable';
import BoomerBucks from '../Shared/BoomerBucks';
import { NavLink, useHistory } from 'react-router-dom';
import ChatHolder from '../User/Chat/ChatHolder';
import SimpleBoomer from '../Boomer/SimpleBoomer';
import coins from '../../images/coins.png';
import exit from '../../images/exit.png';
import house from '../../images/house.png';
import calendar from '../../images/calendar.svg';

const NavBar = ({ chatterId, premium, firstName, avatarPath, unreadMessagesCounter }) => {
  const history = useHistory()
  const showConfirmLogout = (method, message, title) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirm
            message={message}
            onClose={onClose}
            onConfirm={method}
            title={title}
          />
        )
      }
    });
  }

  const logout = () => {
    history.push('/logout');
  }

  return (
    <nav className={styles.navBar}>

      <div className={styles.userTitle}>
        <div>
          Welcome,<span className={styles.name}>{firstName}</span>
        </div>

        <div className={styles.coinsContainer}>
          <img src={coins} alt="coins" />
          <BoomerBucks />
        </div>

      </div>

      <ul className={styles.ul}>

        <div className={styles.split1}>
          <li>
            <NavLink activeClassName={styles.activeLink} className={styles.listItem} to="/dashboard">
              <div className={styles.itemContent}>
                <img src={house} alt="home icon" />
                <p>Home</p>
              </div>
            </NavLink>
          </li>

          {premium &&
            <li className={styles.listItem}>
              <ActionCableProvider url={API_WS_ROOT + ls('token')}>
                <ChatHolder chatterId={chatterId} studentAva={avatarPath} unread={unreadMessagesCounter} />
              </ActionCableProvider>
            </li>
          }

          <li>
            <NavLink activeClassName={styles.activeLink} className={styles.listItem} to="/user/profile/boomer">
              <div className={styles.itemContent}>
                <SimpleBoomer />
                <p>Profile</p>
              </div>
            </NavLink>
          </li>

        </div>

        <div className={styles.split2}>
          {/*<li>
            <NavLink activeClassName={styles.activeLink} className={styles.listItem} to="/calendar">
              <div className={styles.itemContent}>
                <img src={calendar} alt="home icon" />
                <p>Calendar</p>
              </div>
            </NavLink>
          </li>*/}
          <li className={styles.listItem}>
            <div className={styles.itemContent} onClick={() => showConfirmLogout(logout, "Are you sure you want to logout", "Logout")} >
              <img src={exit} alt="logout icon" />
              <p>Logout</p>
            </div>
          </li>
        </div>
      </ul>
      <span className={styles.copyright}>BoomWriter Media.<br />Copyright 2022</span>
    </nav>
  )
}

export default NavBar;