import React from "react";
import { entryMarkup } from '../../../../../utils/globals';
import _ from 'lodash';
import styles from '../UnitStarcasting.module.scss';

const Entry = ({ entry, vocab }) => {


    const escapeVocab = (vocab) => {
        return vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    const highlightVocab = (text, vocab) => {
        let newText = text;
        _.map(vocab, (vocab) => {
            let exp = RegExp('\\b(' + escapeVocab(vocab.word) + ')\\b', 'gi');
            newText = newText.replace(exp, ` <span class='tagvocab'>${vocab.word}</span>`)
        });
        return newText;
    }

    return (
        <>
            <div className={styles.votingEntryText} dangerouslySetInnerHTML={entryMarkup(highlightVocab(entry.entryText, vocab))} />
        </>
    )
}

export default Entry;