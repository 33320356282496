

import React from 'react';

const WritingClubIcon = ({ fill, bg,unselectedBg }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6152 2.37824L8.68874 0.0247266C8.57776 -0.00824219 8.45947 -0.00824219 8.34845 0.0247266L0.421876 2.37824C0.17149 2.45258 0 2.6807 0 2.93945V11.569C0 12.6914 0.459134 13.8404 1.36458 14.984C2.0561 15.8575 3.01285 16.7345 4.20815 17.5909C6.21618 19.0295 8.1935 19.912 8.2767 19.9488C8.35362 19.9829 8.43611 20 8.5186 20C8.60109 20 8.68357 19.983 8.7605 19.9488C8.84366 19.912 10.8209 19.0295 12.829 17.5909C14.0243 16.7345 14.981 15.8575 15.6725 14.984C16.578 13.8404 17.037 12.6914 17.037 11.569V2.93945C17.037 2.6807 16.8656 2.45258 16.6152 2.37824Z" fill={fill} />
      <path d="M2.37695 4.45255C1.90584 4.92331 1.87624 5.6717 2.28861 6.17778C2.34825 6.25096 2.2957 6.19429 3.41001 7.30859L6.30253 4.41611L5.27597 3.37657C4.77336 2.87396 3.95558 2.87396 3.45293 3.37657L2.37695 4.45255Z" fill={fill === 'white' ? bg : unselectedBg} />
      <path d="M9.39522 13.238L13.0413 14.9395C13.2869 15.0541 13.578 15.0029 13.7696 14.8112C13.9613 14.6196 14.0125 14.3284 13.8979 14.0829L12.1964 10.4368C12.1291 10.2926 12.5378 10.7293 7.20712 5.33119L4.32031 8.21796C9.67246 13.5701 9.24814 13.1694 9.39522 13.238Z" fill={fill === 'white' ? bg : unselectedBg} />
    </svg>
  );
}

export default WritingClubIcon;