import React, { useState } from 'react';
import styles from './PickerBtn.module.scss'
import WhiteSchoolIcon from '../../../../images/newDashboard/icons/WhiteSchoolIcon'
import WritingClubIcon from '../../../../images/newDashboard/icons/WritingClubIcon'
import ArchivedAssigIcon from '../../../../images/newDashboard/icons/ArchivedAssigIcon'
import ClassmatesIcon from '../../../../images/newDashboard/icons/ClassmatesIcon'
import CurrentAssigIcon from '../../../../images/newDashboard/icons/CurrentAssigIcon'
import PastAssignIcon from '../../../../images/newDashboard/icons/PastAssignIcon'


const PickerBtn = ({color,icon,text,setFunction, term,stateTerm}) => {
  const [hovered, setHovered] = useState(false)
  
  const renderIcon = () =>{
    if(term==='school') return hovered&&stateTerm!==term?  <WhiteSchoolIcon fill={'#333333'}/> :  <WhiteSchoolIcon fill={stateTerm===term?'white':'#858585'}/>
    if(term==='writingclub') return hovered&&stateTerm!==term?  <WritingClubIcon fill={'#333333'} bg={'#701FD0'} unselectedBg='white'/> :  <WritingClubIcon fill={stateTerm===term?'white':'#858585'} bg={'#701FD0'} unselectedBg='white'/>
    if(term==='archived') return hovered&&stateTerm!==term?  <ArchivedAssigIcon fill={'#333333'}/> :  <ArchivedAssigIcon fill={stateTerm===term?'#333333':'#858585'}/>
    if(term==='classmates') return hovered&&stateTerm!==term?  <ClassmatesIcon fill={'#333333'}/> :  <ClassmatesIcon fill={stateTerm===term?'white':'#858585'}/>
    if(term==='current') return hovered&&stateTerm!==term?  <CurrentAssigIcon fill={'#333333'}/> :  <CurrentAssigIcon fill={stateTerm===term?'white':'#858585'}/>
    if(term==='past') return hovered&&stateTerm!==term?  <PastAssignIcon fill={'#333333'}/> :  <PastAssignIcon fill={stateTerm===term?'white':'#858585'}/>
  }
  if(color==='purple'){
    return (  
      <div className={stateTerm===term?`${styles.pickerBtn} ${styles.active}`:`${styles.pickerBtn}`} onClick={() => setFunction(term)} onMouseOver={()=>{setHovered(true)}} onMouseLeave={()=>{setHovered(false)}}>
        {renderIcon()}
        <p>{text}</p>
      </div>
      )
      
  }
  if(color==='darkPurple'){
    return (  
      <div className={stateTerm===term?`${styles.pickerBtn} ${styles.active} ${styles.darkPurple}`:`${styles.pickerBtn}`} onClick={() => setFunction(term)} onMouseOver={()=>{setHovered(true)}} onMouseLeave={()=>{setHovered(false)}}>
        
        {renderIcon()}
        <p>{text}</p>
      </div>
      )
  }
  if(color==='orange'){
    return (  
      <div className={stateTerm===term?`${styles.pickerBtn} ${styles.active} ${styles.orange}`:`${styles.pickerBtn}`} onClick={() => setFunction(term)} onMouseOver={()=>{setHovered(true)}} onMouseLeave={()=>{setHovered(false)}}>
        
        {renderIcon()}
        <p>{text}</p>
      </div>
      )
  }
  if(color==='blue'){
    return (  
      <div className={stateTerm===term?`${styles.pickerBtn} ${styles.active} ${styles.blue}`:`${styles.pickerBtn}`} onClick={() => setFunction(term)} onMouseOver={()=>{setHovered(true)}} onMouseLeave={()=>{setHovered(false)}}>
        
        {renderIcon()}
        <p>{text}</p>
      </div>
      )
  }
  if(color==='grey'){
    return (  
      <div className={stateTerm===term?`${styles.pickerBtn} ${styles.active} ${styles.grey}`:`${styles.pickerBtn}`} onClick={() => setFunction(term)} onMouseOver={()=>{setHovered(true)}} onMouseLeave={()=>{setHovered(false)}}>
        
        {renderIcon()}
        <p>{text}</p>
      </div>
      )
  }
  if(color==='green'){
    return (  
      <div className={stateTerm===term?`${styles.pickerBtn} ${styles.active} ${styles.green}`:`${styles.pickerBtn}`} onClick={() => setFunction(term)} onMouseOver={()=>{setHovered(true)}} onMouseLeave={()=>{setHovered(false)}}>
        
        {renderIcon()}
        <p>{text}</p>
      </div>
      )
  }
  
}
 
export default PickerBtn;