import { gql } from 'apollo-boost';

export const USER_SUBSCRIPTION = gql`
subscription userSubscription($studentId: ID!) {
    newItems(studentId: $studentId) {
        type
        activity {
            actionItemName
            amount
            badge {
                earnedOn
                equipped
                id
                levelName
                message
                name
                url
                status
            }
            createdAt
            id
            verb
        }
        student {
            id
            lastName
            boomerBucks
        }
    }
}
`;

export const TICKLES_SUBSCRIPTION = gql`
subscription ticklesSubscription($projectUnitId: ID!) {
    tickles(projectUnitId: $projectUnitId) {
        tickle {
            id
            author {
                id
                firstName
                avatar
                avatarPath
                rank {
                    id
                    url1
                    url2
                }
                currentBadge{
                    id
                    url
                }
            }
            projectUnit {
                index
                phase
                project {
                    id
                }
            }
            createdOn
            isSystemTickle
            text
            tickleType
        }
    }
}
`;