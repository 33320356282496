import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { PROJECT_MAIN_QUERY, VIEWED_WINNER_MUTATION} from '../../../queries';
import coins from '../../../images/coins.png';
import group from '../../../images/boomer-group.png';
import youWon from '../../../images/you-won.png';
import youWonMobile from '../../../images/you-won-mobile.png';

const ViewWinner = ({ project, userEntry, winner }) => {
    const [showWinnerOverlay, setShowWinnerOverlay] = useState(true);

    const [viewWinner] = useMutation(VIEWED_WINNER_MUTATION, {
        variables: {
            entryId: userEntry.id
        },
        refetchQueries: () => [{ query: PROJECT_MAIN_QUERY, variables: { id: project.id } }],
        onCompleted: () => {
            setShowWinnerOverlay(false);
        }
    });
    
    return (
        <div className={`confirm-overlay ${showWinnerOverlay ? null : 'confirm-overlay--hide'}`}>
            <div className="confirm-overlay__body confirm-overlay__body--winner">
                {Number(winner.studentId) === userEntry.authorId ? 
                <Fragment>
                    <img className="u-hidden-mobile confirm-overlay__hero" src={youWon} alt="You Voted" />
                    <img className="u-only-mobile confirm-overlay__hero" src={youWonMobile} alt="You Voted" />
                    <div className="view-winner-overlay__inner">
                        <p>Congratulations, Your entry won!</p>
                        <p className="confirm-overlay__earnings">You earned <img src={coins} className="confirm-overlay__earnings-coins" alt="BoomerBucks"/>30</p>
                        <button className="button button--bordered" onClick={() => viewWinner()}>Great!</button>
                    </div>
                </Fragment>
                :
                <Fragment>
                    <div className="view-winner-overlay__inner">
                        <p className="u-m-base-20">{winner.name} won!</p>
                        <button className="button button--bordered" onClick={() => viewWinner()}>Read the winner</button>
                    </div>
                </Fragment>
                }
                <img className="confirm-overlay__lads" src={group} alt="Boomer Group" />
            </div> 
        </div>
    );
}

export default ViewWinner;
