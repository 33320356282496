
export const userObject = (state={}, action) => {

  switch(action.type){

    case 'INITIATE_USER':
      return action.payload

    case 'UPDATE_USER_KEY':
      return {...state, [action.key]: action.payload}

    default:
      return state
  }
}
