import React from 'react';
import { Link } from 'react-router-dom';

import { APP_ENDPOINT } from '../../utils/endPoints';

const ErrorPage = () => {
    return (
        <div className="error-page">
            <div className="error-page__inner">
                <p className="error-page__title">Uh-oh!</p>
                <p className="error-page__subtitle">Sorry, that didn't quite go as we planned</p>
                <img src="/img/error.png" alt="Error!" className="error-page__image" />
                <p className="error-page__caption">*Bad as in bad... not good bad.</p>
                <p className="error-page__body">As you read this our developers will be frantically working to put things right. Rest assured that we won’t feed them until they have fixed the error.</p>                    
                <Link to={"/"} className="button" > Home </Link>
            </div>
        </div>
    ); 
}

export default ErrorPage;
