



import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Tooltip } from 'react-tippy';
// import styles from './EntrySubHeader.module.scss'
import 'react-tippy/dist/tippy.css';

import arrowLeft from '../../../../../../images/arrowLeftPurple.svg';
import arrowRight from '../../../../../../images/arrowRightPurple.svg';
import caution from '../../../../../../images/caution.png';
import check from '../../../../../../images/check-green.png';
import { PROJECT_UPDATE_MUTATION } from '../../../../../../queries';
import { useIdleTimer } from 'react-idle-timer'
import { DRAFT, STORY_WRITER, WRITING, REVIEW, VOTING, TIE_BREAK, COMPLETED, ARCHIVED, START_NEXT, SCHEDULED } from '../../../../../../utils/globals';
import star from '../../../../../../images/star.png';



const EntrySubHeader = ({ blurred, entry, max, title, toggleShowHistory, currentUserEntry, showIdleModal,mouseOutSave,setMouseOutSave,overallGradingScore, scrollUnits, units, isReview, unitIdx, setEntry, showWinner, setShowWinner, isStoryStart, isOpenBreakout, setSelectedTopTabAnimated, edit }) => {
  const callbackRef = useRef(null);
  const [ showArrows, setShowArrows ] = useState(false)
  const [ canScroll, setCanScroll] = useState({back:false, forward:false})
  const { approved, author, rejected, revisionStatus, submitted, wordsCount } = currentUserEntry;
  const isRejected = rejected && (revisionStatus === 1 || revisionStatus === 2);
  const [state, setState] = useState({
    savedEntry: entry.text,
    saving: false,
  });
  let unitsArray 
  const [ userVoted, setUserVoted ] = useState(true)

  const handleOnIdle = event => {
    // console.log('user is idle', event)
    checkSaveNeeded()
  }

  const countWordsLocal = (entryText) => {
    entryText = entryText.replace(/<[^>]*>/g, '');
    var matches = entryText.match(/[\w\d\’\'-]+/gi);
    return matches ? matches.length : 0;
  }


  
  useEffect(()=>{  
    
    unitsArray = units.filter(i => 
    i.phase !== SCHEDULED &&
    i.phase !== DRAFT &&
    i.phase !== ARCHIVED &&
    i.phase !== START_NEXT
    )
    
    // isolate indexes, disregard all other keys
    const unitsArray2=unitsArray.map(i=>i.index-1)

    //ascertain which index within the new array is the index of the currently displayed unit
    const currentIdx = unitsArray2.findIndex(i => parseInt(i)===parseInt(unitIdx))

    //check index againt array to see if it is possible to scroll in either direction
    let forward
    let back
    if(unitsArray.length>1){
      if(currentIdx<unitsArray.length-1){forward=true}else{forward=false}
      if(currentIdx>0){back=true}else{back=false}
      setCanScroll({forward, back})
      setShowArrows(true)
    }


    //update userVoted variable when unitIdx changes

    if(units[unitIdx]&&units[unitIdx]){
      setUserVoted(units[unitIdx].currentUserVoted)
      // userVoted=units[unitIdx].currentUserVoted;

    }

  },[unitIdx])



  const renderWinner = (unit, projectType) => {
    const { currentUserEntry, index, winner } = unit;
    if (!isStoryStart) {
      if (index === 1 && projectType === STORY_WRITER) {
        return null;
      }
      if (Number(currentUserEntry.author.id) === Number(winner.studentId) || !winner.studentId) {
        return null
      }
      return (
        <>
          {showWinner &&
            <div className="winner-name">
              <p className='winner-name_chapter'>{title}</p>
              <p>By {winner.name}</p>
            </div>
          }
        </>
      );
    }
    return null;
  }

  const renderTabs = (unit, projectType) => {
    const { currentUserEntry, currentUserNextEntriesToVote, index, winner } = unit;
    if(!isStoryStart){
        if (index === 1 && projectType === STORY_WRITER) {
            return null;
        }
        if (Number(currentUserEntry.author.id) === Number(winner.studentId)) {
            return (
                <div className="portfolio-read-chapter__winning-entry"><img className="portfolio-read-chapter__star" src={star} alt="star" />You have Won!</div>
            )
        } if( !winner.studentId ){
          return(
            <h3 className="my-entry">My entry</h3>
          )
        }
        return (
          <>
            <span className="winning-entry-slider">
                <button 
                    onClick={() => {setShowWinner(true)}} 
                    className={`button button--white ${showWinner ? 'active' : ''}`}
                >
                    Winning Entry
                </button>
                <button 
                    onClick={() => {setShowWinner(false)}} 
                    className={`button button--white ${showWinner ? '' : 'active'}`}
                >
                    My Entry
                </button>
            </span>
          </>
        );

    } else if (isStoryStart) {

      return(
        <div className="author-name">
          <p>Story start</p>
          <p>By {units[0].storyStart.authorName}</p>
        </div>
      )

    }
}
  


  // eslint-disable-next-line no-unused-vars
  const { getLastActiveTime } = useIdleTimer({
    timeout: 5000,
    onIdle: handleOnIdle,
    debounce: 500
  })

  useEffect(()=>{
    
    if(mouseOutSave){
      checkSaveNeeded()
      setMouseOutSave(false)
    }else{
      setMouseOutSave(false)
    }

  },[mouseOutSave])


  const [saveEntryMutation] = useMutation(PROJECT_UPDATE_MUTATION, {
    onCompleted: () => {
      setState({
        savedEntry: entry.text,
        saving: false,
      })
    },
    variables: {
      id: entry.id,
      entryText: entry.text
    },
  });


  useEffect(() => {
    callbackRef.current = checkSaveNeeded;
  }, [entry.text, state.savedEntry]);

  useEffect(() => {
    if (blurred) {
      callbackRef.current();
    }
  }, [blurred]);

  const checkSaveNeeded = () => {
    if ((entry.text !== state.savedEntry) && (countWordsLocal(entry.text) !== 0) && ((countWordsLocal(entry.text) <= 5000))) {
      saveEntry();
    }
  };

  const saveEntry = () => {
    saveEntryMutation();
    setState({
      ...state,
      saving: true,
    });
  }

  showIdleModal && saveEntry()


  const getReasonSaveDisabled = () => {
    if (countWordsLocal(entry.text) === 0) {
      return "You cannot save a blank entry";
    }
    if (countWordsLocal(entry.text) > 5000) {
      return "Your entry is over the word limit";
    }
    return "Your entry has been saved!";
  }

  return (
    <div className={isOpenBreakout?`project-entry-header openBreakout` : 'project-entry-header'}>

      <div className="project-entry-header__left">
        <div className="scroll-arrows">
          {showArrows&& <img className={canScroll.back ? "arrow" :  "arrow disable" }onClick={()=>{scrollUnits('back'); setSelectedTopTabAnimated(false)}} src={arrowLeft} alt="arrow left"/>}
          <p className="project-entry-header__title">{title}</p>
          {showArrows && <img className={canScroll.forward ? "arrow" :  "arrow disable" }onClick={()=>{scrollUnits('forward'); setSelectedTopTabAnimated(false)}} src={arrowRight} alt="arrow right"/>}
        </div>

        <div className="switch-buttons">
          {renderTabs(units[unitIdx], 1)}
        </div>

      </div>

      <div className="project-entry-header__right">

        {/* Start Pills */}
        {submitted && !isRejected && !approved && revisionStatus !== 3 && <p className="u-pill u-pill--success u-m-base-5"><img className="u-pill__image" src={check} alt="check" />Entry Submitted</p>}

        {isRejected && !approved && !isReview && <p className="u-pill u-pill--alert u-m-base-5"><img className="u-pill__image" src={caution} alt="caution" />Revisions Required</p>}

        {!approved && revisionStatus === 3 && <p className="u-pill u-pill--pending u-m-base-5">Revision Submitted</p>}

        {approved && !overallGradingScore && !isReview && <p className="u-pill u-pill--success u-m-base-5"><img className="u-pill__image" src={check} alt="check" />Entry Approved</p>}

        {/* Finish Pills */}

        {/* Word Counter */}
        {!approved && !isReview && 
          <p className={`project-entry-header__count ${countWordsLocal(entry.text)> max && 'project-entry-header__count--alert'}`}>
            Word Count: <span className="project-entry-header__numbers">{countWordsLocal(entry.text)}/{max}</span>
          </p>
        }
        {isReview && 
          <>
            <p className={`project-entry-header__count__review ${countWordsLocal(entry.text)> max && 'project-entry-header__count--alert'}`}>
              Word Count: <span className="project-entry-header__numbers">{countWordsLocal(entry.text)}/{max}</span>
            </p>
            {units[unitIdx].winner.studentId ? 
            
                userVoted?
                    <p className={`project-entry-header__voted`}><img className="report-read__status-img" src={check} alt="Yes"/>You voted</p>
                :
                    <p className={`project-entry-header__voted ${'red'}`}>You did not vote</p>

            :

              <h5 className="project-complete">In Review</h5>
            }
          </>
        }


        {(!isReview && ((!approved) || isRejected) && !currentUserEntry.submitted || isRejected && !isReview) ?
          <div className={'flexBtns'}>
            {
              state.saving ? <button className="entry-history entry-history--save">Saving...</button> :
                ((entry.text !== state.savedEntry) && (countWordsLocal(entry.text) !== 0) && (wordsCount <= max)) ?
                  <button className="entry-history entry-history--save" onClick={() => saveEntry()}>Save</button> :
                  (
                    <Tooltip
                      title={getReasonSaveDisabled()}
                    >
                      <button className="entry-history entry-history--save entry-history--disabled" disabled>Save</button>
                    </Tooltip>
                  )
            }
            <button className="entry-history" onClick={() => toggleShowHistory()}><span className={`entry-history__icon ${state.saving && 'green-pulse'}`}></span>Entry History</button>
          </div>
          :

          null

        }
      </div>
      {renderWinner(units[unitIdx], 1)}
    </div>
  );
}

export default EntrySubHeader;
