import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import moment from 'moment';

import { BOOMER_BUCKS_ACTIVITY_FEED_QUERY } from '../../queries';
import MainLoader from './MainLoader';
import coins from '../../images/coins.png';

const ActivityFeed = () => {
    const { data, error, loading } = useQuery(BOOMER_BUCKS_ACTIVITY_FEED_QUERY);

    const renderList = () => {
        if (loading) {
            return <MainLoader />
        }
        if (error) {
            return <p>{error.message}</p>
        }
        if (data) {
            const { activities } = data.currentUser;
            if (activities.length === 0 && activities[0] !== null) {
                return (
                    <li className="activity-feed__item activity-feed__item--placeholder">
                        <p className="activity-feed__text">You don't have any activity yet.</p>
                    </li>
                );
            }
            return _.map(_.reverse(_.sortBy(activities, ['createdAt'])), activity => {
                return (
                    <li key={`activity-${activity.id}`} className="activity-feed__item">
                        <p className="activity-feed__text">You {activity.verb} {activity.badge ? 'the' : <img src={coins} className="activity-feed__coins-img" alt="coins" />}<strong>{activity.amount}</strong> {activity.actionItemName}</p>
                        <p className="activity-feed__time">{moment(activity.createdAt).fromNow()}</p>
                    </li>
                );
            })
        }
        return null;
    }
    
    return (
        <div className="activity-feed">
            <p className="activity-feed__header">Recent Activity</p>
            <ul className="activity-feed__list">
                {renderList()}
            </ul>
        </div>
    )
}

export default ActivityFeed;
  