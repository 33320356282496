import styles from './Calendar.module.scss'

export const eventClick = (info, env) => { //redirect

  const { projectId,unitIdx } = info.event.extendedProps

  if (env === 'dev') {
    window.location = `http://localhost:3000/project/${projectId}/${unitIdx}/edit`
  } else if (env === 'stage') {
    window.location = `https://sand-students.boomwriter.com/project/${projectId}/${unitIdx}/edit`

  } else if (env === 'prod') {
    window.location = `https://students.boomwriter.com/project/${projectId}/${unitIdx}/edit`
  }
  info.el.style.backgroundColor = 'red';
}


export const initCalendarStudent = (classes, styles, setInitialEvents, setEvents, setClassLetters) => {
  const initEv = [];
  const keyLetters = [];

  classes.forEach(item => {

    const firstLetter = item.name.charAt(0);
    const keyLetter =
      !keyLetters.includes(firstLetter) ? firstLetter
        : !keyLetters.includes(`${firstLetter}${firstLetter}`) ? `${firstLetter}${firstLetter}`
          : !keyLetters.includes(`${firstLetter}${firstLetter}${firstLetter}`) ? `${firstLetter}${firstLetter}${firstLetter}`
            : `${firstLetter}${firstLetter}${firstLetter}${firstLetter}`;
    keyLetters.push(keyLetter);

    
   
    if (item.projects) {
      item.projects.filter((elm) => !elm.completed&&!elm.archived).sort((a,b)=>b.id-a.id).forEach(activity => {

        const determineType = () => {
          if (activity.type === 1) {
            return activity.journal ? styles.journal : styles.assignment
          }
          if (activity.type === 2) {
            return styles.journal;
          }
          if (activity.type === 3) {
            return styles.story;
          }

        }

        const sectionIndex = activity.currentUnitData.index
        const sectionTitle = activity.title

        const classKey = keyLetter.length > 1 ? `<p class=${styles.toThePower}>${keyLetter.charAt(0)}<sup>${keyLetter.length}</sup></p>` : keyLetter
        const payload = {
          title: sectionTitle,
          description: `${activity.currentUnitData.title} (S-${sectionIndex})`,
          id: activity.currentUnitData.id,
          projectId: activity.id,
          start: activity.currentUnitData.startedAt,
          end: activity.currentUnitData.deadline,
          classId: item.id,
          unitIdx:activity.currentUnitData.index,
          keyLetter: classKey,
          status: activity.sectionStatus,
          classType:item.classType,
          className: `${styles.event} ${determineType()}`,

        }


        initEv.push(payload)
      })
    }
  })
  setInitialEvents(initEv)
  setEvents(initEv)
  setClassLetters(keyLetters)
}

export const addDetailsElements = ({ event, el }) => {

  if(event._def.extendedProps.classType && event._def.extendedProps.classType >= 9 ){
    let ribbon = document.createElement('div')
    ribbon.classList.add(styles.aRibbon)
    el.appendChild(ribbon);

  }

  var newDiv = document.createElement('div')
  newDiv.innerHTML = event._def.extendedProps.keyLetter;
  // newDiv.innerHTML = `<p class=${styles.toThePower}>x<sup>2</sup></p>`;
  newDiv.classList.add(styles.keyDot)
  el.appendChild(newDiv);

  if (el.children[0].classList[0] === 'fc-daygrid-event-dot') { return }

  const textDiv = el.children[0].children[0].children[0];
  var newSpan = document.createElement('span')
  newSpan.innerHTML = event._def.extendedProps.description;
  textDiv.appendChild(newSpan);
};
