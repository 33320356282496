import React from 'react';
import styles from './DayCounter.module.scss'
import clockIcon from './../../../../images/newDashboard/icons/clockIcon.png'
import clockIconUnit from './../../../../images/newDashboard/icons/clockIconUnit.svg'

const DayCounter = ({ deadline, unit, scheduledFor }) => {
  const actualDate = new Date()

  let days = Date.parse(deadline) - Date.parse(actualDate)

  days = Math.ceil(days / (60 * 60 * 24 * 1000))

  

  

  const renderText = () => {

    if(scheduledFor){
      days = Date.parse(scheduledFor) - Date.parse(actualDate)
      days = Math.ceil(days / (60 * 60 * 24 * 1000))
      return `Unit starts in ${days} days`
    }else if(days >= 0 ){
      return `${days} days left`
    }else{
      return 'Due date passed'
    }
    
  }

  

  return (
    <div className={unit ? `${styles.unitTime}` : styles.time}>
      <img src={unit ? clockIconUnit : clockIcon} alt='' />
      {/* {days >= 0 ? `${days} days left` : 'Due date passed'} */}
      {renderText()}
    </div>
  );
}

export default DayCounter;