import React, { useState, useContext } from 'react';
import { useSubscription } from '@apollo/react-hooks';
import _ from 'lodash';

import { BOOMER_BUCKS_ACTIVITY_FEED_QUERY, USER_SUBSCRIPTION, BOOMER_STORE_QUERY, SIMPLE_BOOMER_QUERY, BOOMER_BUCKS_QUERY } from '../../queries';
import CloseButton from '../../images/close-purple.png';
import coins from '../../images/coins.png'

import { PointsContext } from '../../utils/pointsProvider'

const UserSubcription = ({ studentId }) => {
    if (!studentId) {
        return null;
    }

    const [badge, setBadge] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [showBucksNotification, setShowBucksNotification] = useState(false);
    const [bucksAmount, setBucksAmount] = useState(null)
    const pointsContext = useContext(PointsContext)
    const {points, setPoints} = pointsContext;

    useSubscription(USER_SUBSCRIPTION, {
        variables: {
            studentId
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const { newItems } = subscriptionData.data;
            if (newItems.type === "balance_update" || newItems.type === "new_badge") {
                if (newItems.activity.actionItemName === "teacher awarded bonus") {
                    setBucksAmount(newItems.activity.amount)
                    setShowBucksNotification(true)
                    return
                }
                const activityQuery = client.readQuery({ query: BOOMER_BUCKS_ACTIVITY_FEED_QUERY });
                if (activityQuery) {
                    const newActivity = newItems.activity;
                    client.writeQuery({ //TODO fix this
                        query: BOOMER_BUCKS_ACTIVITY_FEED_QUERY,
                        data: {
                            currentUser: {
                                ...activityQuery.currentUser,
                                activities: [
                                    ...activityQuery.currentUser.activities,
                                    newActivity
                                ]
                            }
                        }
                    });
                };
                if (newItems.type === "balance_update") {
                    setPoints(newItems.activity.amount)
                }
            }
            if (newItems.type === "new_badge") {
                const newBadge = newItems.activity.badge;
                const boomerStoreQuery = client.readQuery({ query: BOOMER_STORE_QUERY});
                const boomerQuery = client.readQuery({ query: SIMPLE_BOOMER_QUERY });
                if (boomerStoreQuery) {
                    const badges = _.filter(boomerStoreQuery.currentUser.badgesList, badge => { return badge.id !== newBadge.id});
                    badges.push(newBadge);
                    client.writeQuery({
                        query: BOOMER_STORE_QUERY,
                        data: {
                            currentUser: {
                                ...boomerStoreQuery.currentUser,
                                badgesList: badges
                            }
                        }
                    });
                }
                if (newBadge.equipped && boomerQuery) {
                    client.writeQuery({
                        query: SIMPLE_BOOMER_QUERY,
                        data: {
                            currentUser: {
                                ...boomerQuery.currentUser,
                                currentBadge: newBadge
                            }
                        }
                    });
                }
                setBadge(newBadge)
                setShowNotification(true)
            }
            client.writeData({ data: { boomerBucks: newItems.student.boomerBucks } });
        }
    });

    if (showNotification && badge) {
        return (
            <div onClick={() => setShowNotification(false)} className="badge-info badge-info--notification">
                <div className="badge-info__badge" style={{backgroundImage: 'url(' + badge.url + ')'}}></div>
                <div className="badge-info__inner">
                    <h1 className="badge-info__title">Congratulations!</h1>
                    <p className="badge-info__desc">You earned the {badge.levelName}!</p>
                </div>
                <div className="badge-info__close">
                    <img src={CloseButton} alt="Close" className="badge-info__close-img" />
                </div>
            </div>
        )
    }
    if (showBucksNotification && bucksAmount) {
        return (
            <div onClick={() => setShowBucksNotification(false)} className="badge-info badge-info--notification">
            <img src={coins} className="badge-info__coins"/>
            <div className="badge-info__inner">
                <h1 className="badge-info__title">Congratulations!</h1>
                <p className="badge-info__desc">Your teacher awarded {bucksAmount} bonus Boomer Bucks!</p>
            </div>
            <div className="badge-info__close">
                <img src={CloseButton} alt="Close" className="badge-info__close-img" />
            </div>
        </div>
        )
    }
    return null;
}

export default UserSubcription;