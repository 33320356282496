import React, {  useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import styles from './GroupDashboard.module.scss'
import { useQuery } from '@apollo/react-hooks';
import { GET_GROUP_DATA } from '../../../queries';
import PickerBtn from '../Structural/PickerBtn/PickerBtn';
import MainLoader from '../../Shared/MainLoader';
// import AssignmentCardRenderer from './AssignmentCardRenderer/AssignmentCardRenderer'
import Classmates from './Classmates/Classmates';
import Breadcrumbs from '../Structural/Breadcrumbs/Breadcrumbs';
import lightPurpleDecoration from './../../../images/newDashboard/decorations/lightpurpleDecoration.png'
import ProjectCardRenderer from './ProjectCardRenderer/ProjectCardRenderer';
// import { gql } from 'apollo-boost';

const ProjectDashboard = () => {
  // const client = useApolloClient();
  const { classId, term } = useParams()
  const { data, loading } = useQuery(GET_GROUP_DATA, { variables: { classId } })
  const [stateTerm, setStateTerm] = useState(term ? term : 'current')
  
  // useEffect(()=>{
  //   if(currentProjects){
  //     console.log(currentProjects)
  //     console.log(currentProjects.sort((a,b)=> 
  //     new Date(b.units[b.units.length-1].createdAt).getTime() - new Date(a.units[a.units.length-1].createdAt).getTime()))
  //     currentProjects.map(proj => {
  //       console.log(proj.units[proj.units.length-1].createdAt)
  //       // console.log(new Date(proj.currentUnitData.createdAt).getTime())
  //     })
  //   }
  // },[data])
  

  const sortByNewestUnit = (array) =>{
    return array.sort((a,b)=> 
    new Date(b.units[b.units.length-1].createdAt).getTime() - new Date(a.units[a.units.length-1].createdAt).getTime())
  }

  if (loading||!data) return <MainLoader />

  let archivedProjects=sortByNewestUnit(data.group.projects.filter((elm)=>elm.archived))
  let completedProjects=sortByNewestUnit(data.group.projects.filter((elm)=>elm.completed))
  let currentProjects=sortByNewestUnit([...data.group.projects.filter((elm) => !elm.completed&&!elm.archived&&elm.type !== 7), ...data.group.projects.filter((elm) => elm.type === 7)])

  

  if (data.group === null) { //If group is not found (wrong id) it displays this block of code
    return (
      <div className={`${styles.adaptToScreen} ${styles.center}`}>
        <h1>Class not found</h1>
        <Link to='/dashboard'>Go back to dashboard</Link>
      </div>
    )
  }
  if (!data.group.membershipStatus){
    return (
      <div className={`${styles.adaptToScreen} ${styles.center}`}>
        <h1>You are not part of this classroom</h1>
        <Link to='/dashboard'>Go back to dashboard</Link>
      </div>
    )
  }
  return (

    <div className={styles.adaptToScreen}>
      <div className={styles.dashboardContainer}>
        <div>
          <Breadcrumbs classData={{ classId: data.group.id, name: data.group.name }} />
        </div>

        <div className={styles.pickerBtnContainer}>

          <Link to={`/class/${classId}/current`}>
            <PickerBtn text={'Current assignments'} term='current' stateTerm={stateTerm} setFunction={setStateTerm} color={'purple'} />
          </Link>

          <Link to={`/class/${classId}/past`}>
            <PickerBtn text={'Past assignments'} term='past' stateTerm={stateTerm} setFunction={setStateTerm} color={'blue'}/>
          </Link>

          <Link to={`/class/${classId}/archived`}>
            <PickerBtn text={'Archived assignments'} term='archived' stateTerm={stateTerm} setFunction={setStateTerm} color={'grey'}/>
          </Link>

          <Link to={`/class/${classId}/classmates`}>
            <PickerBtn text={'Classmates'} term='classmates' stateTerm={stateTerm} setFunction={setStateTerm} color={'orange'}/>
          </Link>

        </div>

        <div className={styles.mainDisplay}>

          {stateTerm === 'current' && <ProjectCardRenderer projects={currentProjects} term={'current'} myStudentId={data.currentUser.id}/>}
          {stateTerm === 'past' && <ProjectCardRenderer projects={completedProjects} term={'past'}/>}
          {stateTerm === 'archived' && <ProjectCardRenderer projects={archivedProjects} term={'archived'}/>}
          {stateTerm === 'classmates' && <Classmates students={data.group.students} />}
        </div>

      </div>
    </div>
  );
}

export default ProjectDashboard;