import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './storeConfig';

// import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/react-hooks';
import {PointsProvider} from './utils/pointsProvider'
// Our Stuff
import { Client } from './Client';
// import ErrorBoundary from './BugSnag';

// Import components
import Router from './components/Router';
// import ErrorPage from './components/Shared/ErrorPage';
    //Page to display when a js exception triggers

// Import assets
import './styles/main.scss';

ReactDOM.render(
    // <ErrorBoundary FallbackComponent={ErrorPage}> 
        
        <Provider store={store}>
            <ApolloProvider client={Client}>
                <PointsProvider>
                    <Router />
                </PointsProvider>
            </ApolloProvider>
        </Provider>

    // </ErrorBoundary>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();


