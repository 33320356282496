import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ls from 'local-storage';

import SimpleBoomer from '../Boomer/SimpleBoomer';

import { Client } from '../../Client';

const Alert = ({ buttonMessage, message, onClose, title }) => (
    <ApolloProvider client={Client}>
        <div 
            onClick={(event) => {
                if (event.target.classList.contains('confirm-overlay')) {
                    onClose();
                }
            }} 
            className="confirm-overlay"
        >
            <div className="confirm-overlay__body">
                {
                    ls('token') ? (
                        <div className="confirm-overlay__boomer">
                            <SimpleBoomer mutation="in-confirm" />
                        </div>
                    ) : null
                }
                
                <button onClick={onClose} className="confirm-overlay__close-button">
                    <span className="confirm-overlay__close-icon">x</span>
                </button>
                {title && <p className="confirm-overlay__title">{title}</p>}
                <p className="confirm-overlay__message">{message || 'Are you sure?'}</p>
                <div className="confirm-overlay__buttons">
                    <button className="button button--bordered confirm-overlay__button" onClick={onClose}>{buttonMessage || 'OK'}</button>
                </div>
            </div>
        </div>
    </ApolloProvider>
);

export default Alert;
