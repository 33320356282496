

import React from 'react';

const PastAssignIcon = ({ fill }) => {
  return (
    <svg width="18" height="23" viewBox="0 0 18 23" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M11.207 0H2.70703C1.53828 0 0.592656 1.035 0.592656 2.3L0.582031 20.7C0.582031 21.965 1.52766 23 2.69641 23H15.457C16.6258 23 17.582 21.965 17.582 20.7V6.9L11.207 0ZM13.332 18.4H4.83203V16.1H13.332V18.4ZM13.332 13.8H4.83203V11.5H13.332V13.8ZM10.1445 8.05V1.725L15.9883 8.05H10.1445Z" fill={fill} />
    </svg>
  );
}

export default PastAssignIcon;
