import React, { useEffect, useState } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import { DRAFT, WRITING, REVIEW, VOTING, TIE_BREAK, COMPLETED, ARCHIVED, START_NEXT, SCHEDULED } from '../../../../utils/globals';
import BreadcrumbDropdown from './BreadcrumbDropdown';
import styles from './Breadcrumbs.module.scss'

const Breadcrumbs = ({ classData, projectData, unitName, displayIdx }) => {


  const location = useLocation()
  const {unitIdx} = useParams()

  
  if (location.pathname.includes('/class'))
    return (
      <div className={styles.separators}>
        <Link to='/dashboard' className={styles.breadcrumb}>Home</Link>
      /
        <Link to={`/class/${classData.classId}`} className={`${styles.breadcrumb} ${styles.current}`}>{classData.name}</Link>
      </div>
    )

    if (unitIdx) return (
      <div className={styles.separators}>
        <Link to='/dashboard' className={`${styles.breadcrumb}`}>Home</Link>
      /
        <Link to={`/class/${classData.classId}`} className={`${styles.breadcrumb} `}>{classData.name}</Link>
      /
        <Link to={`/project/${projectData.projectId}`} className={`${styles.breadcrumb}`}>{projectData.name}</Link>
      /
        {
          typeof unitName === 'string' ?
            <span className={`${styles.breadcrumb} ${styles.current}`}>{unitName}</span>

          : 
              <BreadcrumbDropdown
                displayIdx={displayIdx}
                units={unitName}
                isStory={projectData.isStory}  />
        }
      </div>
    )
    
  if (location.pathname.includes('/project')) return (
    <div className={styles.separators}>
      <Link to='/dashboard' className={`${styles.breadcrumb}`}>Home</Link>
    /
      <Link to={`/class/${classData.classId}`} className={`${styles.breadcrumb} `}>{classData.name}</Link>
    /
      <Link to={`/project/${projectData.projectId}`} className={`${styles.breadcrumb} ${styles.current}`}>{projectData.name}</Link>
    </div>
  )

  

}

export default Breadcrumbs;