import React, { useState, createContext } from 'react';

export const PointsContext = createContext();

export const PointsProvider = ({ children }) => {
    const [points, setPoints] = useState(null);
    return (
        <PointsContext.Provider
            value={{
                points,
                setPoints,
            }}
        >
            {children}
        </PointsContext.Provider>
    )
}