import React from 'react';
import styles from './Grading.module.scss'
const Grading = ({overallGradingScore,setShowFullRubric}) => {
    return ( 
        <div className={styles.grading}>
            <div>
                <p>Final score</p>
                <button onClick={()=>setShowFullRubric(true)}>View rubric</button>
            </div>
            
            <div className={styles.score}>
                <span>{overallGradingScore}</span>
            </div>
            
        </div>
     );
}
 
export default Grading;