import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import UnitVoting from './UnitVoting';
import UnitStarcasting from '../UnitStarcasting';
import MainLoader from '../../../Shared/MainLoader';
import { PROJECT_VOTING_QUERY } from '../../../../queries';

const UnitVotingQuery = ({ projectId, unitId, isSW }) => {
    const { data, error, loading } = useQuery(PROJECT_VOTING_QUERY, {
        variables: {
            id: projectId,
            unitId: unitId,
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <MainLoader mutation="project" />
    }
    if (data && data.project) {
        return data.project.currentUnitData.legacyVote ? <UnitVoting project={data.project} isSW={isSW} /> : <UnitStarcasting project={data.project} isSW={isSW} />
    }   
    return null;
}

export default UnitVotingQuery;
