import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styles from './BoomerHeading.module.scss'
import SimpleBoomer from '../Boomer/SimpleBoomer';
import { FIRST_NAME_QUERY } from '../../queries';
import BadgeInfo from './BadgeInfo'
import { RANK1 } from '../../utils/globals.js'
import WritingBee from '../../images/wb_dark.png';
import BreakoutPartner from '../Project/Unit/BreakoutPartner/BreakoutPartner';

const BoomerHeading = ({ mainHeading, mutation, showBoomer, subHeading, bee = false, breakout }) => {
    const [showBadgeInfo, setShowBadgeInfo] = useState(false);
    const [boomerLoaded] = useState(false);
    const { data, error, loading } = useQuery(FIRST_NAME_QUERY);

    const toggleBadge = (currentBadge) => {
        currentBadge && setShowBadgeInfo(!showBadgeInfo);
    }

    const renderContent = () => {
        if (error) {
            return <p>{error.message}</p>
        }
        if (loading) {
            return null;
        }
        if (data && data.currentUser) {
            const { currentBadge, rank } = data.currentUser;
            return (
                <>
                    <div className={`boomer-heading__boomer ${rank.points > RANK1 ? 'boomer-heading__boomer--rank' : null}`}>
                        <SimpleBoomer mutation={`${rank.points > RANK1 ? 'in-heading-with-rank' : 'in-heading'}`} />
                    </div>
                    {currentBadge && boomerLoaded ? <img src={currentBadge.url} onClick={() => toggleBadge(currentBadge)} alt="badge" className="boomer-heading__badge" /> : null}
                    {(currentBadge && showBadgeInfo) &&
                        <BadgeInfo badge={currentBadge} close={() => toggleBadge(currentBadge)} />
                    }
                    {rank.points > RANK1 && <img src={rank.url2} className="boomer-heading__rank" alt="Rank 1" />}
                </>
            );
        }
        return null;
    }

    const renderBreakout = () => {
        if (breakout) {
            return <BreakoutPartner name={breakout.name} img={breakout.boomerImg}/>
        } else {
            return null
        }
    }

    return (
        <div className={`boomer-heading ${mutation ? 'boomer-heading--' + mutation : ''} ${showBoomer ? '' : 'boomer-heading--no-boomer'}`}>
            {bee && <img src={WritingBee} alt="Writing Bee" className="boomer-heading__wb-logo" />}
            {(!bee && showBoomer) && renderContent()}
            <div className={styles.boomerHeadingText}>
                <div>
                    <div>
                        <h1 className="boomer-heading__title">{mainHeading} {breakout&&breakout.live && <span>Live</span>}</h1>
                        
                    </div>
                    <h2 className="boomer-heading__sub">{subHeading}</h2>
                </div>
                {renderBreakout()}
            </div>
        </div>
    );
}

export default BoomerHeading;
