import React, { Fragment } from 'react';
import _ from 'lodash';

import caution from '../../images/caution.png';
import check from '../../images/check-green.png';

const FormResponse = ({ errors, successMessage }) => {
    if (errors.length !== 0) {
        return (
            <div className="basic-form__errors">
                <img className="basic-form__result-img basic-form__result-img--error" src={caution} alt="caution" />
                <Fragment>
                    {
                        _.map(errors, (error, i) => {
                            return <p className="basic-form__result-message" key={i}>{error}</p>
                        })
                    }
                </Fragment>
                    
            </div>
        );
    }
    return (
        <div className="basic-form__success">
            <img className="basic-form__result-img" src={check} alt="check" />
            <p className="basic-form__result-message">{successMessage || 'Changes Saved!'}</p>
        </div> 
    );
}

export default FormResponse;
