import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import close from '../../../images/close-icon.png';
import { CONTACTS_QUERY, SEND_MESSAGE, SEEN_MESSAGE } from '../../../queries';
import MessagesQuery from './MessagesQuery';
import Teacher from './Teacher';
import { ActionCableConsumer } from '../../Cable';
import chatIcon from '../../../images/chat-icon.png';
import chatIconNew from '../../../images/chat-icon_new.png';
import chatIlly from '../../../images/feedback.png';
import _ from 'lodash';
import styles from './../../NavBar/NavBar.module.scss'

const ChatHolder = ({ chatterId, studentAva, unread }) => {
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [composeMessage, setComposeMessage] = useState({ message: '', sending: false });
    const [showChat, setShowChat] = useState(false);
    const [newMessage, setNewMessage] = useState(null);
    const [handleNewMessage, setHandleNewMessage] = useState(null);
    const [unreadList, setUnreadList] = useState(unread);
    const filterTeachers = (groups) => {
        let teachers = [];
        _.map(groups, group => {
            _.map(group.teachers, teacher => {
                teachers = [...teachers, teacher]
            })
        })
        teachers = _.filter(teachers, teacher => {
            return teacher.premium === true;
        })
        _.uniq(teachers);
        return (_.map(_.uniq(teachers), teacher => {
            return (
                <Teacher key={`teacherComponent${teacher.id}`} teacher={teacher} setActiveTeacher={selectTeacher} unread={unreadList.includes(teacher.chatterId)} />
            )
        })
        )
    }

    const [markAsSeen] = useMutation(SEEN_MESSAGE, {
        onCompleted: (errors) => console.log('seen mesage', errors)
    })

    const selectTeacher = (teacher) => {
        setSelectedTeacher(teacher);
        const newUnreads = _.remove(unreadList, n => {
            return n !== teacher.chatterId;
        })
        setUnreadList(newUnreads);
        markAsSeen({
            variables: {
                id: teacher.id,
            }
        });
    }



    const [sendMessage] = useMutation(SEND_MESSAGE, { //here Sergiu, it's weird becouse i dont know why this .js works perfectly fine and the breakout rooms chat dont and i get the network error
        awaitRefetchQueries: false, // ???
        onCompleted: () => { //this should be a apollo client event function
            let time = new Date();
            setHandleNewMessage( //this is linked with react local state
                {
                    id: `message${time}`,
                    body: composeMessage.message, //takes the message from local react state
                    sentby: 'student',
                    timestamp: + time,
                    teacher_id: selectedTeacher.chatterId,
                    student: {
                        avatarPath: studentAva
                    }
                }
            )
            setComposeMessage({ message: '', sending: false })
            setHandleNewMessage(null) // but here they set it local react state to null
        },
    });

    const handleReceivedMessage = response => {
        setHandleNewMessage(response.message);
    };

    useEffect(() => {
        if (handleNewMessage) { //here it detects if there is somethin in handleNewMessage
            if (showChat === false || (selectedTeacher && selectedTeacher.chatterId !== handleNewMessage.teacher_id) || selectedTeacher === null) { //does something about unread messages
                if (unreadList.includes(handleNewMessage.teacher_id) === false) {
                    setUnreadList([...unreadList, handleNewMessage.teacher_id])
                }
                setHandleNewMessage(null)
            } else if (selectedTeacher && selectedTeacher.chatterId === handleNewMessage.teacher_id) {// if selected teacher exists and chatterId equals handlenewmessage.teacherID then
                setNewMessage(handleNewMessage); // linked to react local state
                setHandleNewMessage(null);
                // console.log('markAsSeenFN') Aqui no llega
                markAsSeen({ // mark all as seen
                    variables: {
                        id: selectedTeacher.id,
                    }
                });
            }
        }
    }, [handleReceivedMessage])

    const openChat = () => {
        setShowChat(true);
        if (selectedTeacher) {
            const newUnreads = _.remove(unreadList, n => {
                return n !== selectedTeacher.chatterId;
            })
            setUnreadList(newUnreads);
            markAsSeen({
                variables: {
                    id: selectedTeacher.id,
                }
            });
        }
    }

    const { data, error, loading } = useQuery(CONTACTS_QUERY);

    return (
        <>
            <ActionCableConsumer
                channel={{ channel: 'StudentChannel', chatter_id: chatterId }}
                onReceived={handleReceivedMessage}
            />
            <div onClick={() => openChat()} className={styles.itemContent}>
                <span style={{ backgroundImage: `url(${unreadList.length > 0 ? chatIconNew : chatIcon})` }} className="main-menu__icon main-menu__icon--chat">{unreadList.length > 0 && unreadList.length}</span>
                <p>Chat</p>
            </div>
            {showChat &&
                <div className="chat-overlay">
                    <div className="chat-modal">
                        <div className="chat-mob-close">
                            {selectedTeacher && <button className="button chat-mob__return-btn" onClick={() => setSelectedTeacher(null)}>Back</button>}
                            <button className="chat-mob-close__btn" onClick={() => setShowChat(false)}>
                                <img src={close} alt="Close" className="chat-mob-close__btn-img" />
                            </button>
                        </div>
                        {error && <p>Something went wrong</p>}
                        <div className={`chat-modal-inner ${selectedTeacher && 'chat-modal-inner--hide'}`}>
                            {loading && <p>Loading</p>}
                            {data && (
                                <ul className="chat-contacts">
                                    {filterTeachers(data.currentUser.groups)}
                                </ul>
                            )}
                        </div>
                        <div className={`chat-main ${selectedTeacher && 'chat-main--show'}`}>
                            {selectedTeacher ?
                                <>
                                    <div className="chat-main__header">
                                        <p className="chat-contact">
                                            <img alt="teacher" src={selectedTeacher && selectedTeacher.profilePicturePath} className="chat-contact__ava" />
                                            <span>{selectedTeacher && selectedTeacher.displayName}</span>
                                        </p>
                                    </div>
                                    {selectedTeacher && <MessagesQuery teacherId={selectedTeacher.id} newMessage={newMessage} teacherAvatar={selectedTeacher.profilePicturePath} />}
                                    <div className='chat-main__textarea'>
                                        <textarea className="u-full-width chat-main__textarea__input" value={composeMessage.message} onChange={e => setComposeMessage({ message: e.target.value, sending: false })} />
                                        <input  // here Sergiu
                                            type="submit"
                                            className="button"
                                            value={composeMessage.sending ? 'Sending...' : 'Send'} //value linked to local react state
                                            disabled={(composeMessage.sending || composeMessage.message === '') && true}
                                            onClick={() => {
                                                setComposeMessage({ ...composeMessage, sending: true })    //local react state change
                                                sendMessage({ // this triggers the sending of the message
                                                    variables: { //where does this go???
                                                        id: selectedTeacher.id,
                                                        body: composeMessage.message
                                                    }
                                                });
                                            }} />
                                    </div>
                                </> :
                                <div className="chat-main__default">
                                    <div className="u-centered-text">
                                        <img src={chatIlly} className="chat-graphic" alt="Chat" />
                                        <p>Click on a name to start a conversation.</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ChatHolder;
