import { gql } from 'apollo-boost';

export const SUBSCRIPTION_TEST = gql`
subscription($projectId: ID!) {
    studentSignedIn(projectId: $projectId) {
        id
    }
}
`;

export const SUBSCRIPTION_INTERNAL_TEST = gql`
{
    boomerBucks @client
}
`;