import React from 'react';

import { BODY, HAIR, TOP, BOTTOM, PET, ACCESSORY } from '../../../utils/boomer';
import BoomerImg from '../../../images/store/boomer.png';
import Hair from '../../../images/store/hair.png';
import Top from '../../../images/store/top.png';
import Bottoms from '../../../images/store/bottoms.png';
import Pet from '../../../images/store/pet.png';
import Accessories from '../../../images/store/accessories.png';

const Categories = ({ onSelectCategory, selectedCategory }) => {
    return (
        <ul className="store__categories">
            <li onClick={() => onSelectCategory(BODY)} className={`store__category-tab ${selectedCategory === BODY ? 'store__category-tab--active' : ''}`}><img alt="Boomer Bodies" src={BoomerImg} className="store__category-boomer"/></li>
            <li onClick={() => onSelectCategory(HAIR)} className={`store__category-tab ${selectedCategory === HAIR ? 'store__category-tab--active' : ''}`}><img alt="Boomer Hair" src={Hair} className="store__category-img"/></li>
            <li onClick={() => onSelectCategory(TOP)} className={`store__category-tab ${selectedCategory === TOP ? 'store__category-tab--active' : ''}`}><img alt="Boomer Tops" src={Top} className="store__category-img"/></li>
            <li onClick={() => onSelectCategory(BOTTOM)} className={`store__category-tab ${selectedCategory === BOTTOM ? 'store__category-tab--active' : ''}`}><img alt="Boomer Bottoms" src={Bottoms} className="store__category-img"/></li>
            <li onClick={() => onSelectCategory(PET)} className={`store__category-tab ${selectedCategory === PET ? 'store__category-tab--active' : ''}`}><img alt="Boomer Pets" src={Pet} className="store__category-img"/></li>
            <li onClick={() => onSelectCategory(ACCESSORY)} className={`store__category-tab ${selectedCategory === ACCESSORY ? 'store__category-tab--active' : ''}`}><img alt="Boomer Accessories" src={Accessories} className="store__category-img"/></li>
        </ul>
    );
}

export default Categories;
