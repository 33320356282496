import React from 'react';
import styles from './ClassmateCard.module.scss'
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import DrawBoomer from '../../../../Boomer/DrawBoomer'

const ClassmateCard = ({ name, avatar, currentBadge }) => {
  return (
    <Tooltip
      title={name}
      position="bottom"
      trigger="mouseenter"
    >
      <div className={styles.classmateCard} >
          <DrawBoomer boomerData={JSON.parse(avatar)} forceSave={false} />
      </div>
    </Tooltip>
  );
}

export default ClassmateCard;