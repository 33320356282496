import { gql } from 'apollo-boost';

// Queries
export const PROJECT_MAIN_QUERY = gql`
query projectMainQuery($id: ID!, $unitId: ID) {
    project(id: $id) {
        id
        archived
        title
        currentUnit
        type
        journal
        currentUnitData(unitId: $unitId) {
            id
            currentUserVoted
            deadline
            editable
            index
            instructions
            phase
            shareGrading
            shareGrammar
            title
            wordLimit
            images
            roomPartnerEntry {
                id
                approved
                entryText
                hasStarted
                submitted
                authorId
                authorName
                authorAvatarPath
            }
            currentUserTicklesCounter(tickleTypes: [1, 4])
            currentUserEntry {
                id
                approved
                currentFeedback
                wordsCount
                entryText
                lastSavedAt
                hasStarted
                rejected
                revisionStatus
                submitted
                rubric {
                    id
                    name
                    overallScore
                    skills {
                        id
                        description
                        studentScore
                        criterias {
                            id 
                            description
                            score
                        }                        
                    }
                }
                author {
                    id
                    firstName
                    lastName
                }
                feedbackHistory {
                    id
                    date
                    description
                }
            }
            currentUserNextEntriesToVote {
                id
                seenByCurrentUser
            }
            documents {
                id
                isDeleted
                name
                url
            }
            vocab {
                id
                word
            }
        }
        teacher {
            id
            displayName
            profilePicturePath
            chatterId
        }
        book {
            id
            bookTemplate{
                id
                bee
            }
        }
        units {
            id
            index
            phase
            editable
            currentUserTicklesCounter(tickleTypes: [7])
            currentUserEntry {
                id
                authorId
                hasViewedWinner
                entryText
                lastSavedAt
                wordsCount
                author {
                firstName
                lastName
                }
            }
            storyStart {
                id
                authorName
                content
            }
            teacherUnit
            title
            winner {
                id
                entryText
                name
                studentId
            }
        }
    }
}
`;

export const PROJECT_UNITS_QUERY = gql`
query projectMainQuery($id: ID!) {
    project(id: $id) {
        id
        archived
        title
        journal
        type
        introVideoUrl
        groups{
            id
            name
        } 
        teacher {
            id
            displayName
            profilePicturePath
            chatterId
        }
        units {
            id
            currentUserVoted
            deadline
            editable
            index
            instructions
            phase
            shareGrading
            shareGrammar
            title
            wordLimit
            images
            currentUserTicklesCounter(tickleTypes: [1, 4])
            roomPartnerEntry {
                id
                approved
                entryText
                hasStarted
                submitted
                authorId
                authorName
                authorAvatarPath
            }
            currentUserEntry {
                id
                approved
                currentFeedback
                feedbackAddedAt
                entryText
                lastSavedAt
                wordsCount
                hasStarted
                rejected
                revisionStatus
                submitted
                overallGradingScore
                canWrite
                author {
                    id
                    firstName
                    lastName
                }
                feedbackHistory {
                    id
                    date
                    description
                }
                rubric {
                    id
                    name
                    overallScore
                    skills {
                        id
                        description
                        studentScore
                        criterias {
                            id
                            description
                            score
                        }
                    }
                }
            }
            currentUserNextEntriesToVote {
                id
                seenByCurrentUser
            }
            documents {
                id
                isDeleted
                name
                url
            }
            vocab {
            id
            word
            }
            storyStart {
                id
                content
                authorName
              }
            winner {
                id
                entryText
                name
                studentId
            }
        }
    }
}
`;

export const VIDEO_QUERY = gql`
query videoQuery($id: ID!) {
    project(id: $id) {
        introVideoUrl
    }
}
`;

export const ENTRY_HISTORY_QUERY = gql`
query entryHistoryQuery($entryId: ID!, $page: Int) {
    unitEntry(id: $entryId) {
        id
        historiesCounter
        histories(limit: 10, page: $page) {
           id
           text
           actionDate
         }
    }
}
`;

export const PROJECT_TICKLES_QUERY = gql`
query projectTicklesQuery($index: ID!, $projectId: ID!, $types: [Int!], $limit: Int) {
    projectUnit(index: $index, projectId: $projectId) {
        id
        phase
        currentUserTickles(tickleTypes: $types, limit: $limit, newOnly: false) {
            id
            author {
                id
                firstName
                avatar
                avatarPath
                rank {
                    id
                    url1
                    url2
                }
                currentBadge{
                    id
                    url
                }
            }
            createdOn
            isSystemTickle
            text
            tickleType
        }
    }
}
`;

export const PROJECT_ADD_TICKLE_QUERY = gql`
query projectAddTickleQuery{
    currentUser {
        id
        avatar
        firstName
        currentBadge {
            id
            url
        }
        rank {
            id
            url1
            url2
        }
    }
}
`;

export const PROJECT_TICKLE_PHRASES_QUERY = gql`
query projectTicklePhrasesQuery{
    ticklePhrases {
        id
        phraseType
        text
    }
}
`;

export const PROJECT_ROUTER_QUERY = gql`
query projectRouterQuery($projectId: ID!) {
    project(id: $projectId) {
        id
        title
        type
        groups{
            id
            name
            students {
                avatar
                id
                firstName
                lastName
                currentBadge {
                    url
                }
                rank {
                    url2
                }
            }
            teachers{
                id
                displayName
                profilePicturePath
            }
            projects {
                id
                archived
                completed
                title
                type
                journal
                currentUnitData {
                    id
                    phase
                }
                units{
                    id
                    approved
                    startedAt
                    deadline
                    index
                    phase
                    title
                }
            }
        }
        units {
            id
            index
            title
            deadline
            startedAt
            phase
            hasStartedWriting
            currentUserVoted
            images
            storyStart {
                id
                content
                authorName
            }
            currentUserEntry {
                id
                approved
                currentFeedback
                entryText
                hasStarted
                rejected
                revisionStatus
                submitted
                author {
                    id
                    lastName
                    firstName
                    avatarPath
                }
               
            }
            roomPartnerEntry {
                id
                approved
                entryText
                hasStarted
                submitted
                authorId
                authorName
                authorAvatarPath
            }
        }
    }
    currentUser {
        id
        firstName
        boomerBucks
        avatar
        avatarPath
        lastName
      }
}
`;

export const PROJECT_UNIT_QUERY = gql`
query projectUnitQuery($index: ID!, $projectId: ID!) {
    project(id: $projectId) {
        id
        title
        groups{
            id
            name
        } 
    } 
    projectUnit(index: $index, projectId: $projectId) {
        id
        title
        phase
        currentUserEntry {
            id
            hasStarted
        }

    }
}
`;

export const PROJECT_VOTING_QUERY = gql`
query projectVotingQuery($id: ID!, $unitId: ID) {
    project(id: $id) {
        id
        title
        book {
            id
            bookTemplate{
              bee
            }
        }
        currentUnitData(unitId: $unitId) {
            id
            currentUserVoted
            index
            phase
            title
            legacyVote
            currentUserTicklesCounter(tickleTypes: [2])
            currentUserEntry {
                id
                submitted,
                canWrite,
                approved
            }
            currentUserNextEntriesToVote {
                id
                entryText
                seenByCurrentUser
                bookmarkedByCurrentUser
            }
            currentStudentNextEntryToStarCast {
                id
                entryText
                wordsCount
            }
            vocab {
                id
                word
            }
        }
        teacher {
            id
            displayName
            profilePicturePath
        }
        units {
            id
            index
            phase
            teacherUnit
            title
        }
    }
}
`;

export const STATUS_VOTED_QUERY = gql`
query projectVotingQuery($id: ID!, $unitId: ID) {
    project(id: $id) {
        id
        currentUnitData(unitId: $unitId) {
            id
            currentUserNextEntriesToVote {
            id
            seenByCurrentUser
            }
        }
    }
}
`;

// Mutations
export const ADD_TICKLE_MUTATION = gql`
mutation addTickle($unitId: ID!, $text: String!) {
    addTickle(unitId: $unitId, text: $text) {
        errors
        tickle {
            id
        }
    }
}
`;

export const SEE_TICKLES_MUTATION = gql`
mutation seeTickle($id: ID!) {
    seeTickles(unitId: $id) {
        errors
        entry {
            id
        }
    }
}
`;

export const PROJECT_UPDATE_MUTATION = gql`
mutation projectUpdate($id: ID!, $entryText: String!) {
    updateProjectUnitEntry(id: $id, entryText: $entryText) {
        errors
        projectUnitEntry {
            id
        }
    }
}
`;

export const PROJECT_SAVED_UPDATE_MUTATION = gql`
mutation projectUpdate($id: ID!, $lastSavedAt: String!, $entryText: String!) {
    updateProjectUnitEntry(id: $id, lastSavedAt: $lastSavedAt,  entryText: $entryText) {
        errors
        projectUnitEntry {
            id
        }
    }
}
`;

export const PROJECT_SUBMIT_MUTATION = gql`
mutation projectSubmit($id: ID!, $entryText: String!) {
    submitProjectUnitEntry(id: $id, entryText: $entryText) {
        errors
        projectUnitEntry {
            id
        }
    }
}
`;

export const PROJECT_SEE_ENTRY_MUTATION = gql`
mutation projectSeeEntry($entryId: ID!, $unitId: ID!) {
    seeEntry(entryId: $entryId, unitId: $unitId) {
        errors
        entry {
            id
        }
    }
}
`;

export const VIEWED_WINNER_MUTATION = gql`
mutation viewedWinner($entryId: ID!) {
    viewedWinner(projectUnitEntryId: $entryId) {
        errors
        projectUnitEntry {
            id
        }
    }
}
`;

export const PROJECT_BOOKMARK_ENTRY_MUTATION = gql`
mutation projectBookmarkEntry($entryId: ID!, $currentSetIds: [ID!]!) {
    bookmarkProjectUnitEntry(id: $entryId, currentSetIds: $currentSetIds) {
        errors
        projectUnitEntry {
            id
        }
    }
}
`;

export const PROJECT_UNBOOKMARK_ENTRY_MUTATION = gql`
mutation projectUnbookmarkEntry($entryId: ID!) {
    removeBookmarkProjectUnitEntry(id: $entryId) {
        errors
        projectUnitEntry {
            id
        }
    }
}
`;

export const PROJECT_VOTE_ENTRY_MUTATION = gql`
mutation projectVoteEntry($entryId: ID!, $unitId: ID!) {
    voteEntry(entryId: $entryId, unitId: $unitId) {
        errors
        entries {
            id
        }
    }
}
`;

