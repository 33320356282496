

import React from 'react';
const CurrentAssigIcon = ({fill}) => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M21.1285 6.95117C20.6577 6.48006 19.9094 6.45046 19.4033 6.86283C19.3301 6.92247 19.3868 6.86992 18.2725 7.98423L21.1649 10.8768L22.2045 9.85019C22.7071 9.34758 22.7071 8.5298 22.2045 8.02715L21.1285 6.95117Z" fill={fill} />
      <path d="M12.343 13.9694L10.6415 17.6155C10.5269 17.8612 10.5782 18.1522 10.7698 18.3438C10.9615 18.5355 11.2526 18.5867 11.4982 18.4721L15.1442 16.7706C15.2885 16.7033 14.8518 17.112 20.2499 11.7813L17.3631 8.89453C12.011 14.2467 12.4117 13.8224 12.343 13.9694Z" fill={fill} />
      <path d="M16.228 17.5644C16.0701 17.7202 15.8882 17.8468 15.6872 17.9406L12.0412 19.6421C11.3242 19.9767 10.4483 19.8493 9.85628 19.2572C9.28346 18.6844 9.12873 17.8063 9.47128 17.0722L11.1728 13.426C11.2684 13.2213 11.3979 13.0364 11.5577 12.8766L17.0391 7.39518V1.93359C17.0391 0.86741 16.1717 0 15.1055 0H2.51562C1.44944 0 0.582031 0.86741 0.582031 1.93359V20.0664C0.582031 21.1326 1.44944 22 2.51562 22H15.1055C16.1717 22 17.0391 21.1326 17.0391 20.0664V16.7634L16.228 17.5644ZM4.01957 4.16797H13.6446C14.0005 4.16797 14.2891 4.45655 14.2891 4.8125C14.2891 5.16845 14.0005 5.45703 13.6446 5.45703H4.01957C3.66362 5.45703 3.37504 5.16845 3.37504 4.8125C3.37504 4.45655 3.66362 4.16797 4.01957 4.16797ZM4.01957 7.60547H11.5821C11.938 7.60547 12.2266 7.89405 12.2266 8.25C12.2266 8.60595 11.938 8.89453 11.5821 8.89453H4.01957C3.66362 8.89453 3.37504 8.60595 3.37504 8.25C3.37504 7.89405 3.66362 7.60547 4.01957 7.60547ZM3.37504 11.6875C3.37504 11.3315 3.66362 11.043 4.01957 11.043H9.51957C9.87553 11.043 10.1641 11.3315 10.1641 11.6875C10.1641 12.0435 9.87553 12.332 9.51957 12.332H4.01957C3.66362 12.332 3.37504 12.0435 3.37504 11.6875Z" fill={fill} />
    </svg>
  );
}

export default CurrentAssigIcon;
