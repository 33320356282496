import React, { useState,useRef } from 'react';
// import test from '../../../../images/project/testThumbnail.png'
// import test2 from '../../../../images/project/testThumbnail.png'
// import test3 from '../../../../images/project/testThumbnail.png'
// import test4 from '../../../../images/project/testThumbnail.png'
import Thumbnail from './Thumbnail';

import ImageModal from './ImageModal'

import Carousel from 'react-elastic-carousel';


import styles from './ImageCarousel.module.scss'

const ImageCarousel = ({ images, setShowModal }) => {
    const [show, setShow] = useState(false)
    const [slide, setSlide] = useState(1)
    const carousel = useRef()
    const totalSlides = Math.ceil(images.length/2)

    const handleClick = (next)=>{
        if(next){
            carousel.current.slideNext()
            totalSlides > slide&& setSlide(slide+1)
        }else{
            carousel.current.slidePrev()
            totalSlides===slide&&setSlide(slide-1)

        }
    }

    return (
        <>
            <div className={styles.carouselWrapper}>

                <Carousel itemsToShow={2} showArrows={false} pagination={false} itemsToScroll={2} ref={carousel}>

                    {images.map((elm, idx) => <Thumbnail setShowModal={setShowModal} img={elm} slide={idx} key={idx} show={show} setShow={setShow}></Thumbnail>)}
                </Carousel>

                <div className={styles.buttons}>
                    <div className={`${styles.btn} ${styles.left}`} onClick={() => handleClick()}/>
                    <div className={styles.totalSlides}> page {slide} / {totalSlides}</div>
                    <div className={styles.btn} onClick={()=> handleClick(true)}/>

                </div>


            </div>

            {/* <ImageModal setShow={setShow} show={show} images={images} /> */}

        </>

    );
}



export default ImageCarousel;

