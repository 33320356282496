import React from 'react';
import styles from './StartAndDeadline.module.scss'
import startDateClock from '../../../../images/newDashboard/icons/startDateClock.png'
import endDateFlag from '../../../../images/newDashboard/icons/endDateFlag.png'
import { dateFormat } from '../../../../utils/DateHelper';

const StartAndDeadline = ({ startedAt, deadline, dark }) => {
  return (
    <div className={styles.startEnd}>

      <div className={dark ? `${styles.date} ${styles.end} ${styles.dark}` : `${styles.date} ${styles.end}`}>
        <img src={endDateFlag} alt='' />
        <p>{dateFormat(deadline)}</p>
      </div>
    </div>
  );
}

export default StartAndDeadline;