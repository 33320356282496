import React from 'react';
import styles from './ScrollArrows.module.scss'

// the parent component should be relative and scrollable

const ScrollArrows = ({theRef,style,offSet,}) => {

  const scroll = pixels => {
    theRef.current.scrollBy({ top: pixels, behavior: "smooth" });
  }

  return (
    <div className={styles.scrollArrows} style={style}>
      <div className={styles.up} onClick={() => scroll(-offSet)}> <span>&lt;</span> </div>
      <div className={styles.down} onClick={() => scroll(offSet)} > <span>&gt;</span></div>
    </div>
  );
}

export default ScrollArrows;