import React, { useEffect, useState } from 'react';
import styles from './ClassCard.module.scss'
import { useQuery } from '@apollo/react-hooks';
import { GET_GROUP_DATA } from '../../../../../queries';
import ProjectBullet from './ProjectBullet/ProjectBullet'
import ProjectCardRenderer from '../../../GroupDashboard/ProjectCardRenderer/ProjectCardRenderer';
import loader from '../../../../../images/small-loader.svg';
// import ClassmateBoomer from './ClassmateBoomer/ClassmateBoomer';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
const ClassCard = ({ title, teacher, students, classId,writingClub }) => {

  const [displayUnits, setDisplayUnits ] = useState(null)
  const { data, loading, error } = useQuery(GET_GROUP_DATA, {
      variables: { classId }
    }    
  )
  
  const sortByNewestUnit = (array) =>{
    return array.sort((a,b)=> 
    new Date(b.units[b.units.length-1].createdAt).getTime() - new Date(a.units[a.units.length-1].createdAt).getTime())
  }


  useEffect(()=>{
    if (data && data.group) {
      setDisplayUnits(
        [...data.group.projects.filter((elm) => !elm.completed&&!elm.archived&&elm.type !== 7), ...data.group.projects.filter((elm) => elm.type === 7)].sort((a,b)=> 
      new Date(b.units[b.units.length-1].createdAt).getTime() - new Date(a.units[a.units.length-1].createdAt).getTime()) // sort by most recently created unit
    )
      }
  },[data])
  
  return  (
    <div className="u-m-base-20">
      <Link className={styles.classHeader} to={`/class/${classId}`}>{title} {loading && <img src={loader} />}<ChevronRight style={{marginLeft: 'auto'}}/></Link>
      {error && <p>Error</p>}
      {data && 
        <>        
            {displayUnits && 
            <div className={styles.dashBoardProjects}>
              <ProjectCardRenderer projects={displayUnits} term={'current'} myStudentId={data.currentUser.id} source={"dash"}/>
            </div>}
        </>
      }
      
    </div>
  );
}

export default ClassCard;