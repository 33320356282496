import React, { Fragment } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { PROJECT_VOTE_ENTRY_MUTATION, PROJECT_TICKLES_QUERY } from '../../../../queries';
import { getTickleType } from '../../../../utils/globals';

const ConfirmVoteButton = ({ index, onVote, phase, projectId, unitId, voteEntry }) => {
    const [onVoteEntry, { error, loading }] = useMutation(PROJECT_VOTE_ENTRY_MUTATION, {
        awaitRefetchQueries: false,
        onCompleted: data => {
            onVote(data);
        },
        refetchQueries: () => [
            { query: PROJECT_TICKLES_QUERY, variables: {
                projectId: projectId, index: index, types: getTickleType(phase), limit: 50,
            }}
        ]
    })
    
    return (            
        <Fragment>
            <button 
                disabled={loading}
                className={'button button--bordered confirm-overlay__button'}
                onClick={() => {
                    onVoteEntry({ variables: {
                        entryId: voteEntry.id,
                        unitId
                    }});
                }}
            >
                {loading ? 'Voting...' : 'Yes'}
            </button>
            {error && <p>{error.message}</p>}
        </Fragment>
    );
}

export default ConfirmVoteButton;
