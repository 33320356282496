import React, { useEffect } from 'react';
import AnimatedNumber from 'react-animated-number';
import { useQuery } from '@apollo/react-hooks';

import { BOOMER_BUCKS_QUERY } from '../../queries';

const BoomerBucks = ({ client }) => {
    const { data, error } = useQuery(BOOMER_BUCKS_QUERY);

    

    if (error) {
        return <span>{error.message}</span>
    }

    if (data && (data.currentUser.boomerBucks || data.currentUser.boomerBucks === 0)) {
        return <AnimatedNumber
            duration={500}
            stepPrecision={0}
            value={data.currentUser.boomerBucks}
        />
    }
    return null;
};

export default BoomerBucks;
