import React, { Fragment, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import ls from 'local-storage';

import { PROJECT_VOTING_QUERY } from '../../../../queries';
import SimpleBoomer from '../../../Boomer/SimpleBoomer';
import coins from '../../../../images/coins.png';
import group from '../../../../images/boomer-group.png';
import youVoted from '../../../../images/you-voted.png';
import youVotedMobile from '../../../../images/you-voted-mobile.png';
import MainLoader from '../../../Shared/MainLoader';
import ConfirmVoteButton from './ConfirmVoteButton';

const GO_TO_STORE = 1;
const GO_TO_PROJECT_HOME = 2;

const ConfirmVote = ({ history, location, onClose, projectId, unitId, voteEntry, index, phase, showScroller, lastChapter, isSW }) => {
    const client = useApolloClient();
    const [state, setState] = useState({
        loading: false,
        newEntries: [],
        voted: false
    });

    const voteAgain = nextAction => {
        setState(prevState => {
            return {
                ...prevState,
                loading: true
            }
        });
        client.query({
            fetchPolicy: "network-only",
            query: PROJECT_VOTING_QUERY, 
            variables: { id: projectId, unitId: unitId } 
        }).then(() => {
            if (nextAction === GO_TO_STORE) {
                history.push('/user/profile/boomer');
            } else if (nextAction === GO_TO_PROJECT_HOME) {
                lastChapter && isSW ? showScroller(true) : history.push(`/project/${projectId}${location.search}`);
            } else {
                setState(prevState => {
                    return {
                        ...prevState,
                        loading: false
                    }
                });
                onClose();
            }
        });
    }

    const onVote = data => {
        setState(prevState => {
            return {
                ...prevState,
                voted: true,
                newEntries: (data.voteEntry && data.voteEntry.entries) || []
            }
        });
    }
        
    return (
        <Fragment>
            {state.loading && <MainLoader mutation="project" />}
            <div 
                onClick={(event) => {
                    if (event.target.classList.contains('confirm-overlay') && !state.voted) {
                        onClose();
                    }
                }} 
                className={`confirm-overlay`}
            >
                { 
                    !state.voted ?
                    (
                        <div className="confirm-overlay__body">
                            {
                                ls('token') ? (
                                    <div className="confirm-overlay__boomer">
                                        <SimpleBoomer mutation="in-confirm" />
                                    </div>
                                ) : null
                            }
                            <button onClick={onClose} className="confirm-overlay__close-button">
                                <span className="confirm-overlay__close-icon">x</span>
                            </button>
                            <p className="confirm-overlay__title">Cast your vote!</p>
                            <p className="confirm-overlay__message">{`Are you sure you want to vote for Entry ${voteEntry.number}?`}</p>
                            <div className="confirm-overlay__buttons">
                                <ConfirmVoteButton index={index} onVote={onVote} phase={phase} projectId={projectId} unitId={unitId} voteEntry={voteEntry} />
                                <button className="button button--bordered button--red confirm-overlay__button" onClick={onClose}>No!</button>
                            </div>
                        </div>
                    ) : (
                        <div className="confirm-overlay__body vote-confirm-overlay__body">
                            <button onClick={() => voteAgain(GO_TO_PROJECT_HOME)} className="confirm-overlay__close-button vote-confirm-overlay__close-button">
                                <span className="confirm-overlay__close-icon">x</span>
                            </button>
                            <img className="vote-confirm-overlay__voted-img u-hidden-mobile" src={youVoted} alt="You Voted" />
                            <img className="vote-confirm-overlay__voted-img u-only-mobile" src={youVotedMobile} alt="You Voted" />
                            <div className="vote-confirm-overlay__inner">
                                <p className="vote-confirm-overlay__title">You earned <img alt="coins" className="vote-confirm-overlay__coins" src={coins} /> 2!</p>
                                <p className="vote-confirm-overlay__message">You can go spend your hard earned Boomer Bucks{state.newEntries.length !== 0 ? " or you can vote again!" : "!"}</p>
                                <div className="vote-confirm-overlay__button-holder">
                                    {
                                        state.newEntries.length !== 0 ?
                                        (
                                            <>
                                            <button disabled={state.loading} onClick={() => voteAgain()} className="button button--bordered button--blue vote-confirm-overlay__button">{state.loading ? "Loading..." : "Vote Again"}</button>
                                            {lastChapter && isSW && <button onClick={() => showScroller(true)} className="button button--bordered button--blue vote-confirm-overlay__button" style={{marginLeft: '10px'}}>No thanks</button>}
                                            </>
                                        ) : (
                                            <>
                                            <button disabled={state.loading} onClick={() => voteAgain(GO_TO_STORE)} className="button button--bordered button--blue vote-confirm-overlay__button">{state.loading ? "Loading..." : "Hit The Store"}</button>
                                            {lastChapter && isSW && <button onClick={() => showScroller(true)} className="button button--bordered button--blue vote-confirm-overlay__button" style={{marginLeft: '10px'}}>No thanks</button>}
                                            </>
                                        )
                                    }
                                    
                                </div>
                            </div>
                            <img className="vote-confirm-overlay__group-img" src={group} alt="Boomer Group" />
                        </div>
                    )
                }
                
            </div>
        </Fragment>
    );
}

export default withRouter(ConfirmVote);
