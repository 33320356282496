import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import Container from './Store/Container';
import MainLoader from '../Shared/MainLoader';
import { BOOMER_STORE_QUERY } from '../../queries';

const Store = () => {
    const { data, error, loading } = useQuery(BOOMER_STORE_QUERY);

    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <MainLoader mutation="has-sub-menu"/>
    }
    if (data && data.currentUser) {
        return <Container data={data.currentUser} />
    }
    return null;
}

export default Store;