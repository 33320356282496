import React, { Fragment } from 'react';

import PasswordFormHolder from './PasswordFormHolder';
import BoomerHeading from '../../Shared/BoomerHeading';

const Password = (props) => (
    <Fragment>
        <div className="u-hidden-mobile">
            <BoomerHeading
                mainHeading="Password Update"
                mutation="boomer-desktop-only"
                showBoomer={true}
                subHeading="Change Your Password"
            />
        </div>
        <PasswordFormHolder {...props} />
    </Fragment>
);

export default Password;