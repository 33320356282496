import React from 'react'
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';
import styles from './SlideScroller.module.scss';

const LottieSlide = ({
    title,
    animationData,
    speed,
    loop = false,
    onBtnClick,
    lottieStyle,
    eventListeners,
    svgWrapperStyle,
    buttonTxt,
    img = null,
    alternate = false,
    writingClosed,
}) => {

    const [ref, inView] = useInView({
        threshold: 0
    });

    const animationOptions = {
        loop,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        }
    }

    return (
        <>
            {title && title.position === 'left' && <h2 className={styles.sideContentTitle}>{title.text}</h2>}
            {img ? <img src={img} className={styles.slideImage} /> :
            <div ref={ref}>
                <div className={svgWrapperStyle}>
                    <Lottie
                        style={lottieStyle}
                        speed={speed || 1}
                        isStopped={!inView}
                        options={animationOptions}
                        eventListeners={eventListeners}
                    />
                </div>
            </div>
            }
            {title && title.position === 'right' && <h2 className={styles.sideContentTitle}>{title.text}</h2>}
            {title && title.position === 'bottom' && <h2 className={alternate ? styles.centerContentTitleAlt : styles.centerContentTitle2}>{title.text}</h2>}
            {!writingClosed && onBtnClick && <button onClick={onBtnClick} className="button button--bordered u-m-top-20">{buttonTxt}</button>}
        </>
    )
}

export default LottieSlide;