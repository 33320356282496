import React from 'react';
import styles from '../UnitStarcasting.module.scss';
import cx from 'classnames';
import voteImg from '../../../../../images/vote.png';

const StarcastingInstructions = ({ closeInstructions }) => {

    return (
        <div className={cx(styles.panel, styles.instructions)}>
            <h1 className={styles.instructionsTitle}>Voting - It's time to pick the best entry!</h1>
            <div className={styles.instructionsBody}>
                <div className={styles.instructionsMain}>
                    <ul className={styles.instructionsList}>
                        <li className={styles.instructionsItem}><span className={styles.instructionsStar}>1</span>First, read the entry we've chosen at random. </li>
                        <li className={styles.instructionsItem}><span className={styles.instructionsStar}>2</span>Next give it a star rating based on how much you like the entry.</li>
                        <li className={styles.instructionsItem}><span className={styles.instructionsStar}>3</span>Don't stop there - keep on reading and voting. The next one you read could be the funniest, the silliest, the most magnificent entry ever!</li>
                    </ul>
                    <button className={cx('button button--bordered', styles.instructionsStartBtn)} onClick={closeInstructions}>Let's get started!</button>
                </div>
                <div className={styles.instructionsImg}>
                    <img src={voteImg}  alt='' />
                </div>
            </div>

        </div>
    )
}

export default StarcastingInstructions;
