import { gql } from 'apollo-boost';

// Cache queries
// Is the user logged in
export const LOGGED_IN = gql`
    {
        loggedIn @client
        error @client
    }
`;

// Has the user been 401 logged out
export const FORCED_LOGOUT = gql`
    {
        forcedLogout @client
    }
`;

export const USER_ID_QUERY = gql`
query userIdQuery {
    currentUser {
        id
    }
}
`;

export const USER_CHAT_QUERY = gql`
query userChatQuery {
    currentUser {
        avatarPath
        chatterId
        unreadMessagesCounter
    }
}
`;

export const MAIN_LOGIN_QUERY = gql`
query mainLoginQuery {
    currentUser {
        id
        avatar
        avatarPath
        email
        firstName
        lastName
        memberOfAClass
        premium
        studentCode
        chatterId
        unreadMessagesCounter
        currentBadge {
            earnedOn
            equipped
            id
            levelName
            message
            name
            url
        }
        badgesList {
            id
            earnedOn
            equipped
            levelName
            message
            name
            url
            status
        }
        rank {
            id
            points
            url1
            url2
        }
        activities {
            actionItemName,
            amount,
            badge {
                id
            }
            createdAt,
            id,
            verb
        }
        rank {
            id
            points
            url1
            url2
        }
        currentBadge {
            id
            name
            levelName
            url
            message
        }
        ownedAvatarItems {
            id
            gender
            tileHeight
            type
            url
            xmlId
            xOffset
            yPos
        }
        groups {
            id
            name
            membershipStatus
            classType
            students {
                avatarPath
                id
                avatar
                firstName
                lastName
                currentBadge {
                    id
                    url
                }
                rank {
                    id
                    url1
                    url2
                }
            }
            teachers {
                id
                displayName
                profilePicturePath
            }
            
            projects {
                id
                completed
                title
                type
                journal
                units {
                    id
                    approved
                    startedAt
                    createdAt
                }
                currentUnitData {
                    id
                    deadline
                    createdAt
                    startedAt
                    title
                    index
                }
                teacher {
                    id
                    displayName
                }
            }
        }
    }
}
`;