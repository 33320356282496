import React, {useState, useEffect} from 'react';

    const Teacher = ({teacher, setActiveTeacher, unread}) => {
    const [showNotification, setShowNotification] = useState(unread);
    useEffect(() => {
        setShowNotification(unread);
    }, [unread])


    return (
            <li key={`teacherContact${teacher.id}`} className="chat-contact" onClick={() => setActiveTeacher(teacher)}>
                <img alt="teacher" src={teacher.profilePicturePath} className="chat-contact__ava" />
                {showNotification && <span className="chat-contact__unread">&nbsp;</span>}
                <span>{teacher.displayName}</span>
            </li>
    )
}

export default Teacher;
