import React,{useState} from 'react';
import styles from './Thumbnail.module.scss'



const Thumbnail = ({img,show,setShow,slide, setShowModal}) => {
    const [hover,setHover] = useState(false)
    

    return (
        <>
        <div className={styles.thumbnail} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
            <img src={img} alt='' />
            <div className={`${styles.hoverContainer} ${!hover&&styles.hide}`}>
                <div className={styles.btnWrapper}>
                    <div className={`button ${styles.bordered}`} onClick={()=>setShowModal({vis:true, idx:slide})}>preview</div>
                </div>
            </div>
        </div>
        
        </>
    );
}

// 

export default Thumbnail;