import { gql } from 'apollo-boost';

// Queries
export const SIMPLE_BOOMER_QUERY = gql`
query simpleBoomerQuery{
    currentUser {
        id
        avatar
        avatarPath
        currentBadge {
            earnedOn
            equipped
            id
            levelName
            message
            name
            url
        }
    }
}
`;


// Mutations
export const UPDATE_BOOMER = gql`
mutation updateBoomer($data: String!) {
    saveAvatarImage(data: $data) {
        errors
        student {
            id
        }
    }
}
`;