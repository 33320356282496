import React, { useEffect, useRef, useState } from 'react';
import styles from './ClassSelector.module.scss'
import ClassCard from './ClassCard/ClassCard';
import ScrollArrows from '../../Structural/ScrollArrows/ScrollArrows';
import { useHistory } from 'react-router-dom';
const ClassSelector = ({ groups, memberOfAClass, writingClub, setdasboardTerm }) => {
  const history = useHistory()
  const scrollContainer = useRef(null);
  const [ isOverflow, setIsOverflow ] = useState(false)


  useEffect(()=>{

    if( scrollContainer.current && 
        scrollContainer.current.scrollHeight>
        scrollContainer.current.clientHeight){
          setIsOverflow(true)
        }

  },[scrollContainer.current])


  const handleScroll = (e) => {

    if( e.target.scrollTop >= e.target.offsetHeight-50){
      setIsOverflow(false)
    } else {
      setIsOverflow(true)
    }

  }

  if (memberOfAClass) {
      return (

            groups && groups.map((elm, idx) =>
              {
              
              return <ClassCard
                title={elm.name}
                teacher={elm.teachers[0]&&elm.teachers[0].displayName}
                students={elm.students}
                projects={elm.projects}
                classId={elm.id}
                key={idx} />
              }
            )
      )
  } else {
    history.push('/user/profile/school')
    return <div className={styles.memberOfAClass}>You are not part of a class!</div>
  }
}

export default ClassSelector;