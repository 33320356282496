import React from 'react'
import styles from './StoryStart.module.scss';
import { existedTypeNameMessage } from 'graphql/validation/rules/UniqueTypeNames';
const StoryStartPreview = ({storyStart, exit}) => {
    return (
        <div className={styles.container}>
            <div className={styles.author}>
                <p className={styles.by}>Story Start by:</p>
                <p className={styles.authorName}>{storyStart.authorName}</p>
            </div>
            <div className={styles.storyStart}>
                {storyStart.content}
            </div>
            <div className={styles.button}>
                <button onClick={() => exit(false)} className="button button--bordered u-m-top-20 u-m-base-20">Write Your Entry!</button>
            </div>
        </div>
    )

}

export default StoryStartPreview