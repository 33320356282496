import React, { useRef, useState, useEffect } from 'react';
import styles from './HelpPopUps.module.scss'
import PopUpModal from './PopUpModal/PopUpModal';

const HelpPopUps = ({ isRejected, currentFeedback,teacher,feedbackHistory }) => {

  const [selectedPopUp, setSelectedPopUp] = useState();
  const refFeedback = useRef();
  const refRevision = useRef();
  
  useEffect(() => {
    if (!isRejected) {
      setSelectedPopUp(null)
    }
  },[isRejected])

  return (
    <>
      {/*Buttons */}
      <div className={`${styles.popUpsContainer}`}>

        {currentFeedback && <div className={styles.feedbackBtn} onClick={() => setSelectedPopUp(selectedPopUp==='feedback'?false:'feedback')} ref={refFeedback} >Feedback </div>}

        {isRejected && <div className={styles.revisionBtn} onClick={() => setSelectedPopUp(selectedPopUp==='revision'?false:'revision')} ref={refRevision}>Revision</div>}

        <PopUpModal selectedPopUp={selectedPopUp} setSelectedPopUp={setSelectedPopUp} currentFeedback={currentFeedback} isRejected={isRejected} teacher={teacher} feedbackHistory={feedbackHistory}/>

      </div>
      {/*Modals */}

    </>
  );
}

export default HelpPopUps;