import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NoCardsToRender.module.scss'
import searchBoomer from './../../../../images/newDashboard/decorations/serchBoomer.png'
const NoCardsToRender = () => {
  return (  
    <div className={styles.noCards}>
      <img src={searchBoomer} alt='Boomer pic'/>
      <h2>Oops, It seems there’s no activities to show yet</h2>
      <Link to='/dashboard' className={styles.btn}>Go to dashboard</Link>
    </div>
    );
}
 
export default NoCardsToRender ;