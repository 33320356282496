import React from 'react';

const ClassmatesIcon = ({fill}) => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.69543 4.09318C7.69543 1.83617 9.5316 0 11.7886 0C14.0455 0 15.8817 1.83621 15.8817 4.09318C15.8817 5.62404 15.0368 6.96113 13.7889 7.6631C13.197 7.99604 12.5147 8.18632 11.7886 8.18632C11.0625 8.18632 10.3801 7.99604 9.78824 7.6631C8.54039 6.96113 7.69543 5.62409 7.69543 4.09318ZM13.8729 9.02539H9.7044C7.82771 9.02539 6.3009 10.5522 6.3009 12.4289V19.3202C6.3009 19.6961 6.60566 20.0009 6.9816 20.0009H16.5957C16.9716 20.0009 17.2764 19.6961 17.2764 19.3202V12.4289C17.2764 10.5522 15.7496 9.02539 13.8729 9.02539ZM5.22687 10.7969C5.04101 11.3057 4.93947 11.8548 4.93947 12.4272V19.3185C4.93947 19.5571 4.98101 19.7861 5.05658 19.9992H2.0421C0.916058 19.9992 0 19.0831 0 17.9571V14.2004C0 12.3237 1.52681 10.7969 3.4035 10.7969H5.22687ZM18.3503 10.7969H20.1737C22.0504 10.7969 23.5772 12.3237 23.5772 14.2003V17.9571C23.5772 19.0831 22.6611 19.9992 21.5351 19.9992H18.5206C18.5962 19.7862 18.6377 19.5571 18.6377 19.3185V12.4272C18.6377 11.8549 18.5362 11.3057 18.3503 10.7969ZM4.60116 3.81445C2.91323 3.81445 1.54004 5.18764 1.54004 6.87558C1.54004 8.56351 2.91323 9.9367 4.60116 9.9367C5.02933 9.9367 5.43705 9.84801 5.80742 9.68859C6.44779 9.41289 6.97579 8.92486 7.30251 8.31356C7.53183 7.88452 7.66229 7.39506 7.66229 6.87558C7.66229 5.18769 6.2891 3.81445 4.60116 3.81445ZM15.9149 6.87558C15.9149 5.18764 17.2881 3.81445 18.976 3.81445C20.664 3.81445 22.0372 5.18764 22.0372 6.87558C22.0372 8.56351 20.664 9.9367 18.976 9.9367C18.5479 9.9367 18.1402 9.84801 17.7698 9.68859C17.1294 9.41294 16.6014 8.92491 16.2747 8.31356C16.0454 7.88456 15.9149 7.39511 15.9149 6.87558Z" fill={fill} />
    </svg>
  );
}

export default ClassmatesIcon;
