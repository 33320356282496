import React from 'react';
import cx from 'classnames';

import starEmpty from '../../../../../../images/voting/star-empty.svg';
import starFilled from '../../../../../../images/voting/star-filled.svg';

import styles from './RatingPanel.module.scss';

const Star = ({ displayOnly, enabled, rating, setRating, setTempRating, value, alt }) => {
    return (
        <button
            className={cx(styles.starBtn, { [styles.starDisplayOnly]: displayOnly }, { [styles.starDulled]: !enabled })}
            onClick={() => setRating(value)}
            onMouseEnter={() => setTempRating(value)}
            onMouseLeave={() => setTempRating(null)}
        >
            <img alt={alt} src={value > rating ? starEmpty : starFilled} />
        </button>
    )
}

export default Star;
