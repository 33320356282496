import { gql } from 'apollo-boost';

export const BOOMER_BUCKS_ACTIVITY_FEED_QUERY = gql`
query boomerBucksActivityFeedQuery {
    currentUser {
        id
        activities {
            actionItemName,
            amount,
            badge {
                id
            }
            createdAt,
            id,
            verb
        }
    }
}
`;

export const FIRST_NAME_QUERY = gql`
query firstNameQuery {
    currentUser {
        id
        email
        firstName
        lastName
        currentBadge {
            id
            name
            levelName
            url
            message
        }
        rank {
            id
            points
            url1
            url2
        }
    }
}
`;

export const BOOMER_BUCKS_QUERY = gql`
query boomerBucksQuery {
    currentUser {
        id
        boomerBucks
    }
}
`;

export const BOOMER_BUCKS_CLIENT = gql`
{
    boomerBucks @client
}
`;

export const STUDENT_QUERY = gql`
query studentQuery($id: ID!) {
    student(id: $id) {
        id
        firstName
        lastName
        avatar
        currentBadge {
            id
            url
        }
        rank {
            id
            url1
            url2
        }
        badgesList {
            id
            name
            levelName
            url
            message
            earnedOn
        }
    }
}
`;

export const CONTACTS_QUERY = gql`
query contactsQuery {
    currentUser {
        id
        chatterId
        groups {
            id
            teachers {
                id
                chatterId
                premium
                displayName
                profilePicturePath
            }
        }
    }
}
`;

export const MESSAGE_HISTORY_QUERY = gql`
query messageHistoryQuery($id: ID!) {
    currentUser {
        id
        chatMessages(teacherId: $id) {
            id
            sentby
            body
            createdAt
            seen
            student {
                id
                avatarPath
            }
            teacher {
                id
                chatterId
                profilePicturePath
            }
        }
    }
}
`;

export const SEND_MESSAGE = gql`
mutation sendMessage($id: ID!, $body: String!) {
    addChatMessage(teacherId: $id, body: $body) {
        errors
    }
}
`;

export const SEND_ROOMCHAT_MESSAGE = gql`
mutation addRoomChatMessage($id: ID!, $body: String!, $unitId: ID!) {
    addRoomChatMessage(teacherId: $id, body: $body, unitId: $unitId) {

        errors
    }
}
`;

export const SEEN_MESSAGE = gql`
mutation markAsSeen($id: ID!) {
    markChatMessagesAsSeen(teacherId: $id) {
        errors
    }
}
`;
