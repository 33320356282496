
let apiUrl; // API URL
let appUrl; // APP URL
let loginUrl; // LOGIN ENDPOINT
let logoutUrl; // LOGOUT ENDPOINT
let subscriptionUrl; // SUBSCRIPTION END POINT

const hosts = {
	localhost: "localhost",
	testing: "sand-students",
	production: "boomwriter"
}

const appEndPoints = {
	localhost: "http://localhost:3010",
	testing: "https://sand-students.boomwriter.com",
	production: "https://students.boomwriter.com"
}

const apiEndPoints = {
	localhost: "https://sand-students-api.boomwriter.com",
	testing: "https://sand-students-api.boomwriter.com",
	production: "https://students-api.boomwriter.com"
}

const loginEndPoints = {
	localhost: "http://localhost:3002/student/login",
	testing: "https://sand-account.boomwriter.com/student/login",
	production: "https://account.boomwriter.com/student/login"
}

const logoutEndPoints = {
	localhost: "http://localhost:3002/logout",
	testing: "https://sand-account.boomwriter.com/logout",
	production: "https://account.boomwriter.com/logout"
}

const subscriptionEndPoints = {
	localhost: "wss://sand-students-api.boomwriter.com",
	testing: "wss://sand-students-api.boomwriter.com",
	production: "wss://students-api.boomwriter.com"
}

if (window.location.host.includes(hosts.localhost)) {
	apiUrl = apiEndPoints.localhost;
	appUrl = appEndPoints.localhost;
	loginUrl = loginEndPoints.localhost;
	logoutUrl = logoutEndPoints.localhost;
	subscriptionUrl = subscriptionEndPoints.localhost;
}

if (window.location.host.includes(hosts.production)) {
	apiUrl = apiEndPoints.production;
	appUrl = appEndPoints.production;
	loginUrl = loginEndPoints.production;
	logoutUrl = logoutEndPoints.production;
	subscriptionUrl = subscriptionEndPoints.production;
}

if (window.location.host.includes(hosts.testing)) {
	apiUrl = apiEndPoints.testing;
	appUrl = appEndPoints.testing;
	loginUrl = loginEndPoints.testing;
	logoutUrl = logoutEndPoints.testing;
	subscriptionUrl = subscriptionEndPoints.testing;
}

if (window.location.host.includes(hosts.sandFaith)) {
	apiUrl = apiEndPoints.sandFaith;
	appUrl = appEndPoints.sandFaith;
	loginUrl = loginEndPoints.sandFaith;
	logoutUrl = logoutEndPoints.sandFaith;
	subscriptionUrl = subscriptionEndPoints.sandFaith;
}

if (window.location.host.includes(hosts.prodFaith)) {
	apiUrl = apiEndPoints.prodFaith;
	appUrl = appEndPoints.prodFaith;
	loginUrl = loginEndPoints.prodFaith;
	logoutUrl = logoutEndPoints.prodFaith;
	subscriptionUrl = subscriptionEndPoints.prodFaith;
}

export const API_ENDPOINT = apiUrl;
// export const APP_ENDPOINT = appUrl; OBSOLETE - used LINK TO="/" instead
export const LOGIN_ENDPOINT = loginUrl; //only used in Routes.js, otherwise taken from localStorage
export const LOGOUT_ENDPOINT = logoutUrl; //not used anywhere in app
export const SUBSCRIPTION_ENDPOINT = subscriptionUrl;
