import React from 'react';
import ActivityCard from '../ProjectCard/ProjectCard';
import NoCardsToRender from '../NoCardsToRender/NoCardsToRender';

const ProjectCardRenderer = ({projects,term,myStudentId, source = null}) => {
return projects[0]? 
    projects.map((elm,idx) =>  <ActivityCard title={elm.title} projectId={elm.id} key={idx} type={elm.type} journal={elm.journal} currentUnitData={elm.currentUnitData} units={elm.units} term={term} myStudentId={myStudentId}/>)
: 
    source === 'dash' ? null : <NoCardsToRender/>
   
}
 
export default ProjectCardRenderer;