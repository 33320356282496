import React, { useState } from 'react';
import { DRAFT, SCHEDULED } from '../../../../../utils/globals';
import styles from './SidePanel.module.scss'
import PreviousChapters from './Tabs/PreviousChapters/PreviousChapters';
import Tabs from './Tabs/Tabs';
import UnitInstructions from './Tabs/UnitInstructions/UnitInstructions'

const SidePanel = ({ setShowFullRubric, units, currentUnit,entryText, setShowModal }) => {

  const [tabTerm, setTabTerm] = useState('instructions');

  let activeUnits = units.filter(elm => elm.phase!==DRAFT && elm.phase!==SCHEDULED).length

  return (
    <div className={styles.sidePanel}>
      <Tabs tabTerm={tabTerm} setTabTerm={setTabTerm} activeUnits={activeUnits} currentUnitIndex={currentUnit.index}/>
      <div className={styles.contentPanel}>

        {tabTerm === 'instructions' && <UnitInstructions setShowModal={setShowModal} entryText={entryText} currentUnit={currentUnit} showFullRubric={setShowFullRubric} />}
        {tabTerm === 'previous' && activeUnits > 1 && <PreviousChapters currentUnit={currentUnit} units={units} />}


      </div>
    </div>
  );
}

export default SidePanel;