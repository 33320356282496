import React from 'react';
import styles from './Status.module.scss'
import lens from '../../../../../images/newDashboard/icons/lens.png'
import finishIcon from '../../../../../images/newDashboard/icons/finishIcon.png'

import hangingBoomer from '../../../../../images/newDashboard/decorations/hangingBoomer.png'

const Status = ({unitStatus}) => {

  if(unitStatus==='dueDatePassed') return ( 
    <div className={`${styles.status} ${styles.dueDatePassed}`}>
      <p>Due date passed</p>
    </div> 
  )

  if(unitStatus==='completed') return ( 
    <div className={styles.finishStatus}>
      <img src={finishIcon} alt='' />
      <p>Completed</p>
    </div> 
  )
  if(unitStatus==='approved') return ( 
    <div className={styles.finishStatus}>
      <img src={finishIcon} alt='' />
      <p>Completed</p> {/* Vinicius says that aprroved units have to display as Completed*/}
    </div> 
  )
  
  if(unitStatus==='submitted') return ( 
    <div className={styles.finishStatus}>
      <img src={finishIcon} alt='' />
      <p>Submitted</p>
    </div> 
  )
  
  if(unitStatus==='finishWriting') return(
    <div className={`${styles.status} ${styles.pink}`}>
      <p>Hey! Finish writing :)</p>
      <img className={styles.hangingBoomer} src={hangingBoomer} alt=""/>
      
    </div> 
  )

  if(unitStatus==='startWriting') return(
    <div className={`${styles.status} ${styles.pink}`}>
      <p>You can start writing</p>
      <img className={styles.hangingBoomer} src={hangingBoomer} alt=""/>

    </div> 
  )

  if(unitStatus==='storyStart') return(
    <div className={`${styles.status} ${styles.pink}`}>
      <p>This is the story start</p>
      <img className={styles.hangingBoomer} src={hangingBoomer} alt=""/>

    </div> 
  )

  if(unitStatus==='review') return ( 
    <div className={`${styles.status} ${styles.pink}`}>
      <p>Revise your entry</p>
      <img className={styles.hangingBoomer} src={hangingBoomer} alt=""/>

    </div> 
  )

  if(unitStatus==='scheduled') return ( 
    <div className={`${styles.status} ${styles.pink}`}>
      <p>Scheduled Unit</p>
      <img className={styles.hangingBoomer} src={hangingBoomer} alt=""/>

    </div> 
  )

  if(unitStatus==='startVoting') return ( 
    <div className={`${styles.status} ${styles.pink}`}>
      <p>You can start voting</p>
      <img className={styles.hangingBoomer} src={hangingBoomer} alt=""/>
    </div> 
  )

  if(unitStatus==='finishVoting') return ( 
    <div className={`${styles.status} ${styles.pink}`}>
      <p>Hey! Finish voting :)</p>
      <img className={styles.hangingBoomer} src={hangingBoomer} alt=""/>

    </div> 
  )

  if(unitStatus==='voted') return ( 
    <div className={styles.finishStatus}>
      <img src={finishIcon} alt='' />
      <p> Voted </p>
    </div> 
  )

  if(unitStatus==='tiebreak') return ( 
    <div className={styles.finishStatus}>
      <p>Tie Break</p>
    </div> 
  )

  

  if(unitStatus==='draft') return ( 
    <div className={styles.status}>
      <img src={lens} alt='' />
      <p>draft</p>
    </div> 
  )
}
 
export default Status;