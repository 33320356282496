import ls from 'local-storage';
let chatApi; // API URL

const hosts = {
	localhost: "localhost",
	testing: "sand-students",
	production: "boomwriter",
	sandFaith: "sand-write.faith-journals",
	prodFaith: "write.faith-journals"
}

if (window.location.host.includes(hosts.localhost)) {
	chatApi = 'wss://https://sand-students-api.boomwriter.com/cable?from=BWStudents&token=';
}
if (window.location.host.includes(hosts.production)) {
	chatApi = 'wss://schools.boomwriter.com/cable?from=BWStudents&token=';
}
if (window.location.host.includes(hosts.testing)) {
	chatApi = 'wss://sand-schools.boomwriter.com/cable?from=BWStudents&token=';
}
if (window.location.host.includes(hosts.sandFaith)) {
	chatApi = 'wss://sand-schools-api.faith-journals.com/cable?from=BWStudents&token=';
}
if (window.location.host.includes(hosts.prodFaith)) {
	chatApi = 'wss://schools-api.faith-journals.com/cable?from=BWStudents&token=';
}


// export const API_WS_ROOT = `${ls('chatUrl')}/cable?from=BWStudents&token=`;
export const API_WS_ROOT = chatApi;
