import React from 'react';
import WritingScroller from './WritingScroller';
import styles from './SlideScroller.module.scss';

const DemoScroller = () => {
    return (
        <div className={styles.demoOnlyWrapper}>
            <h3 style={{ height: '35px' }}>Slider/scoller page</h3>
            <WritingScroller />
        </div>
    )
}
export default DemoScroller;