import React, { useEffect, useRef, useState } from 'react';
import striptags from 'striptags';
import EntryHeader from './EntryHeader/EntryHeader';
import EntrySubHeader from './EntrySubHeader/EntrySubHeader';
import styles from './MainWritingEditor.module.scss'
import styles2 from '../UnitWriting.module.scss'
// import EntryHeader from './../../../../Project/Unit/UnitWriting/EntryHeader.js'
import HelpPopUps from './HelpPopUps/HelpPopUps';
import ContentEditable from 'react-contenteditable';
import TopTabs from './TopTabs/TopTabs';
import BreakoutRooms from './BreakoutRooms/BreakoutRooms';
import AutomatickFeedback from './AutomaticFeedback/AutomaticFeedback';
import Grading from './Grading/Grading';
import { isBefore, parseISO } from 'date-fns'
import { getAutoFeedback } from '../../../../../API/api.service';
import { REVIEW } from '../../../../../utils/globals';
import IntroVideoButton from './IntroVideoButton/IntroVideoButton';
const MainWritingEditor = ({
  setShowFullRubric,
  showIdleModal,
  entry,
  setEntry,
  currentUserEntry,
  isRejected,
  toggleShowHistory,
  title,
  wordLimit,
  teacher,
  edit,
  roomPartnerEntry,
  shareGrading,
  shareGrammar,
  overallGradingScore,
  introVideoUrl,
  setshowVideoModal,
  scrollUnits,
  units,
  unitIdx,
  isStoryStart,
  isReview,
  activityType,
  setLocalWordCount }) => {

  const [blurred, setBlurred] = useState(true);
  const [selectedTopTab, setSelectedTopTab] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const { currentFeedback, feedbackHistory, submitted, approved, revisionStatus, canWrite } = currentUserEntry;
  const [autoFeedbackResponse, setAutoFeedbackResponse] = useState()
  const [mouseOutSave, setMouseOutSave] = useState(false)
  const contentEditableRef = useRef(null)
  const [ showWinner, setShowWinner ] = useState(true)
  const [ hideEntryVideoBtn, setHideEntryVideoBtn ] = useState(false)
  const [showLastChapterWarning, setShowLastChapterWarning] = useState(entry.text.length === 0)
  const localStorageEntry = JSON.parse(localStorage.getItem(entry.id))
  const localStorageMoreRecent = !localStorageEntry ? false : isBefore(parseISO(currentUserEntry.lastSavedAt), parseISO(localStorageEntry.timestamp))

  const [dynamicEntry, setDnyamicEntry] = useState(entry);

  let marginClass = (autoFeedbackResponse&&autoFeedbackResponse.report&&autoFeedbackResponse.report.length) || roomPartnerEntry ? styles.shift_down : ''

  const callAPI = async () => {
    setAutoFeedbackResponse(await getAutoFeedback(entry.id))
  }
  useEffect(() => {

    setAutoFeedbackResponse([])
    if(shareGrammar){ 
      callAPI() 
    }

  }, [entry.original]);

  useEffect(()=>{
    if(localStorageEntry&&!localStorageMoreRecent){
      localStorage.removeItem(entry.id)
    }
    
  },[])

  const unitIsInReview = units[unitIdx].phase === REVIEW;


  /* Functions */

  const toggleBlur = () => {
    setBlurred(b => !b);
  };


  const countWordsLocal = (entryText) => {
    entryText = entryText.replace(/<[^>]*>/g, '');
    var matches = entryText.match(/[\w\d\’\'-]+/gi);
    return matches ? matches.length : 0;
  }

  const updateText = e => {
    
    const localStorageItem = {
      timestamp: new Date().toISOString(),
      entryText:e.target.value,
      entryId:entry.id
    }
    setLocalWordCount(countWordsLocal(e.target.value))
    localStorage.setItem(entry.id, JSON.stringify(localStorageItem))

    if (e.nativeEvent.inputType === "insertFromPaste") {
      let textwithOnlyParagrahsAndBrs = striptags(e.target.value.replace(/style="[^"]*"/g, ""), ['p', 'br'])
      let linebreakText = striptags(textwithOnlyParagrahsAndBrs, [], '\n')
      setEntry({
        ...entry,
        text: linebreakText
      })
    } else {
      setEntry({
        ...entry,
        text: e.target.value,
        restore: false
      })

    }
  }

  const onClickEvent = event => {
    if (event.keyCode === 9) {
      event.preventDefault()
      document.execCommand('insertHTML', false, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
    }
    if (event.keyCode === 13) {
      event.preventDefault()
      document.execCommand('insertHTML', false, '\n');

    }
  }

  const setSelectedTopTabAnimated = (term) => {


    if (!triggerAnimation) {
      setTriggerAnimation(term)
      setSelectedTopTab(term)

    } else if (!term) {
      setTriggerAnimation(false)
      setTimeout(() => {
        setSelectedTopTab(term)
      }, 1500);

    } else if (triggerAnimation !== term) {
      setTriggerAnimation(false)
      setTimeout(() => {
        setSelectedTopTab(term)
        setTriggerAnimation(true)
      }, 1500);

    } else {
      setTriggerAnimation(term)
      setSelectedTopTab(term)
    }
  }

  /* Handling Save on Mouse Left */

  const handleMouseLeave = () => {
    setMouseOutSave(true)
  }
  useEffect(() => {
    if (contentEditableRef) {
      contentEditableRef.current && contentEditableRef.current.el.current.addEventListener('mouseleave', handleMouseLeave)
      //cleanup
      return () => {
        window.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [contentEditableRef]);


  return (
    <>
      <div className={`${styles.mainWritingEditor} ${marginClass}`} style={!triggerAnimation ? { borderRadius: '10px 0px 10px 10px' } : { borderRadius: '10px 10px 10px 10px' }}>
      <div className={triggerAnimation ? `${styles.half} ${styles.principalWritingPanel} ${styles.open}` : styles.principalWritingPanel}>

        <EntrySubHeader
          setSelectedTopTabAnimated={setSelectedTopTabAnimated}
          isOpenBreakout={triggerAnimation}
          isStoryStart={isStoryStart}
          isReview={unitIsInReview}
          setEntry={setEntry}
          showWinner={showWinner}
          setShowWinner={setShowWinner}
          entry={entry}
          blurred={blurred}
          showIdleModal={showIdleModal}
          scrollUnits={scrollUnits}
          units={units}
          edit={edit}
          unitIdx={unitIdx}
          entry={entry} 
          max={wordLimit}
          title={title}
          toggleShowHistory={toggleShowHistory}
          currentUserEntry={currentUserEntry}
          setMouseOutSave={setMouseOutSave}
          mouseOutSave={mouseOutSave}
          overallGradingScore={overallGradingScore}
        />
        {activityType === 3 && !showWinner &&
        <div className={styles.authorDetails}>
          <p className={styles.authorDetails__chapter}>{units[unitIdx].title}</p>
          <p>by {currentUserEntry.author.firstName} {currentUserEntry.author.lastName}</p>
        </div>
        }
        {
          !canWrite && !isStoryStart ?

            <p>{`You are not allowed to submit an entry for this ${ activityType === 3 ? "chapter":"section" }`}</p>

        :
        
          (edit && !isReview) || (edit && isRejected && !isReview) ?
            <ContentEditable
              html={
                isStoryStart ? units[0].storyStart.content :
                !isStoryStart && isReview && !submitted && !showWinner && entry.text==="" ? 'You did not submit an entry' :
                localStorageEntry&&localStorageMoreRecent ? localStorageEntry.entryText :
                entry.text
              }
              className={styles.contentEditable}
              onBlur={toggleBlur}
              onChange={updateText}
              onKeyDown={onClickEvent}
              onFocus={toggleBlur}
              ref={contentEditableRef}

            />
        :
          <ContentEditable
            html={
              isStoryStart ? units[0].storyStart.content :
              !isStoryStart && isReview && !submitted && !showWinner && entry.text==="" ? 'You did not submit an entry' :
              localStorageEntry&&localStorageMoreRecent ? localStorageEntry.entryText :
              showWinner && units[unitIdx].winner.id ? units[unitIdx].winner.entryText
            : entry.text
            }
            className={styles.contentEditable}
            onBlur={toggleBlur}
            onChange={updateText}
            onKeyDown={onClickEvent}
            onFocus={toggleBlur}
            disabled={true} />
        }
        {activityType === 3 && (unitIdx + 1 === units.length) &&
        <div className={styles.endPanel}>
              <p>The End</p>
        </div> 
        }     
      </div>
      <div className={triggerAnimation ? `${styles.active} ${styles.auxiliarPanel}` : styles.auxiliarPanel}>
        {selectedTopTab === 'partner1' && roomPartnerEntry && <BreakoutRooms roomPartnerEntry={roomPartnerEntry} setSelectedTopTabAnimated={setSelectedTopTabAnimated} />}
        {selectedTopTab === 'feedback' && autoFeedbackResponse && autoFeedbackResponse.report && autoFeedbackResponse.report.length && <AutomatickFeedback setSelectedTopTabAnimated={setSelectedTopTabAnimated} autoFeedbackResponse={autoFeedbackResponse} entryText={entry.text} />}
      </div>
      <HelpPopUps
        shiftRight={introVideoUrl}
        isRejected={isRejected && !isReview}
        currentFeedback={currentFeedback}
        feedbackHistory={feedbackHistory}
        teacher={teacher} />

      <TopTabs
        shareGrammar={shareGrammar}
        setSelectedTopTabAnimated={setSelectedTopTabAnimated}
        selectedTopTab={selectedTopTab}
        roomPartnerName={roomPartnerEntry ? roomPartnerEntry.authorName : false}
        authorAvatarPath={roomPartnerEntry ? roomPartnerEntry.authorAvatarPath : false}
        automaticFeedback={autoFeedbackResponse}
      />

      {introVideoUrl && 

        <IntroVideoButton 
          isRejected={isRejected}
          setHide={setHideEntryVideoBtn} 
          hide={hideEntryVideoBtn} 
          setshowVideoModal={setshowVideoModal} 
          showVideoModal={setshowVideoModal} />
      }

      {(overallGradingScore && shareGrading && !selectedTopTab) ? <Grading overallGradingScore={overallGradingScore} setShowFullRubric={setShowFullRubric} /> : null}
    </div>
    </>
  );
}

export default MainWritingEditor;

// style={((isRejected&&!selectedTopTab)||(currentFeedback&&!selectedTopTab))&&{paddingBottom:'80px'}}