import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import { UPDATE_BOOMER } from '../../queries';

const AutoSaveBoomer = ({ imgData }) => {
    const client = useApolloClient(); 
    
    client.mutate({
        mutation: UPDATE_BOOMER,
        variables: {
            data: imgData
        }
    });

    return <img alt="Your Boomer" className="boomer-holder__image" src={imgData} />;
}

export default AutoSaveBoomer;
