import React, { useEffect, useState } from 'react';
import { withRouter, Route, Switch  } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import queryString from 'query-string';
import ls from 'local-storage';

import Logout from './Auth/Logout';
import LoggedInRouter from './LoggedInRouter.routes';
import { LOGGED_IN} from '../queries';
import jwt_decode from 'jwt-decode'
import { LOGIN_ENDPOINT } from '../utils/endPoints';

const Routes = ({ location, history }) => {
    const [targetRoute, setTargetRoute] = useState();
    const { data } = useQuery(LOGGED_IN);
    const client = useApolloClient();
    const values = queryString.parse(location.search);

    useEffect(() => {
        if (location.pathname === "/login" && values.ft) {
          const { t,
            backUrl="https://students-api.boomwriter.com/", 
            authUrl="https://boomwriter.com/", 
            chatUrl="https://schools.boomwriter.com/",
            env="prod",
            imp=false
          } = jwt_decode(values.ft)

          ls('backUrl', backUrl)
          ls('authUrl', authUrl)
          ls('chatUrl', chatUrl)
          ls('env', env)
          ls('imp', imp)
          ls('token', t);

          client.writeData({ data: { loggedIn: true, forcedLogout: false } });
          const tr = (ls('targetRoute') && !ls('targetRoute').includes("logout")) ? ls('targetRoute') : null;
          setTargetRoute(tr);

        } else if (!ls('token')) {
            // window.location.href = `${ls('authUrl')}/auth/student`;
            window.location.href = LOGIN_ENDPOINT;

        }
    }, []);

    if (data.loggedIn && targetRoute) {
        history.push({targetRoute});
        setTargetRoute(null);
    }

    return (
      
      <Switch>
          {data.loggedIn && location.pathname !== "/logout" && <LoggedInRouter />}
          {/*If the user is logged then you go to LoggedInRouter */}
          <Route path="/logout" component={Logout} />
      </Switch>
        
    );
}

export default withRouter(Routes);
