import React from 'react';
import striptags from 'striptags';
import styles from './BreakoutRooms.module.scss'

const BreakoutRooms = ({ setSelectedTopTabAnimated, roomPartnerEntry: { authorAvatarPath, entryText, authorName } }) => {


  return (
    <div className={styles.breakoutContainer}>
      <div className={styles.infoBox}>
        <div className={styles.imageFrame}>
          <figure>
            <img src={authorAvatarPath} alt="Partner Boomer" />
          </figure>
        </div>
        <div className={styles.infoBoxText}>
          <h6>{authorName}'s Entry</h6>
          <p>Writing Partner</p>
        </div>
        <div className={styles.closeBtn} onClick={() => setSelectedTopTabAnimated(false)}>
      </div>
      </div>
      <div className={styles.textContainer}>
        {striptags(entryText, [], '\n')}
      </div>
    </div>
  );
}

export default BreakoutRooms;