import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
// import { PROJECT_VOTING_QUERY, SEE_TICKLES_MUTATION } from '../../../../queries';
import MainLoader from '../../../Shared/MainLoader';
import Entry from './Entry';
import ConfirmVote from './ConfirmVote';
import { entryMarkup, TIE_BREAK } from '../../../../utils/globals';
import BoomerHeading from '../../../Shared/BoomerHeading';
import check from '../../../../images/check-green.png';
import boomers from '../../../../images/project-boomers.png';
import close from '../../../../images/close-icon.png';
import notes from '../../../../images/notes.png';
import info from '../../../../images/info-icon.png';
import coins from '../../../../images/coins.png';
import loader from '../../../../images/small-loader.svg';
import star from '../../../../images/star.png';
import highFive from '../../../../images/high-five.png';
import alertBoomer from '../../../../images/alert-boomer.png';
import PublishScroller from '../../SlideScroller/PublishScroller'
// import chat from '../../../../images/chat.png';
// import TicklesModal from '../../Tickles/TicklesModal';
// import AddTickleModal from '../../Tickles/AddTickleModal';

const UnitVoting = ({ history, project, isSW }) => {
    const client = useApolloClient();
    const canWrite = project.currentUnitData.currentUserEntry.canWrite
    
    const [state, setState] = useState({
        entryOpen: false,
        entrySetIds: [],
        loading: false,
        selectedEntry: -1,
        showConfirm: false,
        showInstructions: false,
        showLoader: -1,
        voteEntry: {},
        selectedUnit: null,
        showTicklesModal: false,
        showAddTickleModal: false,
    });

    const [showScroller, setShowScroller] = useState(false)

    const [hidePublished, setHidePublished] = useState(false)

    useEffect(() => {
        let ids = [];
        _.map(project.currentUnitData.currentUserNextEntriesToVote, (entry) => {
            ids.push(entry.id);
        });
        setState(prevState => {
            return {
                ...prevState,
                entrySetIds: ids,
            };
        })
    }, []);
    
    const beeCheck = (project.book && project.book.bookTemplate && project.book.bookTemplate.bee) ? true : false;

    const reset = (num, entryOpen) => {
        setState(prevState => {
            return {
                ...prevState,
                entryOpen: entryOpen !== undefined ? entryOpen : prevState.entryOpen,
                showLoader: num
            }
        });
    }

    const toggleInstructions = () => {
        setState(prevState => {
            return {
                ...prevState,
                showInstructions: !state.showInstructions,
            };
        })
    }

    const toggleConfirm = (entry, number) => {
        let voteEntry = {
            ...entry,
            number: number,
        }
        setState(prevState => {
            return {
                ...prevState,
                showConfirm: !state.showConfirm,
                voteEntry: voteEntry,
            }
        });
    }

    const renderConfirm = () => {
        return (
            <ConfirmVote
                client={client}
                onClose={() => toggleConfirm()}
                showScroller={setShowScroller}
                lastChapter={project.currentUnitData.index === project.units.length}
                projectId={project.id}
                unitId={project.currentUnitData.id}
                phase={project.currentUnitData.phase}
                index={project.currentUnitData.index}
                voteEntry={state.voteEntry}
                isSW={isSW}
            />
        );
    }

    const getProgressBar = () => {
        const {
            currentUnitData: {
                currentUserNextEntriesToVote
            }
        } = project;

        const entriesRead = _.filter(currentUserNextEntriesToVote, ['seenByCurrentUser', true]).length;
        const entriesTotal = currentUserNextEntriesToVote.length;
        let width = entriesRead / entriesTotal;
        width = width * 100;
        width = width + '%';

        const canVote = _.filter(currentUserNextEntriesToVote, ['seenByCurrentUser', false]).length === 0;
        return (
            <div className="voting__progress">
                <p>Reading Progress</p>
                <div className="voting__bar">
                    <div className="voting__bar-inner" style={{width: width}}>
                    </div>
                </div>
                {canVote ? <div className="voting__progress-check"><img src={check} alt="check" className="voting__progress-img"/></div> : null}
                {canVote ? <div className="voting__progress-text voting__progress-text--active">Voting Activated</div> : <div className="voting__progress-text">Voting Inactive</div>}
            </div>
        );
    }

    // const onShowAddTickleModal = unit => {
    //     setState(prevState => {
    //         return {
    //             ...prevState,
    //             showAddTickleModal: true,
    //             selectedUnit: unit
    //         }
    //     });
    // }
    
    const prevUnit = event => {
        history.push(`/project/${project.id}/${project.currentUnitData.index}/edit`);
    }

    // const onShowTicklesModal = unit => {
    //     if (project.currentUnitData.currentUserTicklesCounter > 0) {
    //         client.mutate({
    //             mutation: SEE_TICKLES_MUTATION,
    //             awaitRefetchQueries: false,
    //             refetchQueries: [ 
    //                 { query: PROJECT_VOTING_QUERY, variables: { id: project.id, unitId: currentUnitData.id } }
    //             ],
    //             variables: {
    //                 id: project.currentUnitData.id,
    //             }
    //         });
    //     }
    //     setState(prevState => {
    //         return {
    //             ...prevState,
    //             showTicklesModal: true,
    //             selectedUnit: unit
    //         }
    //     });
    // }

    // const onCloseAddTickleModal = () => {
    //     setState(prevState => {
    //         return {
    //         ...prevState,
    //             showAddTickleModal: false
    //         };
    //     });   
    // }
    
    // const onCloseTickleModal = () => {
    //     setState(prevState => {
    //         return {
    //         ...prevState,
    //             showTicklesModal: false
    //         }
    //     });
    // }

    const {
        currentUnitData: {
            currentUserNextEntriesToVote,
            currentUserVoted,
            id,
            // currentUserTicklesCounter,
            vocab
        },
        currentUnitData,
        // units
    } = project;


    // const { showTicklesModal, showAddTickleModal } = state;
    if (showScroller) {
        return <PublishScroller history={history} oppo={true} exit={setShowScroller} alternate={project.currentUnitData.currentUserEntry.approved} />
    }
    if (currentUserNextEntriesToVote && currentUserNextEntriesToVote.length > 1) {
        const canVote = _.filter(currentUserNextEntriesToVote, ['seenByCurrentUser', false]).length === 0;
        return (
            <div className="voting">
                {state.loading && <MainLoader mutation="project" />}
                <BoomerHeading
                    bee={beeCheck}
                    mainHeading={currentUserVoted ? "Vote Again!" : "Cast your vote!"}
                    showBoomer={true}
                    subHeading="Before you vote you must read all entries"
                    mutation="writing"
                />
                <div className="voting__content">
                    <div className="voting__side">
                        {getProgressBar()}
                        <span className="voting__instruction-btn">Instructions</span>
                        <div className={`voting__instructions ${state.showInstructions && 'voting__instructions--show'}`}>
                            <button className="instructions__close--voting" onClick={() => toggleInstructions()}><img src={close} alt="Close" className="instructions__close-icon--voting"/></button>
                            <div className="instructions-mob instructions-mob--voting">
                                <img src={boomers} alt="Boomers" className="instruction-boomers--voting"/>
                                <div className="u-border-sep u-m-base-40">
                                    <span className="u-border-sep__text">Instructions</span>
                                </div>
                            </div>
                            <div className="instruction u-m-base-10">
                                <div className="instruction__side">
                                    <img src={notes} alt="Notes" className="instruction__img" />
                                </div>
                                <div className="instruction__main">
                                    <p>Voting Guidelines</p>
                                    <p className="instruction__detail u-m-top-5">Click on an entry to read. After reading all entries, Click on your preferred entry to vote.</p>
                                    <p className="instruction__detail instruction__detail-prompt u-m-top-20"><img src={coins} alt="Coins" className="instruction__detail-coin" /> Earn 2 BoomerBucks!</p>
                                </div>
                            </div>
                            {project.currentUnitData.currentUserEntry.submitted === false &&
                                <div className="instruction u-m-top-40">
                                    <div className="instruction__side">
                                        &nbsp;
                                    </div>
                                    {canWrite &&
                                        <div className="instruction__main">
                                            <p>Write  an entry!</p>
                                            <p className="instruction__detail u-m-top-5">You didn't submit an entry for this section. You can go back and write one now.</p>
                                            <button className="button u-full-width u-m-top-10" onClick={() => prevUnit()}>Write an Entry</button>
                                            <p className="instruction__detail instruction__detail-prompt u-m-top-20">Please note, this entry will not be included in this round of voting.</p>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <button className="project-tab--instructions voting-tab--instructions" onClick={() => toggleInstructions()}><img src={info} alt="i" className="voting-tab__info"/>Instructions</button>
                    </div>
                    <ul className="voting__entries-list">
                        {
                            _.map(currentUserNextEntriesToVote, (entry, i) => {
                                return (
                                    <li key={`entry-${entry.id}`} className="voting__entries-list__entry light-panel">
                                        <h2 className="voting__entry__header">Entry {i + 1} {entry.bookmarkedByCurrentUser && <img src={star} className="voting__entry__star" alt="Bookmarked" />}</h2>
                                        <div className="voting__entry-preview" dangerouslySetInnerHTML={entryMarkup(entry.entryText)} />
                                        {entry.seenByCurrentUser && <p className="u-pill u-pill--success u-m-base-10"><img className="u-pill__image" src={check} alt="check" />Entry Read</p>}
                                        {state.showLoader === (i) ? <img src={loader} alt="Loading..." className="voting__entry-loader" /> : null}
                                        <div className={`voting__entry-buttons ${canVote && 'voting__entry-buttons--double'}`}>
                                            <button 
                                                onClick={() => {
                                                    setState(prevState => {
                                                        return { ...prevState, entryOpen: true, selectedEntry: i }
                                                    })
                                                }}
                                                className="button button--bordered"
                                            >
                                                Read
                                            </button>
                                            {
                                                canVote && (
                                                    <button className="button button--green button--bordered" onClick={() => toggleConfirm(entry, i + 1)}>Vote</button>
                                                )
                                            }
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                {
                    state.entryOpen ? 
                    (
                        <Entry 
                            entry={currentUserNextEntriesToVote[state.selectedEntry]}
                            number={state.selectedEntry + 1}
                            entryIDs={state.entrySetIds}
                            projectId={project.id}
                            unitId={id}
                            vocab={vocab}
                            reset={reset}
                            canVote={canVote}
                            vote={toggleConfirm}
                        />
                    ) : null
                }
                {state.showConfirm ? renderConfirm() : null}
            </div>
            
        );
    }
    if (currentUnitData.phase === TIE_BREAK) {
        return (
            <Fragment>
                <BoomerHeading
                    mainHeading="Wow, voting ended in a tie!"
                    showBoomer={true}
                    subHeading="The winner will be decided soon."
                />
                <div className="light-panel voting-success">
                    <img src={alertBoomer} alt="Congratulations" className="voting-success__boomers" />
                    <p className="u-m-top-20">Amazingly voting ended in a tie, the winner will be decided soon please check back shortly.</p>
                </div>
            </Fragment>
        )
    }
    if (project.currentUnitData.currentUserVoted) {
        return (
            <>
            {project.currentUnitData.index === project.units.length && !hidePublished && isSW ? 
                <><PublishScroller history={history} exit={setHidePublished} /></> :
                <Fragment>
                    <BoomerHeading
                        mainHeading="Thanks for voting!"
                        showBoomer={true}
                        subHeading="Check back soon to see the winner"
                    />
                    <div className="light-panel voting-success">
                        <img src={highFive} alt="Congratulations" className="voting-success__boomers" />
                        <p>You've voted as many times as possible!</p>
                    </div>
                </Fragment>
            }
            </>
        )
    } else {
        return (
            <>
                {project.currentUnitData.index === project.units.length && !hidePublished && isSW && project.currentUnitData.currentUserVoted  ? 
                <>
                <PublishScroller history={history} exit={setHidePublished}  /></> :
                <>
                    <BoomerHeading
                        mainHeading="Uh oh!"
                        showBoomer={true}
                        subHeading="Sorry you can't vote at this time."
                    />
                    <div className="light-panel voting-success">
                        <img src={alertBoomer} alt="Congratulations" className="voting-success__boomers" />
                        <p className="u-m-top-20">There are not enough entries for you to vote.</p>
                    </div>
                </>
            }
            </>
        )
    };
}

export default withRouter(UnitVoting);
