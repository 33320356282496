import React, { useState } from 'react';
import { entryMarkup } from '../../../../../../../utils/globals';
import styles from './PreviousChapters.module.scss'

const PreviousChapters = ({ currentUnit, units }) => {

    const [previousIdx, setPreviousIdx] = useState(currentUnit.index-1);

    const togglePrev = e => setPreviousIdx(parseInt(e.target.value))
    

    return (
        <div className={styles.previousChaptersContainer}>
            <select className={styles.dropdown} onChange={togglePrev} value={previousIdx}>
                {units.map((unit, idx) => (unit.index < currentUnit.index && unit.phase !== 1) && <option key={idx} value={unit.index}>{unit.title}</option>)}
            </select>
            <div className={styles.textContainer}>
                <p dangerouslySetInnerHTML={entryMarkup(units[previousIdx-1].storyStart ? units[previousIdx-1].storyStart.content : units[previousIdx-1].winner.entryText ? units[previousIdx-1].winner.entryText : units[previousIdx-1].currentUserEntry.entryText)} />
            </div>
        </div>
    );
}

export default PreviousChapters;