import React, { useEffect, useState } from 'react';
import styles from './AutomaticFeedback.module.scss'
import './AutomaticFeedback.module.scss'
import grammarbot from './../../../../../../images/newDashboard/icons/grammarBot.png'
import { setGrammarFeedback } from './autoFeedbackFuncs'

const AutomatickFeedback = ({ setSelectedTopTabAnimated, autoFeedbackResponse, entryText }) => {
  const [autoFeedbackTerm, setAutoFeedbackTerm] = useState(false);
  const [entryHTML, setEntyHTML] = useState('');


  // console.log(autoFeedbackResponse)


  useEffect(() => {
    if (autoFeedbackResponse && autoFeedbackResponse.report && autoFeedbackResponse.report.length) { setEntyHTML(setGrammarFeedback(autoFeedbackResponse.fullEntry, autoFeedbackResponse.report, autoFeedbackTerm)) }
  }, [autoFeedbackTerm])


  return (
    <div className={styles.autoFeedbackContainer}>
      <div className={styles.infoBox}>
        <div className={styles.infoBoxHeader}>
          <img src={grammarbot} alt="GrammarBot" />
          <div className={styles.infoBoxText}>
            <h6>GrammarBot</h6>
            <p>Click the report that you want to see</p>
          </div>
        </div>

        <div className={styles.reportsContainer}>

          <div className={autoFeedbackTerm === 'passive' ? `${styles.report} ${styles.active} ${styles.passive}` : `${styles.report} ${styles.passive}`} onClick={() => setAutoFeedbackTerm('passive')}>
            <span>{autoFeedbackResponse && autoFeedbackResponse.report && autoFeedbackResponse.report.filter(elm => elm.type === 'passive').length}</span>
            <div className={styles.reportText}>
              Passive Voice
            </div>
          </div>

          <div className={autoFeedbackTerm === 'initial' ? `${styles.report} ${styles.active} ${styles.initial}` : `${styles.report} ${styles.initial}`} onClick={() => setAutoFeedbackTerm('initial')}>
            <span>{autoFeedbackResponse && autoFeedbackResponse.report && autoFeedbackResponse.report.filter(elm => elm.type === 'initial').length}</span>
            <div className={styles.reportText}>
              Initial Pronouns
            </div>
          </div>

          <div className={autoFeedbackTerm === 'overused' ? `${styles.report} ${styles.active} ${styles.overused}` : `${styles.report} ${styles.overused}`} onClick={() => setAutoFeedbackTerm('overused')}>
            <span>{autoFeedbackResponse && autoFeedbackResponse.report && autoFeedbackResponse.report.filter(elm => elm.type === 'overused').length}</span>
            <div className={styles.reportText}>
              Overuse
            </div>
          </div>

          <div className={autoFeedbackTerm === 'consistency' ? `${styles.report} ${styles.active} ${styles.consistency}` : `${styles.report} ${styles.consistency}`} onClick={() => setAutoFeedbackTerm('consistency')}>
            <span>{autoFeedbackResponse && autoFeedbackResponse.report && autoFeedbackResponse.report.filter(elm => elm.type === 'consistency').length}</span>
            <div className={styles.reportText}>
              Consistency
            </div>
          </div>

          <div className={autoFeedbackTerm === 'grammar' ? `${styles.report} ${styles.active} ${styles.grammar}` : `${styles.report} ${styles.grammar}`} onClick={() => setAutoFeedbackTerm('grammar')}>
            <span> {autoFeedbackResponse && autoFeedbackResponse.report && autoFeedbackResponse.report.filter(elm => elm.type === 'grammar').length}</span>
            <div className={styles.reportText}>
              Grammar
            </div>
          </div>
        </div>

        <div className={styles.closeBtn} onClick={() => setSelectedTopTabAnimated(false)}>
          X
      </div>

      </div>

      <div className={styles.textContainer} dangerouslySetInnerHTML={{ __html: entryHTML }}/>
      
    </div>
  );
}

export default AutomatickFeedback;