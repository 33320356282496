import React, { useEffect, useState } from 'react';
import styles from './TopTabs.module.scss'
import grammarbot from './../../../../../../images/newDashboard/icons/grammarBot.png'


const TopTabs = ({ 
  setSelectedTopTabAnimated, 
  selectedTopTab, 
  roomPartnerName, 
  authorAvatarPath, 
  automaticFeedback,
  shareGrammar }) => {

  const [ showReport, setShowReport ] = useState(false)

  useEffect(()=>{
    if(shareGrammar&&automaticFeedback&&automaticFeedback.report&&automaticFeedback.report.length){
      setShowReport(true)
    } else {
      setShowReport(false)
    }
  },[automaticFeedback])
  return (
    <div className={styles.topTabsContainer}>
      {roomPartnerName &&
        <button
          disable={selectedTopTab === 'partner1' ? "true" : undefined}
          className={selectedTopTab === 'partner1' ? `${styles.active} ${styles.tab}` : styles.tab}
          onClick={() => setSelectedTopTabAnimated('partner1')}>
          {authorAvatarPath &&
            <div className={styles.imageFrame}>
              <figure>
                <img src={authorAvatarPath} alt="Partner Boomer" />
              </figure>
            </div>}
          {roomPartnerName.split(" ")[0]}'s Entry
        </button>
      }
      { showReport && 
          <button
            disable={selectedTopTab === 'feedback' ? "true" : undefined}
            className={selectedTopTab === 'feedback' ? `${styles.active} ${styles.tab} ${styles.feedback}` : `${styles.tab} ${styles.feedback}`}
            onClick={() => setSelectedTopTabAnimated('feedback')}>
            <img src={grammarbot} alt='grammarbot' />
            Grammar Bot 
          </button>
      }

      {/* <div className={selectedTopTab==='partner2'?`${styles.active} ${styles.tab}`:styles.tab} onClick={()=>setSelectedTopTab('partner2')}>Partner 2 Entry</div> */}
    </div>
  );
}

export default TopTabs;