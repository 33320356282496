import React from 'react';

// import loader from '../../images/loader-animation.svg';
import loader from '../../images/LoaderAnimation.svg';

const MainLoader = ({ message, mutation }) => (
    <div className={`main-loader ${mutation ? 'main-loader--' + mutation : ''}`}>
        <object style={{position:"relative", bottom:"60px", height:"100px", objectFit:"contain"}}  className="main-loader__svg" data={loader} type="image/svg+xml">
            <img className="main-loader__img" src={loader} alt="loader"  />
        </object>
        <p className="main-loader__message">{message || "Loading..."}</p>
    </div>
);

export default MainLoader;
