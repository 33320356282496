import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import Name from './Name';
import Password from './Password';
import ProfileMenu from './ProfileMenu';
import School from './School';
import BoomerPanel from '../../Shared/BoomerPanel';
import BoomerHeading from '../../Shared/BoomerHeading';
import MainLoader from '../../Shared/MainLoader';
import Store from '../../Boomer/Store';

import { GET_PROFILE_DATA } from '../../../queries';

const ProfileRouter = ({ location }) => {
    const isBoomerStore = location.pathname === "/user/profile/boomer";

    const { loading, data, error } = useQuery(GET_PROFILE_DATA);

    const renderRouter = () => {
        if (loading) {
            return <MainLoader mutation="has-sub-menu" />
        }
        if (error) {
            return <p>{error.message}</p>
        }
        if (data && data.currentUser) {
            return (
                <Fragment>
                    {
                        isBoomerStore ? 
                        <Route path="/user/profile/boomer" component={Store} /> :
                        (
                            <div className="profile__split-content">
                                <div className="profile__left-content">
                                    <Switch>
                                        <Route path="/user/profile/name" render={() => <Name student={data.currentUser} />} />
                                        <Route path="/user/profile/password" render={() => <Password student={data.currentUser} />} />
                                        <Route path="/user/profile/school" render={() => <School student={data.currentUser} />} />
                                        <Route path="/" render={() => <Redirect to="/user/profile/name" />} />
                                    </Switch>
                                </div>
                                <div className="profile__right-content u-only-desktop">
                                    <BoomerHeading
                                        mainHeading="Boomer Update"
                                        subHeading="Customize Your Boomer"
                                    />
                                    <BoomerPanel />
                                </div>
                            </div>
                        )
                    }    
                </Fragment>
            )
        }
        return null;
    }

    return (
        <Fragment>
            {
                !isBoomerStore ? 
                (
                    <div className="u-hidden-desktop">
                        <BoomerPanel />
                        <h1 className="u-m-base-20 u-only-mobile">Update Your Details</h1>
                    </div>
                ) : null
            }       
            <ProfileMenu className={isBoomerStore && "u-hidden"}/>
            {renderRouter()}
        </Fragment>
    );
}

export default ProfileRouter;