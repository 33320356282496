import styles from './AutomaticFeedback.module.scss'
export const setGrammarFeedback = (inputString, infoArray, param) => {

  if (!infoArray.filter(i => i.type === param).length) {

    return `<p>${inputString}</p>`

  } else {
    let outputArr = [];
    const filteredArray = []

    infoArray.filter(i => i.type === param).forEach((item, index) => {
      if (index === 0 || item.startPos > infoArray.filter(i => i.type === param)[index - 1].endPos) {  //exclude overlapping items
        filteredArray.push(item)
      }
    });


    filteredArray.forEach((item, index) => {

      // if(index === 0 || item.startPos > filteredArray[index-1].endPos){ 

      let part1;
      let part2;

      const subStr = inputString.slice(item.startPos, item.endPos+1)
      const modArr = [];
      if (item.suggestions.length) {
        item.suggestions.forEach(i => {
          const modItem = `'${i}'`;
          modArr.push(modItem)
        })
      }
      const suggStr = modArr.length && modArr.length>5 ? modArr.slice(4).join(', ') : modArr.join(', ') ;

      const newSubStr =
        item.type === 'grammar' ?
          `<span class="${styles.feedback} ${styles.auto_grammar} ">${subStr} <span class=${styles.tooltipWrapper}><span class=${styles.tooltip}>${item.hint} ${item.suggestions.length ? `<span class="${styles.sugg_grammar}"><span class="${styles.suggHead}">suggestions:</span>${suggStr}</span>` : ''}</span> </span> </span>`

          : item.type === 'consistency' ?
            `<span class="${styles.feedback} ${styles.auto_consistency} ">${subStr} <span class=${styles.tooltipWrapper}><span class=${styles.tooltip}>${item.hint} ${item.suggestions.length ? `<span class="${styles.sugg_consistency}"><span class="${styles.suggHead}">suggestions:</span>${suggStr}</span>` : ''}</span> </span> </span>`

            : item.type === 'overused' ?
              `<span class="${styles.feedback} ${styles.auto_overused} ">${subStr} <span class=${styles.tooltipWrapper}><span class=${styles.tooltip}>${item.hint} ${item.suggestions.length ? `<span class="${styles.sugg_overused}"><span class="${styles.suggHead}">suggestions:</span>${suggStr}</span>` : ''}</span> </span> </span>`

              : item.type === 'passive' ?
                `<span class="${styles.feedback} ${styles.auto_passive} ">${subStr} <span class=${styles.tooltipWrapper}><span class=${styles.tooltip}>${item.hint} ${item.suggestions.length ? `<span class="${styles.sugg_passive}"><span class="${styles.suggHead}">suggestions:</span>${suggStr}</span>` : ''}</span> </span> </span>`

                : item.type === 'initial' ?
                  `<span class="${styles.feedback} ${styles.auto_initial}  ">${subStr} <span class=${styles.tooltipWrapper}><span class=${styles.tooltip}>${item.hint} ${item.suggestions.length ? `<span class="${styles.sugg_initial}"><span class="${styles.suggHead}">suggestions:</span>${suggStr}</span>` : ''}</span> </span> </span>`
                  :
                  null
        ;

      if (index === 0) {
        part1 = inputString.slice(0, item.startPos);

      } else {
        if (!item.startPos > filteredArray[index - 1].endPos) {
          //adjust starting index to account for ignored overlapping items
          part1 = inputString.slice(filteredArray[index - 2].endPos + 1, item.startPos);

        } else {
          part1 = inputString.slice(filteredArray[index - 1].endPos + 1, item.startPos);

        }
      }

      if (index === filteredArray.length - 1) {
        part2 = inputString.slice(item.endPos + 1)
      }

      outputArr.push(part1)
      outputArr.push(newSubStr)
      if (part2) { outputArr.push(part2) }

      // } else { return null }

    }
    )

    return `<p style=position:relative;padding-bottom:30px >${outputArr.join('')}</p>`
  }


}