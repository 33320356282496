import React, { useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { PROJECT_ROUTER_QUERY } from '../../../queries';
import MainLoader from '../../Shared/MainLoader';
import Breadcrumbs from '../Structural/Breadcrumbs/Breadcrumbs';
import ClassColumn from './ClassColumn/ClassColumn';
import styles from './ProjectDashboard.module.scss'
import ProjectHeader from './ProjectHeader/ProjectHeader';
import UnitCard from './UnitCard/UnitCard';
import ScrollArrows from '../Structural/ScrollArrows/ScrollArrows';
const ProjectDashboard = () => {
  const { projectId } = useParams()
  const { data, loading } = useQuery(PROJECT_ROUTER_QUERY, { variables: { projectId } });
  const unitcardcontainer = useRef(null);

  // const scroll = pixels => {
  //   unitcardcontainer.current.scrollBy({ top: pixels, behavior: "smooth" });
  // }


  if (loading) return <MainLoader />
  return (
    <div className={`${styles.adaptToScreen} ${styles.center}`}>
      <div className={styles.dashboardContainer}>
        <div>
          <Breadcrumbs
            classData={{ classId: data.project.groups[0].id, name: data.project.groups[0].name }}
            projectData={{ projectId: data.project.id, name: data.project.title }}
          />
        </div>
        <div className={styles.projectDashboardContainer}>
          <div className={styles.projectMenu}>
            <div className={styles.unitCardContainer} ref={unitcardcontainer}>
              {data.project.units.map((elm, idx) =>
                <UnitCard
                  key={idx}
                  unitId={elm.id}
                  idx={idx}
                  title={elm.title}
                  deadline={elm.deadline}
                  startedAt={elm.startedAt}
                  numberOfUnits={data.project.units.length}
                  phase={elm.phase}
                  studentId={data.currentUser.id}
                  projectId={data.project.id}
                  currentUserEntry={elm.currentUserEntry}
                  currentUserVoted={elm.currentUserVoted}
                  storyStart={elm.storyStart}/>
              )}
              {data.project.units.length > 6 && <ScrollArrows theRef={unitcardcontainer} offSet={180}/>}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ProjectDashboard;