import React from 'react';

import CloseButton from '../../images/close-purple.png';

const BadgeInfo = ({ badge, close, mutation = null }) => (
    <div className={`badge-info badge-info--${mutation}`}>
        <div className="badge-info__badge" style={{backgroundImage: 'url(' + badge.url + ')'}}></div>
        <div className="badge-info__inner">
            <h1 className="badge-info__title">{badge.name}</h1>
            <p className="badge-info__desc">{badge.message}</p>
        </div>
        <div className="badge-info__close" onClick={close}>
        	<img src={CloseButton} alt="Close" className="badge-info__close-img" />
        </div>
    </div>
);

export default BadgeInfo;
