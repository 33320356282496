import React, {useState, useEffect, useRef} from 'react';
import _ from 'lodash';
import moment from 'moment';


const Messages = ({ messages, newMessage, teacherAvatar }) => {

	const [ogMessages] = useState(messages);
	const [messageList, setMessages] = useState(messages);
	const [message] = useState(newMessage);

	useEffect(() => {
		if (newMessage !== message) {
			setMessages([
				...messageList,
				{
					id: newMessage.id,
					body: newMessage.body,
					createdAt: newMessage.timestamp,
					sentby: newMessage.sentby,
					teacher: {
						profilePicturePath: null,
					},
					student: {
						avatarPath: newMessage.student && newMessage.student.avatarPath,
					}
				}
			]);
		}
	}, [newMessage]);

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "auto" })
    }

	useEffect(() => {
		if (messages !== ogMessages) {
			setMessages(messages);
		}
	}, [messages])

	useEffect(() => {
		scrollToBottom()
	}, [messageList])

    return (
    	<>
	    	<ul>
			   {_.map(_.orderBy(messageList, ['createdAt'], ['asc']), (message, i) => {
			        return (
			            <li key={`message${message.id}`} className={`chat-main__message ${message.sentby === 'student' && 'chat-main__message--self'}`}>
			                <img src={message.sentby === 'student' ? message.student.avatarPath : teacherAvatar} className={`chat-main__ava ${message.sentby === 'student' && 'chat-main__ava--self'}`} alt="Avatar" />
			                <p className={`chat-main__message__text ${message.sentby === 'student' && 'chat-main__message__text--self'}`}>{message.body}</p>
			                <p className={`chat-main__message__date ${message.sentby === 'student' && 'chat-main__message__date--self'}`}>{moment(message.createdAt).format("Do MMMM, YYYY, h:mm a")}</p>
			            </li>
			        )
			    })}
	    	</ul>
		   <div ref={messagesEndRef} className="scroll-bottom" />
	   </>
	)
}

export default Messages;             




















