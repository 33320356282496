import React, { useState } from 'react';

import _ from 'lodash';

import { entryMarkup } from '../../../utils/globals';

const UnitWriting = ({ rubric, showFullRubric, showScore = false }) => {

    const[selectedRubric, setSelectedRubric] = useState(rubric.skills[0].id);

    const changeRubric = event => {
        setSelectedRubric(event.target.value);
    }

	return (
		<div className="full-rubric">
            <h2 className="u-m-base-20">{rubric.name}</h2>
            <div className="large-rubric">
                <div className="fr-skill">
                    <div className="fr-cell fr-heading">&nbsp;</div>
                    {_.map(_.sortBy(rubric.skills[0].criterias, 'score').reverse(), criteria => {
                        return <div className={`fr-cell fr-heading fr-heading--${criteria.score}`} key={`criteria-label-${criteria.id}`}>Score {criteria.score}</div>
                    })}
                </div>
                {_.map(rubric.skills, skill => {
                    return (
                        <div key={`fr-skill-${skill.id}`} className="fr-skill">
                            <div className="fr-cell">
                                <p className="u-bold" dangerouslySetInnerHTML={entryMarkup(skill.description)} />
                            </div>  
                            {_.map(_.sortBy(skill.criterias, 'score').reverse(), criteria => {
                                return (
                                    <div key={`criterias-${criteria.id}`} className={`fr-cell ${showScore && criteria.score === skill.studentScore && 'fr-cell--scored'}`}>
                                        <p dangerouslySetInnerHTML={entryMarkup(criteria.description)} />
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            <div className="mobile-rubric">
                <select className="dropdown" onChange={changeRubric}>
                    {_.map(rubric.skills, skill => {
                        return (
                            <option value={skill.id} key={`option-skill-${skill.id}`}>{skill.description}</option>
                        )
                    })}
                </select>
                {_.map(rubric.skills, skill => {
                    if (skill.id === selectedRubric) {    
                    return (
                        <div key={`mobile-skill-${skill.id}`} className="u-m-top-40">
                            <h2 className="u-bold u-centered-text">{skill.description}</h2>
                            <ul className="mob-skill u-m-top-20">
                                {_.map(_.sortBy(skill.criterias, 'score').reverse(), criteria => {
                                    return (
                                        <div key={`mob-criterias-${criteria.id}`} className={`mob-criteria ${showScore && criteria.score === skill.studentScore && 'mob-criteria--scored'}`}>
                                            <div className={`mob-criteria__score ${criteria.score === skill.studentScore && `mob-criteria__score--${criteria.score}`}`}>{criteria.score}</div>
                                            <p dangerouslySetInnerHTML={entryMarkup(criteria.description)} />
                                        </div>
                                    )
                                })}    
                            </ul>
                        </div>
                    ) } else { return null}
                })}
            </div>
            <span className="fr-close" onClick={() => showFullRubric(false)}>X</span>
        </div>
    )

}


export default UnitWriting;