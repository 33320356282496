import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { PROJECT_UNITS_QUERY } from '../../../../queries';
import UnitWriting from './UnitWriting';
import MainLoader from '../../../Shared/MainLoader';

const UnitWritingQuery = ({ scrollUnits, projectId, edit = false, unitIdx = null, showIdleModal, isReview, isStoryStart}) => {
    const { data, error, loading } = useQuery(PROJECT_UNITS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: projectId,
        }
    });
    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <MainLoader mutation="project" />
    }
    if (data && data.project) {
        return <UnitWriting 
                    project={data.project} 
                    edit={edit} 
                    unitIdx={unitIdx} 
                    showIdleModal={showIdleModal} 
                    scrollUnits={scrollUnits}
                    isStoryStart={isStoryStart}
                    isReview={isReview}/>
    }
    return null;
}

export default UnitWritingQuery;