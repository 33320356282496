import React from 'react';
import { Field, Form } from 'formik';

const PasswordForm = ({ errors, isSubmitting, isValid, touched }) => {
    const validateexistingPassword = existingPassword => {
        let error;
        if (existingPassword && (existingPassword.length < 2 || existingPassword.length > 25)) {
            error = "Your old password will be between 2 & 25 characters in length"
        }
        return error
    }
    
    const validateNewPassword = newPassword => {
        let error;
        if (newPassword && (newPassword.length < 2 || newPassword.length > 25)) {
            error = "Passwords must be between 2 & 25 characters in length"
        }
        return error
    }
    
    const validateconfirmNewPassword = confirmNewPassword => {
        let error;
        if (confirmNewPassword !== document.getElementById("newPassword").value) {
            error = "Passwords do not match"
        }
        return error
    }
    
    return (
        <Form className="form-horizontal">
            <div className="basic-form__group">
                <label className="basic-form__label u-m-base-10">Current Password:</label>
                <Field
                    className={`basic-form__text-box ${touched.existingPassword && errors.existingPassword ? 'textError' : ''}`}
                    name="existingPassword"
                    placeholder="Current Password" 
                    type="password"
                    validate={validateexistingPassword}
                />
                { touched.existingPassword && errors.existingPassword && <p className="basic-form__hint">{errors.existingPassword}</p> }
            </div>
            <div className="basic-form__group">
                <label className="basic-form__label u-m-base-10">New Password:</label>
                <Field
                    className={`basic-form__text-box ${touched.newPassword && errors.newPassword ? 'textError' : ''}`}
                    id="newPassword"
                    name="newPassword"
                    placeholder="New Password" 
                    type="password"
                    validate={validateNewPassword}
                />
                { touched.newPassword && errors.newPassword && <p className="basic-form__hint">{errors.newPassword}</p> }
            </div>
            <div className="basic-form__group">
                <Field
                    className={`basic-form__text-box ${touched.confirmNewPassword && errors.confirmNewPassword ? 'textError' : ''}`}
                    name="confirmNewPassword"
                    placeholder="Confirm New Password" 
                    type="password"
                    validate={validateconfirmNewPassword}
                />
                { touched.confirmNewPassword && errors.confirmNewPassword && <p className="basic-form__hint">{errors.confirmNewPassword}</p> }
            </div>
            <div>
                <button type="submit" className="button button--bordered button--full-width" disabled={!isValid || isSubmitting}>{ isSubmitting ? 'Saving...' : 'Change Password' }</button>
            </div>
        </Form>
    );
}

export default PasswordForm;