import React, {Fragment} from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ls from 'local-storage';

import emoji from '../../images/emoji.jpg';
import SimpleBoomer from '../Boomer/SimpleBoomer';

import { Client } from '../../Client';

const Confirm = ({ 
    cancelMessage, 
    children, 
    className,
    confirmMessage, 
    emojiWarning,
    message, 
    onClose, 
    onConfirm, 
    title, 
    variables 
}) => (
    <ApolloProvider client={Client}>
        <div 
            onClick={(event) => {
                if (event.target.classList.contains('confirm-overlay')) {
                    onClose();
                }
            }} 
            className={`confirm-overlay ${className}`}
        >
            <div className="confirm-overlay__body">
                {
                    ls('token') ? (
                        <div className="confirm-overlay__boomer">
                            <SimpleBoomer mutation="in-confirm" />
                        </div>
                    ) : null
                }
                
                <button onClick={onClose} className="confirm-overlay__close-button">
                    <span className="confirm-overlay__close-icon">x</span>
                </button>
                {title && <p className="confirm-overlay__title">{title}</p>}
                {emojiWarning  ? (
                    <Fragment>
                        <img src={emoji} className="emoji-warning-img" alt="No Emojis!" />
                        <p className="emoji-warning u-m-top-20">We do not allow emojis in entries.</p>
                        <p className="emoji-warning u-m-top-10">We will remove them for you if you submit your entry or you can edit your entry yourself. Are you sure you want to submit your entry?</p>
                    </Fragment>
                    ) :
                    <p className="confirm-overlay__message">{message || 'Are you sure?'}</p>
                }
                {children}
                <div className="confirm-overlay__buttons">
                    <button 
                        className="button button--bordered confirm-overlay__button" 
                        onClick={() => {
                            onConfirm({ variables }); 
                            onClose();
                        }}
                    >
                        {confirmMessage || 'Yes'}
                    </button>
                    <button className="button button--bordered button--red confirm-overlay__button" onClick={onClose}>{cancelMessage || 'No'}</button>
                </div>
            </div>
        </div>
    </ApolloProvider>
);

export default Confirm;
