import React, { Fragment } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';

import PasswordForm from './PasswordForm';
import MainLoader from '../../Shared/MainLoader';
import FormResponse from '../../Shared/FormResponse';
import { 
    EDIT_PASSWORD,
} from '../../../queries';

const PasswordFormHolder = (props) => {
    const [resetPassword, { data, error, loading }] = useMutation(EDIT_PASSWORD);
    return (
        <Fragment>
            <Formik
                component={PasswordForm}
                initialValues={{ 
                    existingPassword: '',
                    newPassword: '',
                    confirmNewPassword: ''
                }}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    resetPassword({ 
                        variables: { 
                            id: props.student.id, 
                            ...values
                        } 
                    }).then(() => {
                        setSubmitting(false);
                        resetForm();
                    });
                }}
                validationSchema={
                    Yup.object().shape({
                        existingPassword: Yup.string()
                            .min(2, 'Must be longer than 2 characters')
                            .max(25, 'Must be shorter than 25 characters')
                            .required('Required'),
                        newPassword: Yup.string()
                            .min(2, 'Must be longer than 2 characters')
                            .max(25, 'Must be shorter than 25 characters')
                            .required('Required')
                    })
                }
            />
            {loading && <MainLoader mutation="has-sub-menu" />}
            {error && <p>{error.message}</p>}
            {data && <FormResponse errors={data.resetPassword.errors} />}
        </Fragment> 
    ); 
}

export default PasswordFormHolder;
