import React from 'react';

import styles from './Tabs.module.scss'

const Tabs = ({tabTerm,setTabTerm,activeUnits,currentUnitIndex}) => {
  return (
    <div className={styles.tabsContainer}>
      <div className={tabTerm==='instructions'?`${styles.active} ${styles.tab}`:styles.tab} onClick={()=>setTabTerm('instructions')}>Instructions</div>
      {/* {activeUnits > 1 && currentUnitIndex!==1 && 
        <div className={tabTerm==='previous'?`${styles.active} ${styles.tab}`:styles.tab} onClick={()=>setTabTerm('previous')}>Previous</div>
      } */}
    </div>
  );
}

export default Tabs;