import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import cx from 'classnames';

import StarsHolder from './StarsHolder';
import { STARCAST_ENTRY_MUTATION } from '../../services/graphql';

import styles from './RatingPanel.module.scss';
import animatedClock from '../../../../../../images/voting/animated-clock.svg';
import { RATING_LABELS } from '../../constants';


const RatingPanel = ({ entryId, onRatingCompleted, timerLength, setVoteEnded }) => {

    const [rating, setRating] = useState(0);
    const [tempRating, setTempRating] = useState(null);
    const [enabled, setEnabled] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    setTimeout(() => {
        setEnabled(true)
    }, timerLength);

    const [starCastEntry, { loading }] = useMutation(STARCAST_ENTRY_MUTATION, {
        variables: {
            projectUnitEntryId: entryId,
            stars: rating
        },
        onCompleted: ({ starCastProjectUnitEntry }) => {
            if (starCastProjectUnitEntry.errors && starCastProjectUnitEntry.errors.length > 0) {
                if (starCastProjectUnitEntry.errors[0] === 'Voting is not open') {
                    setVoteEnded(true);
                } else {
                    setFormErrors(starCastProjectUnitEntry.errors[0]);
                }
            } else {
                onRatingCompleted(starCastProjectUnitEntry.projectUnit.currentStudentNextEntryToStarCast, rating);
            }
        }
    });


    return (
        <Fragment>
            <p className={styles.ratingTitle}>How much did you enjoy reading this entry?</p>
            <div className={styles.starsWrapper}>
                <StarsHolder
                    enabled={enabled}
                    rating={tempRating || rating}
                    setRating={setRating}
                    setTempRating={setTempRating}
                />
            </div>


            {
                enabled ? (
                    <p className={cx(styles.ratingText, { [styles.ratingTextDisabled]: !enabled })}>{rating === 0 && !tempRating ? 'select your rating' : tempRating ? RATING_LABELS[tempRating] : RATING_LABELS[rating]}</p>
                ) : (
                    <img src={animatedClock} alt="Reading timer animation" className={styles.clockAnimation} />
                )
            }
            {formErrors && <p className='u-m-base-1'>{formErrors}</p>}
            <button className='button button--bordered button--full-width' onClick={() => starCastEntry()} disabled={!rating || loading}>Rate it!</button>
        </Fragment>
    );
}

export default RatingPanel;
