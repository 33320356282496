import React from 'react';
import styles from './Submit.module.scss'
import coins from '../../../../../images/coins.png';
import emojiStrip from 'emoji-strip';
import striptags from 'striptags';
import { confirmAlert } from 'react-confirm-alert';
import Confirm from '../../../../Shared/Confirm';
import { useMutation } from '@apollo/react-hooks';
import { PROJECT_SUBMIT_MUTATION, PROJECT_TICKLES_QUERY, PROJECT_UNITS_QUERY } from '../../../../../queries';
import { useParams } from 'react-router-dom';
import { getTickleType } from '../../../../../utils/globals';




const Submit = ({ submitted, isRejected, entry, isOverMax, phase, approved, submitSuccess }) => {

  const { projectId, unitIdx } = useParams()


  const countWordsLocal = (entryText) => {
    entryText = entryText.replace(/<[^>]*>/g, '');
    var matches = entryText.match(/[\w\d\’\'-]+/gi);
    return matches ? matches.length : 0;
  }

  const [submitMutation] = useMutation(PROJECT_SUBMIT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: () => [
      { query: PROJECT_UNITS_QUERY, variables: { id: projectId } },
      { query: PROJECT_TICKLES_QUERY, variables: { projectId: projectId, index: unitIdx, types: getTickleType(phase), limit: 50 } }
    ],
    onCompleted: () => {
      submitSuccess(true)
    }
  });

  const clickSubmit = variables => submitMutation(variables);
  

  const warningMessage = emoji => {
    if (emoji) {
      return "We do not allow emojis in entries, we will remove them for you if you submit your entry or you can edit your entry yourself. Are you sure you want to submit your entry?"
    } else if (isRejected) {
      return "Are you sure you want to submit your revisions?"
    }
    return "Are you sure you want to submit your entry?"
  }

  const showConfirm = (submitEntry) => {
    const strippedText = emojiStrip(entry.text);
    const moreStrippedText = striptags(strippedText, [], '\n');
    const evenMoreStrippedText = moreStrippedText.replace(/&nbsp;/g, " ");

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirm
            cancelMessage="Not Yet"
            emojiWarning={(strippedText !== entry.text)}
            message={warningMessage((strippedText !== entry.text))}
            onClose={onClose}
            onConfirm={submitEntry}
            title={isRejected ? "Submit revisions?" : "Submit entry?"}
            variables={{
              id: entry.id,
              entryText: evenMoreStrippedText,
              bucks: 10
            }}
          />
        )
      }
    });
  }

  const renderBtnText = () => {
    if(!submitted&&entry.text.length&&!isOverMax) 
      return <span>Submit your entry and earn <img className="button__image" src={coins} alt="coins" /> 10!</span>
    if(isOverMax) return <span>Your entry is over the word limit</span>
    if (approved) return <span>Entry approved!</span>
    if (isRejected) return <span>Submit</span>
    if (submitted) return <span>Entry Submitted</span>
    return <span>Write your entry and earn <img className="button__image" src={coins} alt="coins" /> 10!</span>
  }

  return (
    <>
      <button className={styles.submitBtn} disabled={countWordsLocal(entry.text) === 0 || approved || isOverMax} onClick={() => showConfirm(clickSubmit)} >
        {renderBtnText()}
      </button>
    </>
  );
}

export default Submit;