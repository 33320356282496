import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { TIE_BREAK } from '../../../../utils/globals';

import StarcastingInstructions from './components/StarcastingInstructions';
import StarsHolder from './components/RatingPanel/StarsHolder';
import RatingPanel from './components/RatingPanel';
import Entry from './components/Entry';
import BoomerHeading from '../../../Shared/BoomerHeading';
import PublishScroller from '../../SlideScroller/PublishScroller'

import alertBoomer from '../../../../images/alert-boomer.png';
import kidsImg from '../../../../images/voting/kids-star.png';
import voteDoneImg from '../../../../images/voting/vote-done.png';

import cx from 'classnames';
import styles from './UnitStarcasting.module.scss';


const UnitStarcasting = ({ history, project, isSW }) => {

    const {
        currentUnitData: {
            currentStudentNextEntryToStarCast,
            vocab
        },
        currentUnitData
    } = project;

    const [showScroller, setShowScroller] = useState(false);
    const [showVoted, setShowVoted] = useState(false);
    const [voteEnded, setVoteEnded] = useState(false);

    const [hasVoted, setHasVoted] = useState(currentUnitData.currentUserVoted);
    const [nextEntryToVote, setNextEntryToVote] = useState(currentStudentNextEntryToStarCast);

    const onRatingCompleted = (newEntry, rating) => {
        setNextEntryToVote(newEntry);
        newEntry && setShowVoted(rating);
    }

    const getTimerLength = wordsCount => {
        if (wordsCount * 125 > 120000) {
            return 120000
        }
        return wordsCount * 125;
    }

    if (showScroller) {
        return <PublishScroller history={history} oppo={true} alternate={project.currentUnitData.currentUserEntry.approved} />
    }

    if (currentUnitData.phase === TIE_BREAK) {
        return (
            <>
                <BoomerHeading
                    mainHeading="Wow, voting ended in a tie!"
                    showBoomer={true}
                    subHeading="The winner will be decided soon."
                />
                <div className="light-panel voting-success">
                    <img src={alertBoomer} alt="Congratulations" className="voting-success__boomers" />
                    <p className="u-m-top-20">Amazingly voting ended in a tie, the winner will be decided soon please check back shortly.</p>
                </div>
            </>
        )
    }

    if (voteEnded) {
        return (
            <div className={cx(styles.panel, styles.votedPanel)}>
                <div className={styles.votedPanelMain}>
                    <h3 className={styles.votedPanelTitle}>Sorry, voting has ended. Your vote has not been counted</h3>
                    <Link to='/dashboard' className='button button--inline-block u-m-top-20'>Back to dashboard</Link>
                </div>
            </div>
        )
    }

    if (!hasVoted) {
        return <StarcastingInstructions closeInstructions={() => setHasVoted(true)} />
    }

    return (
        <>
            {showVoted && nextEntryToVote &&
                <div className={cx(styles.panel, styles.votedPanel)}>
                    <div className={styles.votedPanelMain}>
                        <h1 className={styles.votedPanelTitle}>Great Job!</h1>
                        <h2 className={styles.votedPanelSubtitle}>You awarded {showVoted} stars to the entry</h2>
                        <StarsHolder displayOnly={true} enabled={true} rating={showVoted} />

                        <h2 className={styles.votedPanelSubtitle}>Boom! Superb effort... here are 2 Boomer Bucks</h2>
                        <p>You and your class are in control of finding the winner. So don't stop here, Vote again on a different entry and you could find the winner…</p>
                        <div className='u-m-base-10'>
                            <button
                                className='button button--bordered u-m-top-20 u-m-base-20'
                                onClick={() => setShowVoted(false)}
                            >
                                Show me another
                            </button>
                        </div>
                        {project.currentUnitData.index === project.units.length && isSW ?
                            <button onClick={() => setShowScroller(true)} className={styles.votedPanelDone}>No thanks, I'm done</button> :
                            <Link to='/dashboard' className={styles.votedPanelDone}>No thanks, I'm done.</Link>
                        }

                    </div>
                    <div className={styles.votedPanelSide}>
                        <img src={kidsImg} alt='' />
                    </div>
                </div>
            }

            {
                hasVoted && !showVoted && nextEntryToVote && (
                    <div className={styles.entryWrapper}>
                        <div className={styles.entry}>
                            <div className={styles.entryMain}>
                                <div className={styles.entryHeader}>
                                    <h2 className={styles.entryHeaderTitle}>{project.title}</h2>
                                    <p className={styles.entryHeaderSubtitle}>{currentUnitData.title} - written by?</p>
                                </div>
                                <Entry vocab={vocab} entry={nextEntryToVote} />
                            </div>
                            <div className={styles.entrySide}>
                                <RatingPanel
                                    entryId={nextEntryToVote.id}
                                    onRatingCompleted={onRatingCompleted}
                                    timerLength={getTimerLength(nextEntryToVote.wordsCount)}
                                    setVoteEnded={setVoteEnded}
                                />
                            </div>
                        </div>
                    </div>
                )
            }

            {
                hasVoted && !nextEntryToVote && (
                    <>
                        {
                            project.currentUnitData.index === project.units.length ?
                                <PublishScroller history={history} oppo={true} alternate={project.currentUnitData.currentUserEntry.approved} /> :
                                <div className={cx(styles.panel, styles.votedPanel)}>
                                    <div className={styles.votedPanelMain}>
                                        <h1 className={styles.votedPanelTitle}>Great Job!</h1>
                                        <h2 className={styles.votedPanelSubtitle}>Amazing, you've read them all!</h2>
                                        <p className={styles.votedPanelSubtitle}>You've rated all the entries, stay tuned to find out who won....</p>
                                    </div>
                                    <div className={styles.votedPanelSide}>
                                        <img src={voteDoneImg} alt='' />
                                    </div>
                                </div>
                        }
                    </>
                )
            }
        </>
    )
}

export default withRouter(UnitStarcasting);
