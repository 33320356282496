import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

import ActivityFeed from './ActivityFeed';
import SimpleBoomer from '../Boomer/SimpleBoomer'
import BoomerBucks from './BoomerBucks';
import coins from '../../images/coins.png';
import { FIRST_NAME_QUERY } from '../../queries/shared';
import BadgeInfo from './BadgeInfo';
import { RANK1 } from '../../utils/globals.js';

const BoomerPanel = ({ showFeed }) => {
	const [showBadgeInfo, setShowBadgeInfo] = useState(false);

	const { data } = useQuery(FIRST_NAME_QUERY);

	const toggleBadge = (currentBadge) => {
    	currentBadge && setShowBadgeInfo(!showBadgeInfo);
    }

	const renderContent = () => {
		if (data && data.currentUser) {
			const { currentBadge, rank, firstName } = data.currentUser;
			return (
				<>
					{rank.points > RANK1 && <img src={rank.url2} alt="Rank 1" className="boomer-panel__rank" onClick={() => toggleBadge(currentBadge)}/>}
					<div className="boomer-panel__boomer" onClick={() => toggleBadge(currentBadge)}>
						<SimpleBoomer mutation="in-panel" badge={currentBadge && currentBadge.url} />
					</div>
					{showBadgeInfo && <BadgeInfo badge={currentBadge} close={() => toggleBadge(currentBadge)} mutation="bp" />}
					<div className="boomer-panel__content">
						<div className="boomer-panel__content-top">
							<p className="boomer-panel__mobile">Welcome, <strong>{firstName}!</strong></p>
							<p className="boomer-panel__tablet">Need a new look?</p>
							<p className="boomer-panel__coins"><img src={coins} className="boomer-panel__coins-img" alt="coins" />
								<strong>
									<BoomerBucks />
								</strong>
							</p>
							<p className="boomer-panel__desktop">Boomer Bucks</p>
						</div>
						<Link to="/user/profile/boomer" className="button button--yellow">Hit The Store!</Link>
					</div>
					{showFeed && <ActivityFeed />}	
				</>
			)
		}
		return null;
	}

	return (
		<div className="boomer-panel">
			{renderContent()}
		</div>
	)
}

export default BoomerPanel;