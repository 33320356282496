import { gql } from 'apollo-boost';

// Queries
export const BOOMER_STORE_QUERY = gql`
query boomerStoreQuery {
    currentUser {
        id
        avatar
        boomerBucks
        badgesList {
            id
            earnedOn
            equipped
            levelName
            message
            name
            url
            status
        }
        rank {
            id
            points
            url1
            url2
        }
        currentBadge {
            id
            name
            levelName
            url
            message
        }
        ownedAvatarItems {
            id
            gender
            tileHeight
            type
            url
            xmlId
            xOffset
            yPos
        }
    }
}
`;

export const ALL_ITEMS_QUERY = gql`
query allItemsQuery {
    avatarItems {
        id
        gender
        price
        type
        url
        xmlId
        xOffset
        yPos
    }
}
`;


// Mutations
export const BUY_BOOMER_ITEM = gql`
mutation buyBoomerItem($itemXmlId: ID!, $gender: String, $itemType: String) {
    buyAvatarItem(itemXmlId: $itemXmlId, gender: $gender, itemType: $itemType) {
        errors
        avatarItems {
            id
            gender
            tileHeight
            type
            url
            xmlId
            xOffset
            yPos
        }
    }
}
`;

export const SAVE_BOOMER_ITEMS = gql`
mutation saveBoomerItems($items: [AvatarItemAttributes!]!, $data: String!) {
    saveCurrentAvatarItems(items: $items, data: $data) {
        errors
        student {
            id
        }
    }
}
`;

export const SELECT_BADGE = gql`
mutation selectBadge($badgeId: String!) {
    equipBadge(badgeId: $badgeId) {
        errors
    }
}
`;
