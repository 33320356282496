// Use this to just draw an uneditable boomer
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import DrawBoomer from './DrawBoomer';
import loader from '../../images/loader-boomer.png';
import { SIMPLE_BOOMER_QUERY } from '../../queries';

const SimpleBoomer = ({ forceSave, mutation, badge = false, onBoomerLoaded }) => {
    const { data, loading, error } = useQuery(SIMPLE_BOOMER_QUERY);
    
    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return (
            <div className={`boomer-holder ${mutation ? 'boomer-holder--' + mutation : ''}`}>
                <img alt="Your Boomer" className="boomer-holder__loader" src={loader} />
            </div>
        )
    }
    if (data && data.currentUser) {
        return <DrawBoomer boomerData={JSON.parse(data.currentUser.avatar)} forceSave={forceSave} mutation={mutation} badge={badge} onBoomerLoaded={onBoomerLoaded} />
    }
    return null;
}

export default SimpleBoomer;
