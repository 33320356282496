import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';

import JoinSchoolForm from './JoinSchoolForm';
import FormResponse from '../FormResponse';
import MainLoader from '../MainLoader';

import { 
    GET_PROFILE_DATA,
    JOIN_CLASS 
} from '../../../queries';

const JoinSchoolFormHolder = () => {
    const [classCode] = useState('');

    const [joinClass, { data, loading, error }] = useMutation(JOIN_CLASS, {
        refetchQueries: () => [{ query: GET_PROFILE_DATA }]
    });

    return (
        <Fragment>
            <p className="u-m-base-20" style={{color:'white'}}>Enter your Class ID below&hellip;</p>            
            <Formik
                component={JoinSchoolForm}
                initialValues={{ 
                    classCode,
                }}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    const { 
                        classCode
                    } = values;
                    joinClass({ 
                        variables: { 
                            classCode
                        }, 
                        update: () => {
                            resetForm();
                            setSubmitting(false);
                        }
                    });
                }}
                validationSchema={
                    Yup.object().shape({
                        classCode: Yup.string()
                            .min(3, 'Must be at least 3 characters long')
                            .required('Required'),
                    })
                }
            />
            {loading && <MainLoader />}
            {error && <p>{error.message}</p>}
            {data && data.joinClass && data.joinClass.errors.length > 0 && <FormResponse errors={data.joinClass.errors} />}
        </Fragment>
    )
}

export default JoinSchoolFormHolder;