import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';

import { PROJECT_SEE_ENTRY_MUTATION, PROJECT_VOTING_QUERY, PROJECT_BOOKMARK_ENTRY_MUTATION, PROJECT_UNBOOKMARK_ENTRY_MUTATION } from '../../../../queries';
import { entryMarkup } from '../../../../utils/globals';
import cross from '../../../../images/close-purple.png';
import coins from '../../../../images/coins.png';
import star from '../../../../images/star.png';
import loader from '../../../../images/small-loader.svg';

const Entry = ({ entry, entryIDs, number, projectId, unitId, reset, canVote, vocab, vote }) => {
    if (!entry) {
        return null;
    }

    const [loading, setLoading] = useState(false);

    const escapeVocab = (vocab) => {
        return vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); 
    }

    const highlightVocab = (text, vocab) => {
        let newText = text;
        _.map(vocab, (vocab) => {
            let exp = RegExp('\\b(' + escapeVocab(vocab.word) + ')\\b', 'gi');
            newText = newText.replace(exp, ` <span class='tagvocab'>${vocab.word}</span>`)
        });
        return newText;
    }

    const [seeEntry] = useMutation(PROJECT_SEE_ENTRY_MUTATION, {
        awaitRefetchQueries: true,
        onCompleted: () => reset(-1),
        refetchQueries: () => [
            { query: PROJECT_VOTING_QUERY, variables: { id: projectId, unitId: unitId } }
        ]
    });

    const [bookmarkEntry] = useMutation(PROJECT_BOOKMARK_ENTRY_MUTATION, {
        awaitRefetchQueries: true,
        onCompleted: () => setLoading(false),
        refetchQueries: () => [
            {query: PROJECT_VOTING_QUERY, variables: { id: projectId, unitId: unitId } }
        ]
    });

    const [unBookmarkEntry] = useMutation(PROJECT_UNBOOKMARK_ENTRY_MUTATION, {
        awaitRefetchQueries: true,
        onCompleted: () => setLoading(false),
        refetchQueries: () => [
            {query: PROJECT_VOTING_QUERY, variables: { id: projectId, unitId: unitId } }
        ]
    })

    return (
        <div className="voting__entry">
            <div className="voting__entry__content">
                <div className="voting__entry__buttons">
                    {
                        !entry.seenByCurrentUser ? 
                        (                         
                            <button 
                                className="voting__close"
                                onClick={() => {
                                    reset(number - 1, false);
                                    seeEntry({ variables: { entryId: entry.id, unitId } });
                                }}
                            >
                                <img src={cross} alt="Close" className="voting__close-img" />
                            </button>
                        ) : (
                            <button 
                                onClick={() => {
                                    reset(-1, false);
                                }} 
                                className="voting__close"
                            >
                                <img src={cross} alt="Close" className="voting__close-img" />
                            </button>
                        )
                    }
                </div>
                <div className="voting__entry-header">
                    {
                        !loading ? 
                        (
                            !entry.bookmarkedByCurrentUser ? (
                                <button
                                    className="button button--bordered button--bookmark"
                                    onClick={() => {
                                        bookmarkEntry({ variables: { entryId: entry.id, currentSetIds: entryIDs } });
                                        setLoading(true);
                                    }}
                                >
                                    <img src={star} className="button__image--bookmark" alt="Bookmark" /> Bookmark Entry
                                </button>
                            ) : (
                                <button
                                    className="button button--red button--bordered button--bookmark"
                                    onClick={() => {
                                        unBookmarkEntry({ variables: { entryId: entry.id } });
                                        setLoading(true);
                                    }}
                                >
                                    <img src={star} className="button__image--bookmark" alt="Bookmark"/> Remove Bookmark
                                </button>
                            )
                        ) : (
                            <img src={loader} alt="Loading" className="entry__bookmark-loader" />
                        )
                    } 
                    <p className="voting__entry__title">Entry {number} {entry.bookmarkedByCurrentUser && <img src={star} className="voting__entry__star" alt="Bookmarked" />}</p>
                </div>
                <div className="voting__entry__text" dangerouslySetInnerHTML={entryMarkup(highlightVocab(entry.entryText, vocab))} />
                {canVote && <button className="button button--green button--bordered u-full-width" onClick={() => vote(entry, number)}>Vote For This Entry, Earn <img className="button__image" src={coins} alt="coins" /> 2!</button>}
            </div>
        </div>
    )
}

export default Entry;
