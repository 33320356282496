import { gql } from 'apollo-boost';
// Queries and mutations for the user dashboard page

export const GET_GROUP_DATA = gql`

query GET_GROUP_DATA($classId: ID!){
    currentUser {
        id
      }
     group(id:$classId){
        id
        name
        membershipStatus
        students {
            id
            avatar
            firstName
            lastName
        }
        teachers {
            id
            displayName
            profilePicturePath
        }
        projects {
            id
            archived
            completed
            title
            type
            journal
            currentUnitData {
                id
                title
                currentUserVoted
                deadline
                index
                phase
                deadline
                createdAt
                startedAt
                approved
                winner {
                    id
                }
                currentUserEntry {
                    id
                    submitted
                    rejected
                    revisionStatus
                }
            }
            units{
                id
                    index
                    createdAt
                    startedAt
                    deadline
                    phase
                    title
            }
        }
    }
}`