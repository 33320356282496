import _ from 'lodash';

// Boomer stuff
// export const BOOMER_IMG_PATH = window.location.host.includes("localhost") ? '/img/boomer/avatar-items/' : 'https://s3.us-east-2.amazonaws.com/avatar-items/';
export const BOOMER_IMG_PATH = '/img/boomer/avatar-items/';
export const BOOMER_ITEM_ORDER = ['bottom', 'top', 'hair', 'accessory', 'pet'];
export const BOOMER_BODY_HEIGHT = 510;
export const BOOMER_ITEMS_BASE = 570;
export const BOOMER_ITEMS_TOP = -195;
export const BOOMER_MAX_HEIGHT = 765;
export const BOOMER_MAX_WIDTH = 753;
export const BOOMER_FALLBACK_DATA = {
    body: {
        body_id: 2
    },
    items: []
}

// Boomer Item Types
export const ACCESSORY = "accessory";
export const BODY = "body";
export const BOTTOM = "bottom";
export const HAIR = "hair";
export const PET = "pet";
export const TOP = "top";

export const sortItems = (wearing, owned, all) => {
    return _.map(all, item => {
        const wearingItem = isWearing(wearing, item);
        return {
            ...item,
            owned: item.type === BODY ? false : _.find(owned, ['xmlId', item.xmlId]) ? true : false,
            tile: wearingItem ? wearingItem.tile : "1",
            wearing: wearingItem ? true : false,
            xOffset: item.gender === "female" ? item.xOffset - (item.type === "hair" ? 0 : 4) : item.xOffset
        }
    });
}

export const previewItem = (wearing, newItem) => {
    if (newItem.type === BODY) {
        return {
            ...wearing,
            body: {
                body_id: newItem.id
            }
        }
    }
    const wearingWithoutDuplicate = _.reject(wearing.items, ['type', newItem.type]);
    return {
        ...wearing,
        items: [
            ...wearingWithoutDuplicate,
            {
                ...newItem
            }
        ]
    };
}

export const previewTile = (wearing, newItem, tile) => {
    if (!_.find(wearing.items, ['xmlId', newItem.xmlId])) {
        return {
            ...wearing,
            items: [
                ...wearing.items,
                {
                    ...newItem,
                    tile
                }
            ]
        }
    }
    return {
        ...wearing,
        items: _.map(wearing.items, item => {
            if (item.xmlId === newItem.xmlId) {
                return {
                    ...item,
                    tile
                }
            }
            return item
        })
    }
}

export const removeItem = (wearing, removeItemXmlId) => {
    return {
        ...wearing,
        items: _.reject(wearing.items, ['xmlId', removeItemXmlId])
    }
}

export const isWearing = (wearing, item) => {
    if (item.type === BODY) {
        return item.id === wearing.body.body_id;
    }
    return _.find(wearing.items, ['xmlId', item.xmlId]);
}