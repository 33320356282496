

import React from 'react';

const ArchivedAssigIcon = ({fill}) => {
  return (
    <svg width="23" height="17" viewBox="0 0 23 17" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M10.031 0.626875C9.61303 0.223125 9.05203 0 8.46903 0H2.78203C1.57203 0 0.593031 0.95625 0.593031 2.125L0.582031 14.875C0.582031 16.0438 1.57203 17 2.78203 17H20.382C21.592 17 22.582 16.0438 22.582 14.875V4.25C22.582 3.08125 21.592 2.125 20.382 2.125H11.582L10.031 0.626875Z" fill={fill} />
    </svg>
  );
}

export default ArchivedAssigIcon;
