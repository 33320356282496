// This should export the Apollo Client that communicates with graphql

import { ApolloClient } from 'apollo-boost';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from "apollo-link-error";
import ActionCable from 'actioncable';
import ActionCableLink from 'graphql-ruby-client/dist/subscriptions/ActionCableLink';
import ls from 'local-storage';

import { API_ENDPOINT, SUBSCRIPTION_ENDPOINT } from './utils/endPoints';
import { useEffect } from 'react';

const cache = new InMemoryCache({
    dataIdFromObject: object => `${object.__typename}-${object.id}` || null
});


// General link for backend requests
const httpLink = createHttpLink({
    uri: `${ API_ENDPOINT }/graphql`,
});


// Chucks in the auth stuff
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = ls('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token || ""
        }
    }
});

// Error handler if request is unauthorised
const errorLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
    // ls("errorData", JSON.stringify(response));
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) {
        console.log(`[Network error]: ${networkError.statusCode}`);
        if (networkError.statusCode === 401) {
            cache.writeData({
                data: {
                    forcedLogout: true, // I've changed this to avoid infinite reloading
                    error: true

                }
            });
            ls.remove('token'); // also this

            if (!ls('refreshTriggered')){
              ls('refreshTriggered', true)
              window.location.reload()
    
            }
            
        }  else {
            cache.writeData({
                data: {
                    error: true
                }
            });
        }
    }
    return forward(operation);
});

// Stuff for subscriptions
const cable = ActionCable.createConsumer(`${SUBSCRIPTION_ENDPOINT}:443/cable`);

const hasSubscriptionOperation = ({ query: { definitions } }) => {
    return definitions.some(
      ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
    )
  }

const subscriptionLink = ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({cable}),
    httpLink
);


// Set up the client
const Client = new ApolloClient({
    link: ApolloLink.from([
        authLink, // addded .concat(httpLink) following the apolloclient documentation
        errorLink,
        subscriptionLink
    ]),
    cache:cache,
    connectToDevTools: true,
    resolvers: {}
});

// Set up any default globals that are frontend only
cache.writeData({
    data: {
        boomerBucks: 0,
        // userData: null,
        error: false, // if true show error page
        loggedIn: ls('token'), // is the user logged in
        forcedLogout: false, // has the user just been kicked out for having a bad token
    }
});

export {
    Client
};
