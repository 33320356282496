
export const dateFormatYear = (input) => {

  const date = new Date(input);
  const day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();

  switch (month) {
    case 0:
      month = 'January'
      break;
    case 1:
      month = 'February'
      break;
    case 2:
      month = 'March'
      break;
    case 3:
      month = 'April'
      break;
    case 4:
      month = 'May'
      break;
    case 5:
      month = 'June'
      break;
    case 6:
      month = 'July'
      break;
    case 7:
      month = 'August'
      break;
    case 8:
      month = 'September'
      break;
    case 9:
      month = 'October'
      break;
    case 10:
      month = 'November'
      break;
    case 11:
      month = 'December'
      break;
    default:
      month = ''
      break;
  }

  return (`${day} ${month} ${year}`)
}

export const dateFormat = (input) => {

  const date = new Date(input);
  const day = date.getDate();
  let month = date.getMonth();

  switch (month) {
    case 0:
      month = 'Jan'
      break;
    case 1:
      month = 'Feb'
      break;
    case 2:
      month = 'Mar'
      break;
    case 3:
      month = 'Apr'
      break;
    case 4:
      month = 'May'
      break;
    case 5:
      month = 'Jun'
      break;
    case 6:
      month = 'Jul'
      break;
    case 7:
      month = 'Aug'
      break;
    case 8:
      month = 'Sept'
      break;
    case 9:
      month = 'Oct'
      break;
    case 10:
      month = 'Nov'
      break;
    case 11:
      month = 'Dec'
      break;
    default:
      month = ''
      break;
  }
  
  return (`${month} ${day}`)
}
export const dateFormatMonthDayYear = (input) => {

  const date = new Date(input);
  const day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  switch (month) {
    case 0:
      month = 'Jan'
      break;
    case 1:
      month = 'Feb'
      break;
    case 2:
      month = 'Mar'
      break;
    case 3:
      month = 'Apr'
      break;
    case 4:
      month = 'May'
      break;
    case 5:
      month = 'Jun'
      break;
    case 6:
      month = 'Jul'
      break;
    case 7:
      month = 'Aug'
      break;
    case 8:
      month = 'Sept'
      break;
    case 9:
      month = 'Oct'
      break;
    case 10:
      month = 'Nov'
      break;
    case 11:
      month = 'Dec'
      break;
    default:
      month = ''
      break;
  }
  
  return (`${month} ${day}, ${year}`)
}


export const dateNumeric = (input) => {
  const date = new Date(input);
  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth();
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${year}-${month}-${day}`
}
export const dateNumericVar = (input) => {
  const date = new Date(input);
  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth();
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${month}/${day}/${year}`
}
export const dateNumericMonthDay = (input) => {
  const date = new Date(input);
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth();
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${month}/${day}`
}

export const hasStarted = (input) => {
  const today = new Date();

  return +today >= +input;

}

export const addDays = (dateObj, numDays) => {
  dateObj.setDate(dateObj.getDate() + numDays);
  return dateObj;
}

export const dayDiff = (dateOne, dateTwo) => {
  const date1 = new Date(dateOne);
  const date2 = new Date(dateTwo);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // console.log(diffTime + " milliseconds");
  // console.log(diffDays + " days");
  return diffDays
}

export const simpleTimeFormat = (input) => {
  const date = new Date(input);

  const h = date.getHours();
  const m = date.getMinutes();
  const hours = h >= 10 ? h : `0${h}`;
  const minutes = m >= 10 ? m : `0${m}`;

  return `${hours}:${minutes}`
}