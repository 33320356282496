import React, { useEffect } from 'react';
import ls from 'local-storage';

import MainLoader from '../Shared/MainLoader';
import { postStudentLogout } from './../../API/auth.service'


const Logout = () => {

    async function logStudentOut() {

        let errors = await postStudentLogout(ls('token'))
        console.log(errors)
        ls.remove('token');
        ls.remove('refreshTriggered');
        ls.remove('backUrl');
        ls.remove('authUrl');
        ls.remove('chatUrl');
        ls.remove('env');
        ls.remove('imp');
    }

    useEffect(() => {
        logStudentOut()
    })
    return (
        <div className="u-full-width">
            <MainLoader message="Logging out..." />
        </div>
    )
};

export default Logout;
