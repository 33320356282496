import React from 'react';
import { useDispatch } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';

import Routes from './Routes';

const Router = () => {
    const dispatch = useDispatch();

    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    );
}

export default Router;

// import React from 'react';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { useQuery } from '@apollo/react-hooks';

// import LoggedInRouter from './LoggedInRouter';
// import Login from './Auth/Login';
// import Logout from './Auth/Logout';
// import { LOGGED_IN } from '../queries';
// import ErrorPage from './Shared/ErrorPage';

// const Root = () => {
//     const { data } = useQuery(LOGGED_IN);
//     const alertMsg = `ROOT ${data.loggedIn}`;
//     alert(alertMsg);
//     if (data && data.error) {
//         return <ErrorPage />
//     }
//     return (
//         <BrowserRouter>
//             {
//                 data.loggedIn ? 
//                 <LoggedInRouter /> :
//                 (
//                     <Switch>
//                         <Route path="/login" component={Login} />
//                         <Route path="/logout" component={Logout} />
//                         <Route path="/" component={Login} />
//                     </Switch>
//                 )
//             }
//         </BrowserRouter>    
//     );
// };

// export default Root;