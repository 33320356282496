import React, { useEffect, useRef } from 'react';
import { dateFormatYear, simpleTimeFormat } from '../../../../../../../utils/dateFunctions';
import styles from './PopUpModal.module.scss'
const PopUpModal = ({ selectedPopUp, setSelectedPopUp, isRejected, currentFeedback, teacher, feedbackHistory }) => {
  const refFeedback = useRef();
  const refRevision = useRef();

  useEffect(() => {
    refRevision.current.style = `top: -${refRevision.current.offsetHeight + 15}px `
    refFeedback.current.style = `top: -${refFeedback.current.offsetHeight + 15}px`
  })

  return (
    <>

      <div className={`${styles.popUpModal} ${styles.feedback} ${selectedPopUp === 'feedback' && styles.active}`} ref={refFeedback} >
        {currentFeedback &&
          <div>

            <div className={`${styles.message} ${styles.feedback}`}>
              <p>{currentFeedback}</p>
            </div>

            <div className={styles.teacherContainer}>
              <img src={teacher.profilePicturePath} alt='teacher' />
              <p>{teacher.displayName}</p>
            </div>

          </div>
        }
        <div className={styles.closeBtn} onClick={() => setSelectedPopUp(null)}>X</div>
      </div>


      <div className={`${styles.popUpModal} ${styles.revision} ${selectedPopUp === 'revision' && styles.active}`} ref={refRevision}>
        {isRejected &&
          <div>
            {feedbackHistory.map((elm, idx) => <div className={`${styles.message} ${styles.revision}`}>

              <div className={styles.dateContainer}>
                <span>{dateFormatYear(elm.date)}</span>
                <span>{simpleTimeFormat(elm.date)}</span>
              </div>
              <p>{elm.description}</p>

            </div>)}
            <div className={styles.teacherContainer}>
              <img src={teacher.profilePicturePath} alt='teacher' />
              <p>{teacher.displayName}</p>
            </div>
          </div>
        }

        <div className={styles.closeBtn} onClick={() => setSelectedPopUp(null)}>X</div>
      </div>

    </>
  )
}

export default PopUpModal;