import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { NavLink } from 'react-router-dom';

import { GET_SUB_MENU_DATA } from '../../../queries';

const ProfileMenu = ({ className }) => {
    const { data, error, loading } = useQuery(GET_SUB_MENU_DATA);

    return (
        <div className={`${className} sub-menu`}>
            <p className="sub-menu__title">{loading && 'Loading...'}{data && data.currentUser ? `${data.currentUser.firstName} ${data.currentUser.lastName}` : ''}</p>
            {error && <span>error.message</span>}
            <ul className="sub-menu__items">
                <li className="sub-menu__item">
                    <NavLink className="sub-menu__link" to="/user/profile/name">My Details</NavLink>
                </li>
                { 
                    data && (data.currentUser && !data.currentUser.googleAccount) ? (
                        <li className="sub-menu__item">
                            <NavLink className="sub-menu__link" to="/user/profile/password">Password</NavLink>
                        </li>
                    ) : null
                }
                <li className="sub-menu__item">
                    <NavLink className="sub-menu__link" to="/user/profile/school">Classes</NavLink>
                </li>
                <li className="sub-menu__item u-hidden-mobile">
                    <NavLink className="sub-menu__link" to="/user/profile/boomer">My Boomer</NavLink>
                </li>
            </ul>
        </div>
    );
}

export default ProfileMenu;