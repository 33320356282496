//have a good day!

import React, { useState } from 'react';
import styles from './NewDashboard.module.scss'
import BoomerColumn from './NewDashboardComponents/BoomerColumn/BoomerColumn';


import ClassSelector from './NewDashboardComponents/ClassSelector/ClassSelector';
// import { useQuery } from '@apollo/react-hooks';
// import {getFromApi} from './../../API/api.service'


const NewDashboard = ({ currentUser }) => {


  //API CALL
  // const restApiData = getFromApi('dashboard')
  
  // const { data, error, loading } = useQuery(MAIN_LOGIN_QUERY)/* First it queries the main login query, when it resolves it continues making queries */
  const [dasboardTerm, setdasboardTerm] = useState('school');
  let schoolGroups =[];

  schoolGroups = currentUser.groups.filter((elm) => elm.classType < 9 || elm.classType === null)



  

  

  // checks if there school gorups are empty



  if (currentUser) return (
    <div>
      <div className={styles.adaptToScreen}>
        <div className={styles.dashboardContainer}>
          <div className={styles.dashboardBody}>
            <div>
            <BoomerColumn avatar={currentUser.avatar} name={`${currentUser.firstName} ${currentUser.lastName}`} groups={currentUser.groups} />
            </div>
            <div className={styles.fixthis}>
                <ClassSelector groups={schoolGroups} memberOfAClass={currentUser.memberOfAClass} setdasboardTerm={setdasboardTerm} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );

  return (
    <></>
  )
}

export default NewDashboard;


// TODO src/components/Utils/UserSubscription.js:47:20