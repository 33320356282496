import React from 'react';
import _ from 'lodash';

import attachment from '../../../../../../../images/attachments.png';
import calendar from '../../../../../../../images/calendar.png';
import notes from '../../../../../../../images/notes.png';
import vocab from '../../../../../../../images/vocab.png';
import grading from '../../../../../../../images/grading-alt.png';
import imageUpload from '../../../../../../../images/instructionsIcons/imageUpload.png'
import { formattedDate } from '../../../../../../../utils/globals';
import styles from './UnitInstructions.module.scss'
import ImageCarousel from './ImageCarousel/ImageCarousel';
import Linkify from 'react-linkify';

const Instructions = ({ entryText, currentUnit, showFullRubric, setShowModal }) => {

    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
          {text}
      </a>
  );

	const vocabModule = (vocabs, vocabUsed) => {
		if (vocabs.length > 0) {
			return (
				<div className={styles.instruction}>
					<img src={vocab} alt="Vocabulary" className={styles.instructionImage} />

					<div className={styles.instructionBody}>
						<p>Word Bank: <span className="">{vocabUsed.length}/{vocabs.length}</span> </p>
						<ul className="">
							{vocabs.map(vocab => <li id={`vocab${vocab.id}`} key={vocab.id} className={`instruction__vocab ${_.includes(vocabUsed, _.toLower(vocab.word)) && 'pulse instruction__vocab--used'}`}>{vocab.word}</li>)}
						</ul>
					</div>
				</div>
			)
		}
		return null;
	}

	const attachmentModule = (documents) => {
		if (documents && documents.length > 0) {
			return (
				<div className={styles.instruction}>

					<img src={attachment} alt="Attachments" className={styles.instructionImage} />

					<div className={styles.instructionBody}>
						<p>Activity Resources</p>
						{
							_.map(documents, doc => {
								if (!doc.deleted) {
									return (
										<a key={doc.url} href={doc.url} target="_blank" rel="noopener noreferrer" className="instruction__doc"><span className="instruction__doc-name">{doc.name}</span><span className="instruction__doc-read">Read</span></a>
									)
								}
								return null;
							})
						}
					</div>
				</div>
			)
		}
		return null;
	}

	const imagePreview = () => {
		//check if there are images on the currentUnit and loads ImageCarousel
		if (currentUnit.images && currentUnit.images.length > 0) {
			return (
				<div className={styles.instruction}>

					<img src={imageUpload} alt="imageUpload" className={styles.instructionImage} />

					<div className={styles.instructionBody}>
						<p>Images</p>
						<ImageCarousel images={currentUnit.images} setShowModal={setShowModal}/>
					</div>
				</div>
			)
		} else {
			return <></>
		}

	}

	const escapeVocab = (vocab) => {
		return vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}

	const getVocabUsed = (vocabs) => {
		const vocabUsed = [];
		_.each(vocabs, vocab => {
			const exp = RegExp('\\b(' + escapeVocab(vocab.word) + ')\\b', 'gi');
			if (_.words(entryText, exp).length !== 0) {
				vocabUsed.push(vocab.word);
			};
		});

		return vocabUsed;
	}

	return (
		<div className={styles.instructionsContainer}>

			{currentUnit.deadline&& 
				<div className={styles.instruction}>
					<img src={calendar} alt="Calendar" className={styles.instructionImage} />
					<div className={styles.instructionBody}>
						<p>Writing Deadline</p>
						<p className="">{formattedDate(currentUnit.deadline)}</p>
					</div>
				</div>
			}

			{currentUnit.instructions &&
				<div className={styles.instruction}>
					<img src={notes} alt="Notes" className={styles.instructionImage} />
					<div className={styles.instructionBody}>
						<p>Instructions</p>
						<p className={styles.instructionText}>
							<Linkify componentDecorator={componentDecorator}>
								{currentUnit.instructions}
							</Linkify>
						</p>
					</div>
				</div>
			}

			{currentUnit.currentUserEntry.rubric &&
				<div className={styles.instruction}>
					<img src={grading} alt="Grading" className={styles.instructionImage} />
					<div className={styles.instructionBody}>
						<p>Grading</p>
						<p className="">Your work will be graded against the following rubric:</p>
						<button className={styles.rubricBtn} onClick={() => showFullRubric(true)}>
							<span className={styles.rubricName}>{currentUnit.currentUserEntry.rubric.name}</span>
							<span className={styles.rubricView}>View</span>
						</button>
					</div>
				</div>
			}

			{/* {currentUnit.currentUserEntry.rubric &&
				<SideRubric rubric={currentUnit.currentUserEntry.rubric} showFullRubric={showFullRubric} showScore={currentUnit.shareGrading} />
			} */}

			{vocabModule(currentUnit.vocab, getVocabUsed(currentUnit.vocab))}

			{attachmentModule(currentUnit.documents)}

			{imagePreview()}

		</div>
	)
}

export default Instructions;

//preview && currentUnit.phase === WRITING && <button className="button button--bordered project-next--mob" onClick={nextAction}>{buttonText || "Next"}</button>

/* {preview && currentUnit.phase !== WRITING && (
	<div className="instruction u-m-base-40 instruction--closed instruction--hidden-desktop">
		<div className="instruction__side">
			<img src={caution} alt={caution} className="instruction__img" />
		</div>
		<div className="instruction__main">
			<p>Writing Closed!</p>
			<p className="instruction__detail u-m-top-5">Writing has closed for {currentUnit.title}</p>
		</div>
	</div>
)} */