import React from 'react';

import { RATING_LABELS } from '../../constants';

import Star from './Star';

import styles from './RatingPanel.module.scss';

const StarsHolder = ({
    displayOnly,
    enabled,
    rating,
    setRating,
    setTempRating
}) => {

    return (
        <div className={styles.stars}>
            {
                RATING_LABELS.map((_, i) => {
                    if (i === 0) {
                        return null;
                    }
                    return (
                        <Star
                            displayOnly={displayOnly}
                            key={`star-${i}`}
                            enabled={enabled}
                            rating={rating}
                            setRating={setRating}
                            setTempRating={setTempRating}
                            value={i}
                            alt={`Rating value: ${RATING_LABELS[i]}`}
                        />
                    )
                })
            }
        </div>
    );
}

export default StarsHolder;
