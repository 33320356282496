import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams, withRouter } from 'react-router-dom';
import { PROJECT_UNIT_QUERY, PROJECT_UNITS_QUERY } from '../../queries';
import MainLoader from '../Shared/MainLoader';
import { DRAFT, WRITING, REVIEW, VOTING, VOTING_COMPLETE, TIE_BREAK, COMPLETED, ARCHIVED, START_NEXT, SCHEDULED, STORY_WRITER } from '../../utils/globals';
import UnitWritingQuery from './Unit/UnitWriting/UnitWritingQuery';
// import UnitPreview from '../Project/Unit/UnitPreview/UnitPreview';
import UnitVotingQuery from '../Project/Unit/UnitVoting/UnitVotingQuery';
import UnitReview from '../Project/Unit/UnitReview';
import Breadcrumbs from './Structural/Breadcrumbs/Breadcrumbs';
import styles from './NewDashboard.module.scss'
import storyPill from '../../images/activityTypePill-story.svg'
import assignmentPill from '../../images/activityTypePill-assignment.svg'
import journalPill from '../../images/activityTypePill-journal.svg'
import Writingscroller from '../Project/SlideScroller/WritingScroller'
import PublishScroller from '../Project/SlideScroller/PublishScroller'
import StoryStartPreview from '../Project/StoryStartPreview/StoryStartPreview'
import votingBoomers from '../../images/voting-closed.png'

const UnitRouter = ({ history, showIdleModal }) => {
  const { projectId, unitIdx, edit } = useParams()
  const initIdx = useState(unitIdx-1)
  const [ isEditable, setIsEditable ] = useState(true)
  const [ isStoryStart, setIsStoryStart ] = useState(false)
  const [ isReview, setIsReview ] = useState(false)
  const [ displayIdx, setDisplayIdx ] = useState(parseInt(initIdx))
  const [ qlData, setQlData ] = useState(null) 
  const [showPreview, setShowPreview] = useState(true)
  const [showStoryStart, setShowStoryStart] = useState(false)
  const [hidePublish, setHidePublish] = useState(false)
  const {data, loading} =  useQuery(PROJECT_UNITS_QUERY, {
    variables: { id: projectId }
  })

  
  
  useEffect(()=>{setQlData(data)},[data])

  const scrollUnits = (direction) => {

    //filter out units which are not displayed
    let unitsArray = data.project.units.filter(i => 
      i.phase !== SCHEDULED &&
      i.phase !== DRAFT &&
      i.phase !== ARCHIVED &&
      i.phase !== START_NEXT
      )



      // isolate indexes, disregard all other keys
      const unitsArray2=unitsArray.map(i=>i.index-1)

      //ascertain which index within the new array is the index of the currently displayed unit
      const currentIdx = unitsArray2.findIndex(i => parseInt(i)===parseInt(displayIdx))

      if(unitsArray.length!==1){
        if(direction==='forward'&&currentIdx<unitsArray.length-1){
            setDisplayIdx(parseInt(unitsArray2[currentIdx+1]))
          
          } else if(direction==='back'&&currentIdx>0){
            setDisplayIdx(parseInt(unitsArray2[currentIdx-1]))
          }
      }
  }

  useEffect(()=>{

    //update various state vaiable when displayIdx is changed by user (scroll arrows)

    setIsEditable(true)
    setIsStoryStart(false)
    setIsReview(false)

    if(data&&data.project){

      const { type, journal, units } = data.project

      if(units[displayIdx].phase===COMPLETED){
        setIsEditable(false)
        setIsReview(true)
      }
      if(units[displayIdx].phase===START_NEXT || data.project.units[unitIdx - 1].phase===REVIEW){
        setIsReview(true)
      }

      if(type===3 && !journal && displayIdx===0){
        setIsEditable(false)
        setIsStoryStart(true)
      }

    }
  },[data, displayIdx])

  const renderActivityTypePill = (project) => {
    const imgSrc =  project.journal ? journalPill :  project.type === 3 ? storyPill : project.type === 1 ? assignmentPill : journalPill;
    //if project type is math (7) do not return anything at all
    if (project.type !== 7) {
    return <img className={styles.typePill} src={imgSrc} alt="pill"/>
    } else {
      return null
    } 
    
  }



  const unitLogic = () => {
    const { type, journal } = data.project
    if (edit !== undefined) {
      return <UnitWritingQuery 
                isStoryStart={isStoryStart}
                isReview={isReview}
                projectId={projectId} 
                unitIdx={displayIdx} 
                edit={isEditable} 
                showIdleModal={showIdleModal} 
                scrollUnits={scrollUnits} />
    }
    
    switch (data.project.units[displayIdx].phase) {
      case DRAFT:
        return <p>This unit hasn't started yet</p>;
      case WRITING:
      case REVIEW:
        if (data.project.units[displayIdx].currentUserEntry.hasStarted) {
          return <UnitWritingQuery 
                    isStoryStart={isStoryStart}
                    isReview={isReview}
                    projectId={projectId} 
                    unitIdx={displayIdx} 
                    edit={isEditable} 
                    showIdleModal={showIdleModal} 
                    scrollUnits={scrollUnits} />
        } else {
          if (data.project.type !== STORY_WRITER || data.project.type === STORY_WRITER && displayIdx > 1 ) {
            return           <UnitWritingQuery 
            isStoryStart={isStoryStart}
            isReview={isReview}
            projectId={projectId} 
            unitIdx={displayIdx} 
            edit={isEditable} 
            showIdleModal={showIdleModal} 
            scrollUnits={scrollUnits} /> 
          } else {
          return showPreview ? showStoryStart ? <StoryStartPreview storyStart={data.project.units[0].storyStart} exit={setShowPreview} /> : <Writingscroller exit={setShowStoryStart} writingClosed={data.project.units[displayIdx].phase === 3} /> :
          <UnitWritingQuery 
                    isStoryStart={isStoryStart}
                    isReview={isReview}
                    projectId={projectId} 
                    unitIdx={displayIdx} 
                    edit={isEditable} 
                    showIdleModal={showIdleModal} 
                    scrollUnits={scrollUnits} /> 
          }
        }
      case VOTING:
      case TIE_BREAK:
        return <UnitVotingQuery 
                  projectId={projectId} 
                  unitIdx={displayIdx}  
                  unitId={data.project.units[displayIdx].id}
                  isSW={data.project.type === STORY_WRITER} />;
      case VOTING_COMPLETE:
        return <div className="light-panel voting-success votingEndPanel">
            <img src={votingBoomers} className="votingBoomers"/>
            <h3 className="votingHeader">Voting Closed!</h3>
            <p>Winner will be announced soon!</p>
            </div>
      case COMPLETED:
      case START_NEXT:
        let author = data.project.units[displayIdx].currentUserEntry.author.id
        let winner = data.project.units[displayIdx].winner.studentId
        if (data.project.type === STORY_WRITER && data.project.units[displayIdx].phase === COMPLETED && !hidePublish && data.project.units.length -1 === parseInt(displayIdx)) {
          return <>
            <PublishScroller exit={setHidePublish} history={history} alternate={data.project.units[displayIdx].currentUserEntry.approved} winner={winner === author} />
          </>
        }
        return <UnitWritingQuery
                  isStoryStart={isStoryStart}
                  isReview={isReview}
                  projectId={projectId}
                  unitIdx={displayIdx}
                  edit={isEditable} 
                  showIdleModal={showIdleModal}
                  scrollUnits={scrollUnits} />
          
          

      case ARCHIVED:
        return <p>Should a user see an archived unit?</p>;
      case SCHEDULED:
        return <p>This unit is not live yet</p>
      default:
        return <p>You shouldn't see this</p>;
    }
  }


  if (loading) return <MainLoader />

  if (data) {return (

    <div className={styles.entryRouterAdjust}>

     <div className={styles.breadcrumbDiv}>

      <Breadcrumbs 
        classData={{ 
          classId: data.project.groups[0].id, 
          name: data.project.groups[0].name 
        }} 
        projectData={{ 
          projectId: projectId, 
          name: data.project.title, 
          isStory: data.project.type===3
        }} 
        unitName={data.project.units} 
        displayIdx={{current:displayIdx, set:setDisplayIdx}}

      />

      {renderActivityTypePill(data.project)}

     </div>
      {unitLogic()}

    </div>
  )} else {return (<h6>no project data received</h6>)};
}

export default withRouter(UnitRouter);
