import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';

import ItemPreview from './ItemPreview';
import { ALL_ITEMS_QUERY } from '../../../queries';
import MainLoader from '../../Shared/MainLoader';
import { sortItems } from '../../../utils/boomer';

const Items = ({ onSelectItem, ownedItems, selectedCategory, wearing }) => {
    const { data, error, loading } = useQuery(ALL_ITEMS_QUERY);
    
    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <MainLoader mutation="has-sub-menu"/>
    }
    if (data && data.avatarItems && data.avatarItems.length !== 0) {
        const allItems = sortItems(wearing, ownedItems, data.avatarItems);
        const items = selectedCategory === "OWNED" ? _.filter(allItems, 'owned') : _.filter(allItems, ['type', selectedCategory]);
        if (items.length > 0) {
            return _.map(items, item => {
                return (
                    <li key={`item-${selectedCategory}-${item.id}`} onClick={() => onSelectItem(item)} className={`store__item ${item.wearing ? 'store__item--wearing' : ''} ${item.owned ? 'store__item--owned' : ''}`}>
                        <ItemPreview src={item.url[0]} className="store__item-img" />
                        {item.owned ? <p className="store__item-owned">Owned</p> : null }
                    </li>
                )
            });
        }
        if (selectedCategory === "OWNED") {
            return <li className="store__no-item">You do not own any items</li>
        }
    }
    return null;
}

export default Items;
