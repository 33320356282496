import React, { Fragment } from 'react';
import _ from 'lodash';
import BoomerHeading from '../../Shared/BoomerHeading';
import JoinSchoolFormHolder from '../../Shared/School/JoinSchoolFormHolder';
const School = ({ student }) => (
    <Fragment>
        <div className="u-hidden-mobile">
            <BoomerHeading
                mainHeading="Class Details"
                mutation="boomer-desktop-only"
                showBoomer={true}
                subHeading={student.school ? "About Your Classes" : student.pendingSchool ? "Almost There!" : "Connect To Your Class"}
            />
        </div>
        {student.groups ? <h2 className="u-m-top-40 basic-form__label u-m-base-10 u-m-top-40">Your Classes</h2> : null}
        <ul className="class-list u-m-top-20 u-m-base-20">
            {
                _.map(student.groups, group => {
                    return (
                        <li className="class-list__item" key={`group${group.id}`}>
                            <span className="class-list__name">{group.name} {group.membershipStatus === 0 && <span className="class-list__pending">Awaiting Approval</span>} </span>
                            <div className="class-list__teacher"><img src={group.teachers[0].profilePicturePath} alt="Profile" className="class-list__avatar" />{group.teachers[0].displayName}</div>                      
                        </li>
                    );
                })
            }
        </ul>
        {/*<SchoolCard grade={student.grade} pendingSchool={student.pendingSchool} school={student.school} />*/}
        <h2 className="basic-form__label u-m-base-10 u-m-top-40">Join A Class</h2>
        <JoinSchoolFormHolder />
    </Fragment>
);

export default School;