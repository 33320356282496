import React from 'react';

import ReactPlayer from 'react-player/youtube'

import styles from './VideoModal.module.scss'

const VideoModal = ({setshowVideoModal,introVideoUrl}) => {



  return (
    <div className={styles.modalWrapper}>
      <div className={styles.videoModal}>
      <ReactPlayer url={introVideoUrl} controls={true}/>
        <div onClick={()=>setshowVideoModal(false)} className={styles.closeButton}>X</div>
        <div onClick={()=>setshowVideoModal(false)} className={styles.closeText}>Close</div>      
      </div>
    </div>
  );
}

export default VideoModal;
