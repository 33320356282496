import React, {useState, useEffect} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { MESSAGE_HISTORY_QUERY } from '../../../queries';
import Messages from './Messages';
import loader from '../../../images/small-loader.svg';
const MessagesQuery = ({ teacherId, newMessage, teacherAvatar }) => {

    const [teacher, setTeacher] = useState(teacherId)

    useEffect(() => {
        if (teacherId !== teacher) {
            setTeacher(teacherId);
        }
    }, teacherId)


    const { data, error, loading } = useQuery(MESSAGE_HISTORY_QUERY, {fetchPolicy: 'network-only', variables: {
        id: teacher,

    }});
    return (
        <div className={`chat-main__message-body`}>
            <>
            {error && <p>Something went wrong</p>}
            {loading && <img src={loader} className="chat-loader" alt="loading"/>}
            {data && !loading && (
                <>
                    <Messages messages={data.currentUser.chatMessages} newMessage={newMessage} teacherAvatar={teacherAvatar} />
                </>
            )}
            </>
        </div>   
    )
}

export default MessagesQuery;