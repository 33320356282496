import React, { useState, useEffect } from 'react';
import styles from './IdleModal.module.scss'
// import { LOGOUT_ENDPOINT } from '../../utils/endPoints';
import { useHistory } from "react-router-dom";
import teachef from './../../images/newDashboard/decorations/teachef.png'

const IdleModal = ({ setshowIdleModal }) => {
  // idle modal that logs you out
  
  const history = useHistory()
  const [timeLeft, setTimeLeft] = useState(30);


  useEffect( () => {
    const timerInterval = setInterval(() => setTimeLeft(timeLeft => timeLeft -1),1000);
    return () => clearInterval(timerInterval);  
  },[]);

  const handleLogout = () => {
    history.push('/logout');
  }

  timeLeft === 0 && handleLogout()

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.idleModal}>
        <div className={styles.heading}> 
        <img src={teachef} alt='idle img'/>
        Are you still here?
        </div>
        <p>if not, you will be automatically logged out in: {timeLeft}s </p>
        <div className={styles.orangeBtn} onClick={() => setshowIdleModal(false)}>I'm here</div>
        <div className={styles.logout} onClick={() => handleLogout()}>Logout</div>
      </div>
    </div>
  );
}

export default IdleModal;