import React, { Fragment } from 'react';
import _ from 'lodash';

import ItemPreview from './ItemPreview';
import Coins from '../../../images/coins.png';

const Tiles = ({ item, onRemoveItem, onSelectTile }) => {
    return (
        <Fragment>
            <li className="store__item-price"><p className="store__item-desc">{item.type}</p><img src={Coins} className="store__item-coins" alt="$"/><p>{item.price}</p></li>
            {
                _.map(item.url, (tile, index) => {
                    return (
                        <li key={`tile-${item.id}-${index}`} onClick={() => onSelectTile(index + 1)} className={`store__item ${Number(item.tile) === index + 1 ? 'store__item--wearing' : ''}`}>
                            <ItemPreview src={tile} className="store__item-img" />
                        </li>
                    )
                })
            }
            {
                item.owned && item.wearing ? 
                <li onClick={() => onRemoveItem()} className={`store__item ${Number(item.tile) === -1 ? 'store__item--wearing' : ''}`}><span className="store__item-img store__item-img--remove">Remove</span></li> :
                null
            }
        </Fragment>
    );
}

export default Tiles;