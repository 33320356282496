import React, { useState, useRef, useContext } from 'react';
import MainWritingEditor from './MainWritingEditor/MainWritingEditor';
import SidePanel from './SidePanel/SidePanel';
import styles from './UnitWriting.module.scss'
import FullRubric from './../../../Project/Grading/FullRubric'
import EntryHistory from '../../../Project/Unit/UnitWriting/EntryHistory';
import Submit from './Submit/Submit';
import VideoModal from '../../../VideoModal/VideoModal';
import { useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { REVIEW, VOTING} from '../../../../utils/globals';
import HiFive from '../../../../images/hifive.png'
import group from '../../../../images/boomer-group.png';
import coins from '../../../../images/coins.png';
import { Link } from 'react-router-dom';
import { PointsContext } from '../../../../utils/pointsProvider'
import greatJob from '../../../../images/great-job.png';
import greatJobMobile from '../../../../images/great-job-mobile.png';

const UnitWriting = ({ project, edit, unitIdx = null, showIdleModal, scrollUnits, isReview, isStoryStart }) => {
  const pointsContext = useContext(PointsContext)
  const {points, setPoints} = pointsContext;
  // This component manages the entry editor and the instructions tab

  /* ----- Making objects not painful ----- */
  const currentUnit = project.units[unitIdx]
  const { introVideoUrl } = project
  // const introVideoUrl  = 'https://www.youtube.com/watch?v=8N2XSwuXkrU'
  let { currentUserEntry, roomPartnerEntry, shareGrading, shareGrammar } = currentUnit
  const { id, entryText, submitted, rubric, rejected, wordLimit, phase, approved, revisionStatus, overallGradingScore } = currentUserEntry
  const isRejected = rejected && (revisionStatus === 1 || revisionStatus === 2);
  /* ----- Component State ----- */
  const [showFullRubric, setShowFullRubric] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showVideoModal, setshowVideoModal] = useState(false);
  const [localWordCount, setLocalWordCount] = useState(currentUserEntry.wordsCount);
  const [ showModal, setShowModal ] = useState({vis:false, idx:0})
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [showEndWarning, setShowEndWarning] =  useState(entryText.length === 0 && project.type === 3 && unitIdx + 1 === project.units.length)

  window.addEventListener('keydown', (e) => { if (e.keyCode === 27) { setShowModal({vis:false, idx:0}) } }); //line to press esc and go off modal
  

  useEffect(()=>{
    if(showModal.vis&&showModal.idx!==0){
      const dots = Array.from(document.querySelectorAll('.dot'))
      setTimeout(()=>{
        if(dots[showModal.idx]){
        dots[showModal.idx].click()}
        },100)
    }

  },[showModal])

  const [entry, setEntry] = useState({
    id: id,
    text: entryText,
    original: entryText,
    restore: false
  });

  useEffect(() => {
    introVideoUrl ? setshowVideoModal(true) : setshowVideoModal(false)
  },[introVideoUrl])


  //update entry details upon new unitIdx being passed through props
  useEffect(()=>{
    const newUserEntry = project.units[unitIdx].currentUserEntry
    setEntry({
      id: newUserEntry.id,
      text: newUserEntry.entryText,
      original: newUserEntry.entryText,
      restore: newUserEntry.false
    });
  },[unitIdx])

  /* ----- Functions ----- */

  const toggleShowHistory = () => setShowHistory(!showHistory);
  return (
    <>
      <div className={styles.unitWriting}>
        <div className={styles.editorContainer}>
          <MainWritingEditor
            activityType={project.type}
            showIdleModal={showIdleModal}
            scrollUnits={scrollUnits}
            units={project.units}
            entry={entry}
            isReview={isReview}
            isStoryStart={isStoryStart}
            unitIdx={unitIdx}
            setEntry={setEntry}
            currentUserEntry={currentUserEntry}
            toggleShowHistory={toggleShowHistory}
            title={currentUnit.title}
            wordLimit={currentUnit.wordLimit}
            teacher={project.teacher}
            edit={(!isStoryStart && currentUserEntry.canWrite && !currentUserEntry.submitted && !isReview || isRejected && project.units[unitIdx].phase < VOTING)}
            isStory={project.type===3}
            isRejected={isRejected}
            roomPartnerEntry={roomPartnerEntry}
            overallGradingScore={overallGradingScore}
            setShowFullRubric={setShowFullRubric}
            shareGrading={shareGrading}
            shareGrammar={shareGrammar}
            introVideoUrl={introVideoUrl}
            showVideoModal={showVideoModal}
            setshowVideoModal={setshowVideoModal}
            setLocalWordCount={setLocalWordCount}
          />
          {(!isStoryStart && currentUserEntry.canWrite && !currentUserEntry.submitted && !isReview || isRejected && project.units[unitIdx].phase < REVIEW) &&

            <Submit
              isOverMax = {localWordCount > currentUnit.wordLimit}
              submitted={submitted}
              phase={phase}
              approved={approved}
              entry={entry}
              isRejected={isRejected}
              submitSuccess={setSubmitSuccess}
              />
          }

        </div>


        <SidePanel
          currentUnit={currentUnit}
          setShowFullRubric={setShowFullRubric}
          units={project.units}
          setShowModal={setShowModal}
          entryText={entry.text} />


          {showModal.vis && 
            <div className={styles.modal}>
              <div className={styles.inner}>

              <div className={'closeModal'} onClick={() => setShowModal(false)}><p>X</p></div>
              <Carousel>
                { currentUnit.images.map(image => <div><img src={image} /></div>) }
              </Carousel>
              </div>
            </div>
          }
      </div>
      {/* ----- Modals ----- */}
      
      {showEndWarning &&
      <div className={styles.modal2}>
          <div className={styles.inner}>
            <div className={styles.closeModal} onClick={() => setShowEndWarning(false)}><p>X</p></div>
            <div className={styles.whitePanel}>
              <img src={HiFive} className={styles.hifiveImg} />
              <h1 style={{color: '#27507A'}}>Last Chapter, this is it!</h1>
              <p style={{marginTop: '10px', lineHeight: '20px', textAlign: 'center', color: '#27507A'}}>This chapter will appear in the book, so give it your best shot!</p>
              <button className={styles.submitBtn} onClick={() => setShowEndWarning(false)}>Start Writing</button>
            </div>
          </div>
        </div>
}

      {showVideoModal && <VideoModal setshowVideoModal={setshowVideoModal} introVideoUrl={introVideoUrl} />}

      {showHistory && <EntryHistory hide={toggleShowHistory} entryId={id} setEntry={setEntry} />}

      {showFullRubric && <FullRubric rubric={rubric} showFullRubric={() => setShowFullRubric()} showScore={currentUnit.shareGrading} />} {/*old app but works great*/}
      {submitSuccess && currentUserEntry.revisionStatus !== 3 && <>
        <div 
                onClick={(event) => {
                    if (event.target.classList.contains('confirm-overlay')) {
                        setSubmitSuccess(false)
                        setPoints(null)
                    }
                }} 
                className={`confirm-overlay`}
            >
        <div className="confirm-overlay__body vote-confirm-overlay__body">
              <button onClick={() => {setSubmitSuccess(false);setPoints(null)}} className="confirm-overlay__close-button vote-confirm-overlay__close-button">
                  <span className="confirm-overlay__close-icon">x</span>
              </button>
              <img className="vote-confirm-overlay__voted-img u-hidden-mobile" src={greatJob} alt="You Voted" />
                  <img className="vote-confirm-overlay__voted-img u-only-mobile" src={greatJobMobile} alt="You Voted" /> 
              <div className="vote-confirm-overlay__inner">
                  <p className="vote-confirm-overlay__title">You earned <img alt="coins" className="vote-confirm-overlay__coins" src={coins} /> {points}!</p>
                  <p className="vote-confirm-overlay__message">You can go spend your hard earned Boomer Bucks!</p>
                  <div className="vote-confirm-overlay__button-holder">
                  <Link to='/user/profile/boomer' className="button button--bordered button--blue vote-confirm-overlay__button">Hit The Store</Link>                     
                  </div>
              </div>
              <img className="vote-confirm-overlay__group-img" src={group} alt="Boomer Group" />
          </div>
          </div>
      </>}
    </>
  );
}

export default UnitWriting;