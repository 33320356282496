import React from 'react';
import styles from './ProjectCard.module.scss'
import storieBoomer from './../../../../images/book-generic.png'
import journalBoomer from './../../../../images/newDashboard/decorations/journalBoomer.png'
import assigBoomer from './../../../../images/newDashboard/decorations/assigBoomer.png'
import { Link, useLocation } from 'react-router-dom';
import { WRITING, REVIEW, VOTING, COMPLETED, DRAFT, AWAITING_START, VOTING_COMPLETE } from '../../../../utils/globals';
// import { DRAFT, WRITING, REVIEW, VOTING, TIE_BREAK, COMPLETED, ARCHIVED, START_NEXT } from '../../../../utils/globals';
import writingPhaseIcon from './../../../../images/newDashboard/icons/writingPhaseIcon.png'
import votingPhaseIcon from './../../../../images/newDashboard/icons/votingPhaseIcon.png'
import completedPhaseIcon from './../../../../images/newDashboard/icons/completedPhaseIcon.png'
import standbyPhaseIcon from './../../../../images/newDashboard/icons/standbyPhaseIcon.png'
import StartAndDeadline from '../../Structural/StartAndDeadline/StartAndDeadline';
import StartDate from '../../Structural/StartAndDeadline/StartDate';
import DeadlineDate from '../../Structural/StartAndDeadline/DeadlineDate';
import { dateFormat } from '../../../../utils/DateHelper';

const ProjectCard = ({ title, projectId, type, journal, bulletStyle, units, term, studentId, currentUnitData }) => {

  const location = useLocation()
  /*let currentUnitData = {title:'hey',deadline:'12',startedAt:"12",index:'1',phase:2}*/
  let currentUnitCandidateIdx = []
  let areAllUnitsApproved  = false
  let approvedUnits = units.filter(unit=>unit.approved&&unit.approved.includes(parseInt(studentId)))
  //These lines of code adjust the current unit depending on the phase of the unit array 
  units.forEach((unit,idx) => {if(unit.phase===WRITING || unit.phase===REVIEW || unit.phase===VOTING) currentUnitCandidateIdx.push(idx)}); 
  // We push the index of the candidates to be current Unit data to an array
  //currentUnitData=units[currentUnitCandidateIdx[0]] 
  // we select the first one, as it should be the oldest one
  //if(!currentUnitData) currentUnitData=units[units.length-1] 
  // if there arent any idx we select the oldest one so if there is an drafted unit we cans so "StandBy" and if its complete we can show "Complete"

  if(units.length === approvedUnits.length) areAllUnitsApproved=true
  
  if (currentUnitData.phase === AWAITING_START) {return null}

  const renderTypeIcon = () => {
    if (type === 1) {
      if (journal) {
        return <div className={`${styles.iconContainer} ${styles.journal}`}><p>J</p> </div>
      } else {
        return <div className={`${styles.iconContainer} ${styles.assignment}`}><p>A</p></div>
      }
    } else if (type === 2) {
      return <div className={`${styles.iconContainer} ${styles.journal}`}><p>J</p> </div>
    } else if (type === 3) {
      return <div className={`${styles.iconContainer} ${styles.storie}`}><p>S</p> </div>
    }

  }

  const renderDecoration = () => {
    if (type === 1) {
      if (journal) {
        return <figure className={styles.journal}><img src={journalBoomer} alt='' /></figure>
      } else {
        return <figure className={styles.assig}><img src={assigBoomer} alt='' /></figure>
      }
    } else if (type === 2) {
      return <figure className={styles.journal}><img src={journalBoomer} alt='' /></figure>
    } else if (type === 3) {
      return <figure className={styles.stories}><img src={storieBoomer} alt='' /></figure>
    }
  }

  const renderTypeStyles = () => {
    if (type === 1) {
      if (journal) {
        return styles.journal
      } else {
        return styles.assignment
      }
    } else if (type === 2) {
      return styles.journal
    } else if (type === 3) {
      return styles.storie
    } else {
      return undefined
    }
  }

  const renderTermStyles = () => {
    if (term === 'current') return styles.current
    if (term === 'past') return styles.past
    if (term === 'archived') return styles.archived
  }

  const renderBodyCard = () => {
    if (term === 'current') {
      return (
        <div className={styles.cardBody}>
          <div style={{ marginBottom: '10px' }}>{currentUnitData.title} {currentUnitData.index}/{units.length} {dateFormat(currentUnitData.deadline)}</div>
        </div>
      )
    }
    if (term === 'past') {
      return (
        <div className={styles.cardBody}>
          <div>{units.length} sections </div>
          <div style={{ marginTop: '2em' }}>
            <h5>Last section</h5>
            <DeadlineDate deadline={currentUnitData.deadline} />
          </div>

        </div>
      )
    }
    if (term === 'archived') {
      return (
        <div className={styles.cardBody}>
          <div style={{ marginBottom: '10px' }}>{currentUnitData.title} {currentUnitData.index}/{units.length} {dateFormat(currentUnitData.deadline)}</div>
        </div>
      )
    }
  }

  const renderPhaseText = () => {
    if (currentUnitData.phase === WRITING) return <>
        <img src={writingPhaseIcon} alt='' className={styles.phaseIcon}/>
        {currentUnitData.currentUserEntry.rejected ?
          currentUnitData.currentUserEntry.revisionStatus === 3 ? `You’ve submitted your revisions.` : 'Your teacher has asked for some revisions to your chapter.' : 
          currentUnitData.currentUserEntry.submitted ? 
            'You’ve submitted your entry. Watch for any revisions from your teacher' :
            currentUnitData.index === units.length && type === 3 ? 'Last chapter - start writing now!' : 'Ready to write the next chapter? Write your entry now!'
          }
    </>
    if (currentUnitData.phase === REVIEW && areAllUnitsApproved ) return  "Completed"
    if (currentUnitData.phase === REVIEW) return "Writing"
    if (currentUnitData.phase === VOTING) return <>
      <img src={votingPhaseIcon} className={styles.phaseIcon} alt='' />
      {currentUnitData.winner.id ? 'Champter Coplete - Find out who won':
        currentUnitData.currentUserVoted ?
          'You’ve voted' :
          currentUnitData.index === units.length && type === 3 ? <>Vote for your favourite chapter<br />Don’t forget- your own ending will be in your book too!</> 
          : 'Vote for your favourite chapter'
      }
    </>
    if (currentUnitData.phase === VOTING_COMPLETE) return <><img src={votingPhaseIcon} className={styles.phaseIcon} alt='' /> Voting Closed</>
    if (currentUnitData.phase === COMPLETED) return "Completed"
    if (currentUnitData.phase === DRAFT) return "Stand by"  
  }

  const renderText = () => {
    if (currentUnitData.phase === WRITING) return <>
        <img src={writingPhaseIcon} alt='' className={styles.phaseIcon}/> Writing
    </>
    if (currentUnitData.phase === REVIEW && areAllUnitsApproved ) return  "Completed"
    if (currentUnitData.phase === REVIEW) return "Writing"
    if (currentUnitData.phase === VOTING) return <>
      <img src={votingPhaseIcon} className={styles.phaseIcon} alt='' /> Voting
    </>
    if (currentUnitData.phase === COMPLETED) return "Completed"
    if (currentUnitData.phase === DRAFT) return "Stand by" 
  }

    return (
      <Link to={`/unit/${projectId}/${currentUnitData.index}`}>
        <div className={`${styles.projectCard} ${renderTypeStyles()} ${renderTermStyles()} `}>
          <div className={styles.projectCardText}>
            <h3 className="u-bold">{title}</h3>
            {type === 3 ? <h4 className="u-bold">{renderPhaseText()}</h4> :
              <h5>{renderText()}</h5>
            }
          </div>
          {type === 3 ? 'Chapter' : 'Section'} {currentUnitData.index}/{units.length} {currentUnitData.phase === WRITING && <>-  Writing Ends {dateFormat(currentUnitData.deadline)}</>}
          {type === 3 && <img className={styles.projectImg} src={storieBoomer} alt='' />}
        </div>
      </Link>
    )

}

export default ProjectCard;