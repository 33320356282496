import React, { useState, useEffect } from 'react';
import styles from './Calendar.module.scss'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import { eventClick, initCalendarStudent, addDetailsElements } from './calendarFuncs_student';
// import WhiteSchoolIcon from '../../images/newDashboard/icons/WhiteSchoolIcon';
// import WritingClubIcon from '../../images/newDashboard/icons/WritingClubIcon';
import ribbon from './../../images/calendar/ribbon.svg'
import './calendarStyles.scss';
import { useSelector } from 'react-redux';
import ls from 'local-storage';

//Most of this code is ctrl-c from teacher's app

const Calendar = ({ groups }) => {

  const env = ls('env')
  const [events, setEvents] = useState()
  const [groupSelected, setGroupSelected] = useState('all')
  const [isSchool] = useState(true)
  const [initialEvents, setInitialEvents] = useState([])
  const [classLetters, setClassLetters] = useState([]);

  
  useEffect(() => {
    
    groups && initCalendarStudent(groups, styles, setInitialEvents, setEvents, setClassLetters);

  }, [groups, isSchool])

  const handleClassFilter = (input) => {
    if (input === "all") {
      setEvents(initialEvents)
    } else {
      setEvents(initialEvents.filter(item => item.classId === input))
    }
  }


  const handleArrow = (input) => {
    const arrowBtn = input === 'next' ?
      document.getElementsByClassName('fc-next-button')[0] : document.getElementsByClassName('fc-prev-button')[0];
    arrowBtn.click()
  }

  const renderClassSelectorBtn = () => {

    return groups && groups.map((elm, idx) => {
      return (
        <div className={groupSelected === elm.id ? `${styles.active} ${styles.classSelectorBtn}` : styles.classSelectorBtn} key={idx} onClick={() => { setGroupSelected(elm.id); handleClassFilter(elm.id) }}>
          <p>{elm.name}</p>
          <div className={elm.classType >= 9 ? `${styles.ribbon} ${styles.active}` : styles.ribbon} />
          {classLetters[idx] && classLetters[idx].length > 1 ? <span><p className={styles.toThePower}>{classLetters[idx].charAt(0)}<sup>{classLetters[idx].length}</sup></p></span> : <span> {classLetters[idx]}</span>}
        </div>
      )
    })
  }


  return (
    <div className={styles.adaptToScreen}>

      <div className={styles.calendarContainer}>


        <div className={styles.calendarColumn}>
          <div className={styles.pickerRow}>


            <div className={styles.rowTitle}>Filter by class</div>

          </div>

          <div className={styles.classSelector}>
            <div className={groupSelected === 'all' ? `${styles.active} ${styles.classSelectorBtn}` : styles.classSelectorBtn} onClick={() => { setGroupSelected('all'); handleClassFilter('all') }}>
              <p>All Classes</p>

            </div>

            {renderClassSelectorBtn()}

          </div>

        </div>

        <div className={styles.calendarDiv}>

          <div className={styles.arrows}>
            <div onClick={() => { handleArrow('prev') }} className={styles.left}></div>
            <div onClick={() => { handleArrow('next') }} className={styles.right}></div>
          </div>

          <div className={styles.legend}>

            <div className={styles.legendItem}>
              <img className={styles.ribbon} src={ribbon} alt='ribbon' />
              <h5>Writing&nbsp;Club</h5>
            </div>
            <div className={styles.legendItem}>
              <div className={`${styles.dot} ${styles.orange}`} />
              <h5>Assignment</h5>
            </div>
            <div className={styles.legendItem}>
              <div className={`${styles.dot} ${styles.blue}`} />
              <h5>Journal</h5>
            </div>
            <div className={styles.legendItem}>
              <div className={`${styles.dot} ${styles.green}`} />
              <h5>Story</h5>
            </div>
          </div>

          <FullCalendar
            events={events}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            height='680px'
            eventClick={(info) => eventClick(info, env)}
            fixedWeekCount={false}
            dayHeaderFormat={{ weekday: 'long' }}
            displayEventTime={false}
            dayMaxEventRows={3}
            eventDidMount={addDetailsElements}
          />
        </div>

      </div>

    </div>
  );
}

export default Calendar;


// const schoolGroups = groups && groups.filter((elm) => elm.classType < 9 || elm.classType === null)
    // const writingClubGroups = groups && groups.filter((elm) => elm.classType >= 9)
    // groups && isSchool && initCalendarStudent(schoolGroups, styles, setInitialEvents, setEvents, setClassLetters);
    // groups && !isSchool && initCalendarStudent(writingClubGroups, styles, setInitialEvents, setEvents, setClassLetters);


 // if(isSchool) {
    //   return groups && schoolGroups.map((elm, idx) => {
    //     return (
    //       <div className={groupSelected === elm.id ? `${styles.active} ${styles.classSelectorBtn}` : styles.classSelectorBtn} key={idx} onClick={() => { setGroupSelected(elm.id); handleClassFilter(elm.id) }}>
    //         <p>{elm.name}</p>
    //         {classLetters[idx]&&classLetters[idx].length> 1 ?<span><p className={styles.toThePower}>{classLetters[idx].charAt(0)}<sup>{classLetters[idx].length}</sup></p></span>  : <span> {classLetters[idx]}</span> }
    //       </div>
    //     )
    //   })
    // } else {
    //   return groups && writingClubGroups.map((elm, idx) => {
    //     return (
    //       <div className={groupSelected === elm.id ? `${styles.active} ${styles.classSelectorBtn}` : styles.classSelectorBtn} key={idx} onClick={() => { setGroupSelected(elm.id); handleClassFilter(elm.id) }}>
    //         <p>{elm.name}</p>
    //         {classLetters[idx]&&classLetters[idx].length> 1 ?<span><p className={styles.toThePower}>{classLetters[idx].charAt(0)}<sup>{classLetters[idx].length}</sup></p></span>  : <span> {classLetters[idx]}</span> }
    //       </div>
    //     )
    //   })
    // }

/* <div className={isSchool ? `${styles.pickerBtn} ${styles.active}` : styles.pickerBtn} onClick={() => setIsSchool(true)}>
          <WhiteSchoolIcon fill={isSchool ? 'white' : '#a79fb7'} />
          <p>School</p>
        </div>
        <div className={!isSchool ? `${styles.pickerBtn} ${styles.active}` : styles.pickerBtn} onClick={() => setIsSchool(false)}>
          <WritingClubIcon fill={!isSchool ? 'white' : '#a79fb7'} bg={'#4E406E'} unselectedBg={'#4E406E'} />
          <p>Writing club</p>
        </div> */