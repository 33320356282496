import React, { useState } from 'react';
import BoomerHeading from '../../../Shared/BoomerHeading';
import _ from 'lodash';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import spinner from '../../../../images/small-loader.svg';
import { ENTRY_HISTORY_QUERY, PROJECT_UPDATE_MUTATION } from '../../../../queries';
import { entryMarkup } from '../../../../utils/globals';
import moment from 'moment';

const EntryHistory = ({ hide, entryId, projectId, unit,setEntry }) => {
	const client = useApolloClient();
	const { error, loading } = useQuery(ENTRY_HISTORY_QUERY, {
		fetchPolicy: 'network-only',
		variables: {
			entryId: entryId,
			page: 1
		},
		onCompleted: (data) => {
			setHistories({
				drafts: data.unitEntry.histories,
				page: 1,
				loadmore: true,
				total: data.unitEntry.historiesCounter
			})
		}
	})

	const [preview, setPreview] = useState({
		showPreview: false,
		entry: {},
	}
	);

	const [histories, setHistories] = useState({
		drafts: [],
		page: 1,
		loadmore: true,
		total: 0,
	})

	const [loader, setLoader] = useState(false)

	const [saveEntryMutation] = useMutation(PROJECT_UPDATE_MUTATION, {
		awaitRefetchQueries: true,
		onCompleted: () => {
			hide();
			setEntry({
				id:preview.entry.id,
				text:preview.entry.text,
				original:preview.entry.text
			})
		},
		variables: {
			id: entryId,
			entryText: preview.entry.text
		}
	});

	const restoreEntry = () => {
	
		setLoader(true);
		saveEntryMutation();
	}

	const showPreview = (entry, showPreview) => {
		setPreview({
			entry: entry,
			showPreview: showPreview
		})
	}

	const loadMoreEntries = () => {
		client.query({
			query: ENTRY_HISTORY_QUERY,
			variables: {
				entryId: entryId,
				page: histories.page + 1,
			}
		}).then(data => {
			setHistories(
				prevState => {
					return {
						...prevState,
						drafts: prevState.drafts.concat(data.data.unitEntry.histories),
						page: histories.page + 1,
						loadmore: true,
					}
				}
			)
		}
		)
	}

	return (
		<div className="confirm-overlay"
			onClick={(event) => {
				if (event.target.classList.contains('confirm-overlay')) {
					hide();
				}
			}}
		>
			<div className="history">
				<div className="history__close" onClick={() => hide()}>x</div>
				<div className="history__inner">
					<div className="history__header">
						<BoomerHeading showBoomer={true} mainHeading={"Wow! Check out all the edits you’ve made!"} subHeading="Browse through your entry history and restore old versions of your work!" />
					</div>
					<div className="history__main">
						<div className={`history__list-panel ${preview.showPreview && 'history__list-panel--hide'}`}>
							<ul className={`history__list`}>
								{loading && <div className="u-align-center"><img src={spinner} alt="Loading..." /></div>}
								{error && <p>Uh oh, something went wrong</p>}
								{histories.drafts.length !== 0 &&
									_.map(histories.drafts, entry => {
										return (
											<li className={`history__entry ${entry.id === preview.entry.id && 'history__entry--selected'}`} onClick={() => showPreview(entry, true)} key={`entryHistory${entry.id}`}>
												<p>Entry saved at <span className="u-bold">{moment(entry.actionDate).format("h:mm a Do MMMM, YYYY")}</span></p>
												<span className="history__entry-view u-bold">{entry.id === preview.entry.id ? 'Viewing...' : 'View'}</span>
											</li>
										)
									})
								}
							</ul>
							<div className={`history__preview`}>
								{histories.drafts.length < (histories.total - 1) ?
									<button className="button button--bordered u-full-width" onClick={() => loadMoreEntries()}>Load More</button> :
									null
								}
							</div>
						</div>
						<div className={`history__entry-preview ${preview.showPreview && 'history__entry-preview--show'}`}>
							<ul className="history__list history__list--preview history__hide-desktop">
								<li className="history__entry history__entry--selected">
									<p>Entry saved at <span className="u-bold">2:25 pm June 26th, 2019</span></p>
									<span className="history__entry-view u-bold">Viewing...</span>
								</li>
							</ul>
							<div className="history__text">
								{preview.showPreview ?
									<div className="history__entry-holder" dangerouslySetInnerHTML={entryMarkup(preview.entry.text)} /> :
									<div className="history__entry-holder history__entry-holder--placeholder">
										<p>Click on the left to preview and restore an old version</p>
									</div>
								}
							</div>
							<div className={`history__preview history__preview--double`}>
								<button onClick={() => showPreview({}, false)} className="button button--bordered history__hide-desktop">Back</button>
								{preview.entry.id ?
									loader ? <button className="button button--bordered button--green" disabled>Restoring...</button> : <button className="button button--bordered button--green" onClick={() => restoreEntry()}>Restore</button>
									:
									<button className="button button--bordered button--green" disabled>Restore</button>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EntryHistory;