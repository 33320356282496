import React, { useState, useEffect } from 'react';
import ReactPageScroller from 'react-page-scroller';
import { useMeasure } from 'react-use';
import LottieSlide from './LottieSlide';
import { ChevronDown, Loader } from 'react-feather';

import animationDataWriting from '../../../images/slidescroller/computer_writing.json';
import animationDataBook from '../../../images/slidescroller/book.json';
import animationDataTyping from '../../../images/slidescroller/typing.json';
import animationDataVote from '../../../images/slidescroller/vote.json';
import animationDataChecklist from '../../../images/slidescroller/checklist.json';
import animationDataBookStars from '../../../images/slidescroller/book_stars';
import animationDataBookEnd from '../../../images/slidescroller/book_thats_it';
import styles from './SlideScroller.module.scss';

const WritingScroller = ({exit, writingClosed = false}) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [blockScroll, setBlockScroll] = useState(false);
    const [seenAnim, setSeenAnim] = useState(false);

    const [wrapperRef, { width }] = useMeasure();

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    useEffect(() => {
        if (currentPage === 1 && !seenAnim) {
            setBlockScroll(true);
        }
    }, [currentPage, seenAnim, setBlockScroll]);

    const scrollerHeight = window.innerWidth < 750 ? window.innerHeight - 140 : window.innerHeight - 50;


    const renderScrollBtn = (waitForAnim) => {
        const wait = waitForAnim && blockScroll;
        return (
            <button disabled={wait} onClick={() => handlePageChange(currentPage + 1)} className={styles.scrollBtn}>
                {wait ?
                    <div className={`${styles.circleInner} ${styles.animatedSpin}`}><Loader /></div>
                    :
                    <>
                        <div className={`${styles.circleInner}`}><ChevronDown /></div>
                        <div className={`${styles.circleOuter} ${styles.animatedZoomIn}`}></div>
                    </>
                }
            </button>
        );
    }

    return (
        <>

            <div ref={wrapperRef} className={styles.sliderWrapper}>
                <ReactPageScroller
                    containerWidth={width}
                    containerHeight={scrollerHeight}
                    pageOnChange={handlePageChange}
                    customPageNumber={currentPage}
                    renderAllPagesOnFirstRender={true}
                    blockScrollDown={blockScroll}
                >

                    <div className={`${styles.slide} ${styles.sideContent} ${styles.slideIntro}`} tabIndex="0" ref={(input) => input && input.focus()}>
                        <LottieSlide
                            svgWrapperStyle={styles.svgSide}
                            loop={true}
                            animationData={animationDataBookStars}
                            title={{ text: writingClosed ? 'Writing for this chapter has finished. Checkout the process below for future chapters!' : 'You’re about to begin the fantastic journey to become a published writer!', position: 'left' }}
                        />
                        {renderScrollBtn()}
                    </div>

                    <div className={`${styles.slide} ${styles.slideWhite}`}>
                        <div className={styles.svgTyping}>
                            <LottieSlide
                                animationData={animationDataWriting}
                                lottieStyle={{ marginTop: '40px' }}
                                speed={1.5}
                                eventListeners={[
                                    {
                                        eventName: 'complete',
                                        callback: () => {
                                            setBlockScroll(false);
                                            setSeenAnim(true);
                                        }
                                    },
                                ]}
                            />
                        </div>
                        {renderScrollBtn(true)}
                    </div>

                    <div className={`${styles.slide} ${styles.centerContent}`}>
                        <h2 className={styles.titleSingle}>It's really easy</h2>
                        {renderScrollBtn()}
                    </div>

                    <div className={`${styles.slide} ${styles.sideContent} ${styles.slideWhite}`}>
                        <LottieSlide
                            loop={true}
                            svgWrapperStyle={styles.svgSide}
                            animationData={animationDataBook}
                            title={{ text: '1. Read', position: 'right' }}
                        />
                        {renderScrollBtn()}
                    </div>

                    <div className={`${styles.slide} ${styles.sideContent} ${styles.slideGreen}`}>
                        <LottieSlide
                            svgWrapperStyle={styles.svgSide}
                            loop={true}
                            animationData={animationDataTyping}
                            title={{ text: '2. Write', position: 'left' }}
                        />
                        {renderScrollBtn()}
                    </div>

                    <div className={`${styles.slide} ${styles.sideContent} ${styles.slideWhite}`}>
                        <LottieSlide
                            svgWrapperStyle={styles.svgSide}
                            loop={true}
                            animationData={animationDataVote}
                            title={{ text: '3. Vote', position: 'right' }}
                        />
                        {renderScrollBtn()}
                    </div>

                    <div className={`${styles.slide} ${styles.centerContent} ${styles.slideRed}`}>
                        <LottieSlide
                            lottieStyle={{ maxWidth: '350px' }}
                            animationData={animationDataBookEnd}
                            title={{ text: 'That\'s it: you\'re published in  a real paperback book!', position: 'bottom' }}
                        />
                        {renderScrollBtn()}
                    </div>

                    <div className={`${styles.slide} ${styles.centerContent} ${styles.slideWhite}`}>
                        <LottieSlide
                            lottieStyle={{ maxWidth: '350px' }}
                            animationData={animationDataChecklist}
                            title={{ text: 'Listen to your teacher’s instructions and try your best!', position: 'bottom' }}
                            buttonTxt='Read the story start'
                            onBtnClick={() => exit(true)}
                            writingClosed={writingClosed}
                        />
                    </div>
                </ReactPageScroller>
            </div>
        </>
    );
}

export default WritingScroller;
