import React, { useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useIdleTimer } from 'react-idle-timer'
import { MAIN_LOGIN_QUERY } from '../queries';
import UserRouter from './User/UserRouter';
import Store from './Boomer/Store';
import UserSubscription from './Utils/UserSubscription';
import NewDashboard from './NewDashboard/NewDashboard'
import GroupDashboard from './NewDashboard/GroupDashboard/GroupDashboard';
import ProjectDashboard from './NewDashboard/ProjectDashboard/ProjectDashboard'
// import MainMenu from './Shared/MainMenu';
import ErrorPage from './Shared/ErrorPage';
import MainLoader from './Shared/MainLoader';
import UnitRouter from './NewDashboard/UnitRouter';
import Calendar from './Calendar/Calendar';
// import ChatHolder from './User/Chat/ChatHolder';
// import { API_WS_ROOT } from '../utils/chatConstants';
import IdleModal from './IdleModal/IdleModal';
import NavBar from './NavBar/NavBar';

import DemoScroller from './Project/SlideScroller/ScrollerDemoOnly';

const LoggedInRouter = ({ location }) => {
  const hideMenuMobile = location.pathname === "/user/profile/boomer" || location.pathname === "/user/profile/boomer" || (location.pathname.includes("/project/") && !location.pathname.includes("/user/"));
  const showMenu = location.pathname !== "/logout" || !hideMenuMobile;

  const { data, error, loading } = useQuery(MAIN_LOGIN_QUERY)/* First it queries the main login query, when it resolves it continues making queries */

  const [showIdleModal, setshowIdleModal] = useState(false);

  const handleOnIdle = event => {
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    setshowIdleModal(true)
  }

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500
  })


  return (
    <>
      {data && data.currentUser && <UserSubscription studentId={data.currentUser.id} />}
      {/* {data && data.currentUser && showMenu && <MainMenu mutation={hideMenuMobile && "main-menu--hidden-mobile"} user={data.currentUser} />} */}
      {data && data.currentUser && showMenu && <NavBar
        chatterId={data.currentUser.chatterId}
        premium={data.currentUser.premium}
        firstName={data.currentUser.firstName}
        avatarPath={data.currentUser.avatarPath}
        unreadMessagesCounter={data.currentUser.unreadMessagesCounter}
      />}
      {error && <p>{error.message}</p>}
      {loading && <MainLoader />}
      {showIdleModal && <IdleModal setshowIdleModal={setshowIdleModal} />}
      {data &&
        <Switch>
          <Route path="/user" component={UserRouter} />
          <Route path="/boomer" render={Store} />
          <Route path="/error" component={ErrorPage} />
          
          <Route path="/slider" component={DemoScroller} />

          <Route path="/calendar" render={() => <Calendar groups={data && data.currentUser.groups} />} />}
          <Route path='/dashboard' render={() => <NewDashboard currentUser={data && data.currentUser} />} />
          <Route path='/class/:classId/:term?' render={() => <GroupDashboard />} />
          <Route path="/project/:projectId/:columnTerm?" render={() => <ProjectDashboard />} />
          <Route path="/unit/:projectId/:unitIdx/:edit?" render={() => <UnitRouter showIdleModal={showIdleModal} />} />
          <Route path="/" render={() => <Redirect to="/dashboard" />} />
        </Switch>
      }
    </>
  );
}

export default withRouter(LoggedInRouter);

 // const client = useApolloClient();

    // const { data, error, loading } = useQuery(MAIN_LOGIN_QUERY, { /* First it queries the main login query, when it resolves it continues making queries */
    //     onCompleted: response => {
    //         console.log(response)
    //         ls("user", JSON.stringify(response.currentUser)); /* Saves on localstorage the current user response */
    //         client.writeData({ data: { userData: response.currentUser } }); /* write data from main login query to apollo cache */
    //         client.writeQuery({
    //             query: USER_DASHBOARD_DATA, // query doesnt exist anymore
    //             data: { userData: response.currentUser }
    //         });
    //         client.writeQuery({query: GET_GROUP_DATA,
    //             data: { userData: response.currentUser }})
    //         client.writeQuery({
    //             query: FIRST_NAME_QUERY,
    //             data: { userData: response.currentUser }
    //         });
    //         client.writeQuery({
    //             query: SIMPLE_BOOMER_QUERY,
    //             data: { userData: response.currentUser }
    //         });
    //     }
    // });