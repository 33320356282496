import React from 'react';
import youtube from './../../../../../../images/newDashboard/icons/youtube.png'
import styles from './IntroVideoButton.module.scss'
import closeIcon from '../../../../../../images/icon-close--circle.svg'

const IntroVideoButton = ({ setshowVideoModal, hide, setHide, isRejected }) => {
  return (

    <div className={`${styles.introVideoButton} ${hide && styles.hide} ${isRejected && styles.shiftRight}`} >
    <img onClick={()=>{setHide(true)}}className={styles.closeIcon} src={closeIcon} alt="close" />
      <div onClick={() => setshowVideoModal(true)} className={styles.content}>
        <img src={youtube} alt='youtube icon' />
        Introduction Video
      </div>
    </div>
  );
}

export default IntroVideoButton;