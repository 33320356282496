import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import MainLoader from '../../Shared/MainLoader';
import { PORTFOLIO_ENTRY_READ } from '../../../queries';
import { REVIEW, STORY_WRITER, WRITING, SCHEDULED, DRAFT, ARCHIVED, START_NEXT } from '../../../utils/globals';
import caution from '../../../images/caution.png';
import star from '../../../images/star.png';
import check from '../../../images/check-green.png';
import notes from '../../../images/notes.png';
import attachments from '../../../images/attachments.png';
import vocab from '../../../images/vocab.png';
import close from '../../../images/close-icon.png';
import info from '../../../images/info-icon.png';
import ViewWinner from './ViewWinner';
import arrowLeft from '../../../images/arrowLeftPurple.svg';
import arrowRight from '../../../images/arrowRightPurple.svg';
// import chat from '../../../images/chat.png';
// import TicklesModal from '../Tickles/TicklesModal';
// import AddTickleModal from '../Tickles/AddTickleModal';
import FullRubric from '../Grading/FullRubric'
import SideRubric from '../Grading/SideRubric'

const UnitReview = ({ history, projectId, unitIndex, displayIdx, isStory, units, scrollUnits }) => {
    const [state, setState] = useState({
        showInstructions: false,
        showWinner: false,
        selectedUnit: null,
        showTicklesModal: false,
        showAddTickleModal: false
    });
    
    const [showFullRubric, setShowFullRubric] = useState(false);
    
    const currentUnit = units[displayIdx];

    const [ showArrows, setShowArrows ] = useState(false)
    const [ canScroll, setCanScroll] = useState({back:false, forward:false})
    
    useEffect(()=>{  
        
    let unitsArray = units.filter(i => 
    i.phase !== SCHEDULED &&
    i.phase !== DRAFT &&
    i.phase !== ARCHIVED &&
    i.phase !== START_NEXT
    )
    //filter story start if aplicable
    if(isStory){unitsArray.shift()}
  
    // isolate indexes, disregard all other keys
    const unitsArray2=unitsArray.map(i=>i.index-1)

    //ascertain which index within the new array is the index of the currently displayed unit
    const currentIdx = unitsArray2.findIndex(i => parseInt(i)===parseInt(displayIdx))

    //check index againt array to see if it is possible to scroll in either direction
    let forward
    let back
    if(unitsArray.length>1){
      if(currentIdx<unitsArray.length-1){forward=true}else{forward=false}
      if(currentIdx>0){back=true}else{back=false}
      setCanScroll({forward, back})
      setShowArrows(true)
    }

  },[displayIdx])


    const showWinner = () => {
        setState(prevState => {
            return {
                ...prevState,
                showWinner: true,
            }
        });
    }

    const toggleInstructions = () => {
        setState(prevState => {
            return {
                ...prevState,
                showInstructions: !state.showInstructions,
            }
        });
    }

    const selectUnit = event => {
        let selectedUnit = data.project.units[event.target.value-1]
        checkEdit(selectedUnit)
        // history.push(`/unit/${projectId}/${event.target.value}`);
    }
    const checkEdit = selectedUnit => {
        let {phase,currentUserEntry,deadline} = selectedUnit
        let isRejected = currentUserEntry && currentUserEntry.rejected && (currentUserEntry.revisionStatus === 1 || currentUserEntry.revisionStatus === 2);
        const actualDate = new Date()

        let days = Date.parse(deadline) - Date.parse(actualDate)

        days = Math.trunc(days / (60 * 60 * 24 * 1000))

        if (phase === WRITING) {
      // if(!currentUserEntry.hasStarted) return `/unit/${projectId}/${idx + 1}`
      if (currentUserEntry.approved) return history.push(`/unit/${projectId}/${selectedUnit.index}`)
      if (isRejected) return history.push(`/unit/${projectId}/${selectedUnit.index}/edit`)
      if (currentUserEntry.submitted) history.push(`/unit/${projectId}/${selectedUnit.index}/edit`)
      return `error on redirecting`
    }
    if (phase === REVIEW) {

      if (currentUserEntry.approved) return history.push(`/unit/${projectId}/${selectedUnit.index}`)
      if (isRejected) return history.push(`/unit/${projectId}/${selectedUnit.index}/edit`)
      if (currentUserEntry.submitted) return history.push(`/unit/${projectId}/${selectedUnit.index}/edit`)
      if (days <= 0) return history.push(`/unit/${projectId}/${selectedUnit.index}/edit`)
    }
    return  history.push(`/unit/${projectId}/${selectedUnit.index}`)
    }


    // const onShowAddTickleModal = (unit) => {
    //     setState(prevState => {
    //         return {
    //             ...prevState,
    //             showAddTickleModal: true,
    //             selectedUnit: unit
    //         }
    //     });
    // }
    
    // const [onShowTicklesModal] = useMutation(SEE_TICKLES_MUTATION, {
    //     awaitRefetchQueries: true,
    //     refetchQueries: () => [{ query: PORTFOLIO_ENTRY_READ, variables: { id: projectId } }],
    // });

    // const onCloseAddTickleModal = () => {
    //     setState({
    //         showAddTickleModal: false
    //     });
    // }
    
    // const onCloseTickleModal = () => {
    //     setState({
    //         showTicklesModal: false
    //     });
    // }

    const editUnit = event => {
        history.push(`/project/${projectId}/${unitIndex}/edit`);
    }

    const renderDropdown = (units, selected, type) => {
        return (
            <div className="portfolio-read-chapter__dropdown-container">
                <select onChange={selectUnit} name="select-unit" className="dropdown portfolio-read-chapter__dropdown" value={selected}>
                    {
                        _.map(units, unit => {
                            if (!unit.currentUserEntry) {
                                return null
                            }
                            return (
                                <option key={unit.index} value={unit.index}>{type === STORY_WRITER ? `Chapter ${unit.index}` : unit.title}</option>
                            );
                        })
                    }
                </select>
            </div>  
        );
    }

    const renderTabs = (unit, projectType) => {
        const { currentUserEntry, index, winner } = unit;
        if (index === 1 && projectType === STORY_WRITER) {
            return null;
        }
        if (Number(currentUserEntry.authorId) === Number(winner.studentId)) {
            return (
                <div className="portfolio-read-chapter__winning-entry"><img className="portfolio-read-chapter__star" src={star} alt="star" />You have Won!</div>
            )
        }
        return (
            <span className="winning-entry-slider">
                <button 
                    onClick={() => {
                        setState(prevState => {
                            return { ...prevState, showWinner: true }
                        });
                    }} 
                    className={`button button--white ${state.showWinner ? 'active' : ''}`}
                >
                    Winning Entry
                </button>
                <button 
                    onClick={() => {
                        setState(prevState => {
                            return { ...prevState, showWinner: false }
                        });
                    }} 
                    className={`button button--white ${state.showWinner ? '' : 'active'}`}
                >
                    My Entry
                </button>
            </span>
        );
    }

    const renderUserEntry = unit => {
        const { title, currentUserVoted, currentUserEntry: { entryText, submitted, author: { firstName, lastName } } } = unit;
        const warning = `${submitted ? '' : 'You did not submit an entry'}`;
        return renderEntry(`${firstName} ${lastName}`, entryText, title, warning, true, currentUserVoted, submitted);
    }

    const renderEntry = (author, entryText, title, warning, currentUser = false, currentUserVoted = false, submitted) => {
        return (
            <div className="portfolio-read-chapter__entry-holder">
                {currentUser ? 
                    (
                        <div className="report-read__flags clearfix">
                            {submitted && <p className="report-read__status"><img className="report-read__status-img" src={check} alt="Yes"/>Turned In</p>}
                            {currentUserVoted && <p className="report-read__status"><img className="report-read__status-img" src={check} alt="Yes"/>You voted</p>}
                        </div>
                    )
                    :
                    (
                        <>
                            <p className="portfolio-read-chapter__chapter-title u-m-base-5">{title}</p>
                            <p className="portfolio-read-chapter__author">By {author}</p>
                        </>
                    )
                }
                <button className="portfolio__instructions-btn" onClick={() => toggleInstructions()}><img src={info} alt="i" className="portfolio__info"/>Instructions</button>
                {warning && 
                    <>
                        <p className="portfolio-read-chapter__warning"><img className="portfolio-read-chapter__warning-img" src={caution} alt="caution" />{warning}</p>
                    </>
                }
                {entryText !== "" && <div className="portfolio-read-chapter__entry-text" dangerouslySetInnerHTML={{ __html: entryText }} />}
                {!submitted && currentUser ? 
                    <button className="button u-m-top-20" onClick={() => editUnit()}>{entryText === "" ? "Write an Entry" : "Continue this entry."}</button>
                    : null
                }
            </div>
        )
    }

    const renderFeedback = userEntry => {
        const { currentFeedback, feedbackHistory } = userEntry;
        if (currentFeedback || feedbackHistory.length) {
            return (
                <div className="portfolio-read-chapter__feedback-holder u-m-base-20">
                    {currentFeedback && (
                        <p className="portfolio-read-chapter__current-feedback">
                            <span className="portfolio-read-chapter__teacher">Teacher's Comments:</span> {currentFeedback}
                        </p>
                    )}
                    {feedbackHistory.length !== 0 && (
                        <div className={`portfolio-read-chapter__old-feedback-holder ${currentFeedback ? 'portfolio-read-chapter__old-feedback-holder--bordered' : ''}`}>
                            {!currentFeedback && <p className="portfolio-read-chapter__teacher u-m-base-10">Teacher's Comments:</p>}
                            {_.map(feedbackHistory, feedback => {
                                return (
                                    <div key={feedback.date} className="portfolio-read-chapter__old-feedback">
                                        <p className="portfolio-read-chapter__old-feedback-text"><span className="portfolio-read-chapter__date">{moment(feedback.date).fromNow()}:</span> {feedback.description}</p>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            )
        }
        return null;
    }

    const escapeVocab = vocab => {
        return vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); 
    }

    const getVocabUsed = (entryText, vocabs) => {
        const vocabUsed = [];
        _.each(vocabs, vocab => {
            const exp = RegExp('\\b(' + escapeVocab(vocab.word) + ')\\b', 'gi');
            if (_.words(entryText, exp).length !== 0) {
                vocabUsed.push(vocab.word);
            };
        });

        return vocabUsed;
    }

    const checkWinner = (unit, project) => {
        if (!unit.currentUserEntry.hasViewedWinner) {
            return <ViewWinner project={project} winner={unit.winner} userEntry={unit.currentUserEntry} showWinner={showWinner} />
        }
        return null
    }

    const renderContent = project => {
        const { units } = project;
        // const { showTicklesModal, showAddTickleModal } = state;
        const unit = units[displayIdx];
        const vocabUsed = getVocabUsed(unit.currentUserEntry.entryText, unit.vocab);
        const showInfoPanel = !(project.type === STORY_WRITER && unit.index === 1) && (unit.instructions || unit.vocab.length > 0 || unit.documents.length > 0 || unit.currentUserEntry.rubric);
        
        return (
            <>
                {showFullRubric && <FullRubric rubric={unit.currentUserEntry.rubric} showFullRubric={setShowFullRubric} showScore={unit.shareGrading} />}
                <div className="portfolio-read-chapter">
                    <div className="portfolio-read-chapter__layout">
                        <div className="portfolio-read-side">
                            <div className="light-panel portfolio-side">
                                <h3 className="report-side__title">{project.title}</h3>
                                <p className="portfolio-side__teacher"><img className="portfolio-side__ava" src={project.teacher.profilePicturePath} alt="Avatar" />{project.teacher.displayName}</p>
                                {project.schools = null}
                                {project.schools && project.schools.length > 0 ? <p className="project-meta">{project.schools[0].name}</p> : null}
                                {project.completed && <p className="u-pill u-pill--success u-m-top-10"><img className="u-pill__image" src={check} alt="check" />Project Complete</p>}
                            </div>
                            {showInfoPanel && (
                            <div className={`report-instructions ${state.showInstructions ? null : 'report-instructions--hidden'} clearfix`} onClick={() => toggleInstructions()}>
                                <button className="report-instructions__close"><img src={close} alt="Close" className="report-instructions__close-icon"/></button>
                                <div className="report-instructions__inner">
                                    {unit.instructions &&
                                        <div className="u-m-top-20 clearfix">
                                            <img src={notes} alt="Notes" className="report-instructions__img" />
                                            <div className="report-instructions__body">
                                                <h3 className="report-instructions__heading">Guidance Notes</h3>
                                                <p>{unit.instructions}</p>
                                            </div>
                                        </div>
                                    }
                                    {(unit.currentUserEntry.rubric && !state.showWinner) && <SideRubric showScore={unit.shareGrading} rubric={unit.currentUserEntry.rubric} showFullRubric={setShowFullRubric} source="review" modifier="u-m-top-20" />}
                                    {unit.vocab.length > 0 &&
                                        <div className="u-m-top-20 clearfix">
                                            <img src={vocab} alt="Vocab" className="report-instructions__img" />
                                            <div className="report-instructions__body">
                                                <h3 className="report-instructions__heading">Wordbank</h3>
                                                <ul className="clearfix">
                                                    {_.map(unit.vocab, vocab => {
                                                        return (
                                                            <li key={vocab.id} className={`instruction__vocab ${_.includes(vocabUsed, _.toLower(vocab.word)) && 'pulse instruction__vocab--used'}`}>{vocab.word}</li>
                                                        )
                                                    })}
                                                </ul>
                                                <p className="u-m-top-10">Vocab Used: {vocabUsed.length}/{unit.vocab.length}</p>
                                            </div>
                                        </div>
                                    }
                                    {unit.documents && unit.documents.length > 0 &&
                                        <div className="u-m-top-20 clearfix">
                                            <img src={attachments} alt="Attachments" className="report-instructions__img" />
                                            <div className="report-instructions__body">
                                                <h3 className="report-instructions__heading">Useful Attachments</h3>
                                                <ul>
                                                    {_.map(unit.documents, doc => {
                                                        return (
                                                            <li key={doc.id}>
                                                                <a href={doc.url} target="_blank" rel="noopener noreferrer" className="instruction__doc"><span className="instruction__doc-name">{doc.name}</span><span className="instruction__doc-read">Read</span></a>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            ) }
                        </div>
                        <div className="portfolio-read-chapter__content">
                            <div className="portfolio-read-chapter__entry-area">
                                <div className="portfolio-read-chapter__switch-buttons u-m-base-20">
                                    <div className="portfolio-read-chapter__buttons">
                                     <span className="unit-review-scroll-arrows">
                                        {showArrows&& <img className={canScroll.back ? "arrow" :  "arrow disable" }onClick={()=>scrollUnits('back')} src={arrowLeft} alt="arrow left"/>}
                                        <p className="project-entry-header__title">{units[displayIdx].title}</p>
                                        {showArrows && <img className={canScroll.forward ? "arrow" :  "arrow disable" }onClick={()=>scrollUnits('forward')} src={arrowRight} alt="arrow right"/>}
                                     </span>

                                     <p className="unit-review-word-count">
                                         Word Count: <strong> {currentUnit.currentUserEntry.wordsCount} /{currentUnit.wordLimit}</strong>
                                         {/* Word Count: <strong> {countWords(currentUnit.currentUserEntry.entryText)} /{currentUnit.wordLimit}</strong> */}
                                    </p>

                                    {renderTabs(unit, project.type)}

                                    </div>
                                    {/* {units.length !== 1 && project.currentUnit !== 1 ? renderDropdown(units, unit.index, project.type) : null} */}
                                </div>
                                {
                                    state.showWinner ? 
                                    renderEntry(unit.winner.name, unit.winner.entryText, project.type === STORY_WRITER ? `Chapter ${unit.index}` : unit.title) : (
                                        unit.teacherUnit ? 
                                        renderEntry(unit.storyStart.authorName, unit.storyStart.content, project.type === STORY_WRITER ? `Chapter ${unit.index}` : unit.title) : (
                                        <>
                                            {renderFeedback(unit.currentUserEntry)}
                                            {renderUserEntry(unit)}
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {unit.winner.id && checkWinner(unit, project)}
                </div>
            </>
        );
    }

    const { data, error, loading } = useQuery(PORTFOLIO_ENTRY_READ, {
        variables: {
            id: projectId
        }
    });

    if (loading) {
        return <MainLoader />
    }
    if (error) {
        return <p>{error.message}</p>
    }
    if (data && data.project) {
        return renderContent(data.project);
    }
    return null;
}

export default withRouter(UnitReview);
