import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// import Dashboard from './Dashboard/Dashboard';
// import Portfolio from './Portfolio/Portfolio';
import ProfileRouter from './Profile/ProfileRouter';

const UserRouter = ({ location }) => {
    const isDash = location.pathname === "/user/dashboard"
    const isClass = location.pathname.includes("/user/class");
    const isProfile = location.pathname.includes("/user/profile");
    const isBoomer = location.pathname === "/user/profile/boomer";
    return (
        <Fragment>
            <div className={`user-view ${(isDash || isClass) && 'user-view--dash'} ${isProfile ? (isBoomer ? 'user-view--has-sub-menu user-view--fullscreen-mob' : 'user-view--has-sub-menu user-view--profile') : ''}`}>
                <Switch>
                    {/* <Route path="/user/class/:classId" component={Dashboard}/> 
                    <Route path="/user/dashboard" component={Dashboard} />
                    <Route path="/user/mywork" component={Portfolio} /> */}
                    <Route path="/user/profile" component={ProfileRouter} />
                    <Route path="/" render={() => <Redirect to="/dashboard" />} />
                </Switch>
            </div>
        </Fragment>
    );
}

export default UserRouter;
