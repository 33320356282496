import React, { Fragment } from 'react';

import BoomerHeading from '../../Shared/BoomerHeading';

const Name = ({ student: { firstName, lastName, email, grade, studentCode } }) => (
    <Fragment>
        <div className="u-hidden-mobile">
            <BoomerHeading
                mainHeading="Your Details"
                mutation="boomer-desktop-only"
                showBoomer={true}
                subHeading="Profile Information"
            />
        </div>
        <div className="form-horizontal">
            <div className="basic-form__group">
                <label className="basic-form__label u-m-base-10">First Name:</label>
                <input
                    className={`basic-form__text-box basic-form__text-box--locked`}
                    name="firstName"
                    placeholder="First Name" 
                    type="text"
                    value={firstName}
                    disabled
                />
            </div>
            <div className="basic-form__group">
                <label className="basic-form__label u-m-base-10 ">Last Name:</label>
                <input
                    className={`basic-form__text-box basic-form__text-box--locked`}
                    name="lastName"
                    placeholder="Last Name" 
                    type="text"
                    value={lastName}
                    disabled
                />
            </div>
            {
                email && (
                    <div className="basic-form__group">
                        <label className="basic-form__label u-m-base-10 ">Username:</label>
                        <input
                            className={`basic-form__text-box basic-form__text-box--locked`}
                            name="lastName"
                            placeholder="Username" 
                            type="text"
                            value={email}
                            disabled
                        />
                    </div>
                )
            }
            <div className="basic-form__group">
                <label className="basic-form__label u-m-base-10 ">Grade:</label>
                <input
                    className={`basic-form__text-box basic-form__text-box--locked`}
                    name="grade"
                    placeholder="Grade" 
                    type="text"
                    value={grade}
                    disabled
                />
            </div>
            <div className="basic-form__group">
                <label className="basic-form__label u-m-base-10 ">Access Code:</label>
                <input
                    className={`basic-form__text-box basic-form__text-box--locked`}
                    name="accessCode"
                    placeholder="Access Code" 
                    type="text"
                    value={studentCode}
                    disabled
                />
            </div>
            <p className="basic-form__disclaimer">Contact your teacher if these details are wrong.</p>
        </div>
    </Fragment>
);

export default Name;