import { gql } from 'apollo-boost';
// Queries and mutations for the user profile page

// Get basic data to populate the form
export const GET_PROFILE_DATA = gql`
query getProfileDataQuery{
    currentUser {
        id
        firstName
        lastName
        email
        googleAccount
        grade
        studentCode
        groups {
            id
            name
            membershipStatus
            teachers {
                id
                name
                displayName
                profilePicturePath
            }
        }
        school {
            id
            name
            location
            colourCode
            logoImagePath
        }
        pendingSchool {
            id
            name
            location
            colourCode
            logoImagePath
        }
    }
}`;

export const GET_SUB_MENU_DATA = gql`
query getSubMentDataQuery{
    currentUser {
        id
        firstName
        lastName
        googleAccount
    }
}
`;


// USER VIEW MUTATIONS
export const EDIT_PROFILE = gql`
mutation editProfile($firstName: String, $lastName: String) {
    updateUser(firstName: $firstName, lastName: $lastName) {
        errors
        student {
            id
            firstName
            lastName
            googleAccount
            grade
        }
    }
}
`;

export const EDIT_PASSWORD = gql`
mutation editPassword($existingPassword: String!, $newPassword: String!, $confirmNewPassword: String!) {
    resetPassword(existingPassword: $existingPassword, newPassword: $newPassword, confirmNewPassword: $confirmNewPassword) {
        errors
        student {
            id
        }
    }
}
`;

export const JOIN_SCHOOL = gql`
mutation joinSchool($schoolCode: String!) {
    joinSchool(schoolCode: $schoolCode) {
        errors
        student {
            id
            school {
                id
                name
                location
                colourCode
                logoImagePath
            }
            pendingSchool {
                id
                name
                location
                colourCode
                logoImagePath
            }
        }
    }
}
`;

export const JOIN_CLASS = gql`
mutation joinClass($classCode: String!) {
    joinClass(classCode: $classCode) {
        errors
        student {
            id
            school {
                id
                name
                location
                colourCode
                logoImagePath
            }
            pendingSchool {
                id
                name
                location
                colourCode
                logoImagePath
            }
        }
    }
}
`;

export const CANCEL_JOIN_SCHOOL = gql`
mutation cancelJoinSchool($schoolId: ID!) {
    cancelJoinSchool(schoolId: $schoolId) {
        errors
        student {
            id
            school {
                id
                name
                location
                colourCode
                logoImagePath
            }
            pendingSchool {
                id
                name
                location
                colourCode
                logoImagePath
            }
        }
    }
}
`;

export const LEAVE_SCHOOL = gql`
mutation leaveSchool($schoolId: ID!) {
    leaveSchool(schoolId: $schoolId) {
        errors
        student {
            id
            school {
                id
                name
                location
                colourCode
                logoImagePath
            }
            pendingSchool {
                id
                name
                location
                colourCode
                logoImagePath
            }
        }
    }
}
`;