import React, { useState, useEffect } from 'react';
import ReactPageScroller from 'react-page-scroller';
import { useMeasure } from 'react-use';
import LottieSlide from './LottieSlide';
import { ChevronDown, Loader } from 'react-feather';


import animationTeacherBook from '../../../images/slidescroller/teacher_book';
import animationDataAppearInBook from '../../../images/slidescroller/appear_in_book'
import animationDataPA from '../../../images/slidescroller/published_author';
import styles from './SlideScroller.module.scss';
import yourBook from '../../../images/slidescroller/your_book.png';

const PublishScroller = ({exit, winner = null, alternate = null, oppo = false,history}) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [blockScroll, setBlockScroll] = useState(false);
    const [seenAnim, setSeenAnim] = useState(true);

    const [wrapperRef, { width }] = useMeasure();

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    useEffect(() => {
        if (currentPage === 1 && !seenAnim) {
            setBlockScroll(true);
        }
    }, [currentPage, seenAnim, setBlockScroll]);

    const scrollerHeight = window.innerWidth < 750 ? window.innerHeight - 140 : window.innerHeight - 50;


    const renderScrollBtn = (waitForAnim) => {
        const wait = waitForAnim && blockScroll;
        return (
            <button disabled={wait} onClick={() => handlePageChange(currentPage + 1)} className={styles.scrollBtn}>
                {wait ?
                    <div className={`${styles.circleInner} ${styles.animatedSpin}`}><Loader /></div>
                    :
                    <>
                        <div className={`${styles.circleInner}`}><ChevronDown /></div>
                        <div className={`${styles.circleOuter} ${styles.animatedZoomIn}`}></div>
                    </>
                }
            </button>
        );
    }

    return (
        <>

            <div ref={wrapperRef} className={styles.sliderWrapper}>
                <ReactPageScroller
                    containerWidth={width}
                    containerHeight={scrollerHeight}
                    pageOnChange={handlePageChange}
                    customPageNumber={currentPage}
                    renderAllPagesOnFirstRender={true}
                    blockScrollDown={blockScroll}
                >

                    <div className={`${styles.slide} ${styles.sideContent} ${styles.slideIntro}`} tabIndex="0" ref={(input) => input && input.focus()}>
                        <LottieSlide
                            svgWrapperStyle={styles.svgSide}
                            loop={true}
                            animationData={animationDataPA}
                            title={{ text: 'Boom! You’ll soon be a published writer', position: 'left' }}
                        />
                        {renderScrollBtn()}
                    </div>

                    <div className={`${styles.slide} ${styles.centerContent} ${styles.slideWhite}`}>
                        <LottieSlide
                            lottieStyle={{ maxWidth: '350px' }}
                            animationData={animationDataAppearInBook}
                            title={{ text: 'Tell the world and make sure you hold on to your BoomWriter book - it may be worth a fortune when you’re a famous writer in the future!', position: 'bottom' }}
                        />
                        {renderScrollBtn()}
                    </div>

                    <div className={`${styles.slide} ${styles.centerContent} ${styles.slideRed}`}>
                        <LottieSlide
                            lottieStyle={{ maxWidth: '350px' }}
                            animationData={animationDataAppearInBook}
                            img={yourBook}
                            alternate={alternate}
                            title={{ text: winner ? 'Your personalized  book has your name on the cover and your writing inside.' : alternate ? <>Your unique, personalized book has your name on the cover. Plus your own alternative ending.<br/><br />(because your ending is great too!)</> : 'Your unique book features your name on the cover along with the story that you helped create.' , position: 'bottom' }}
                        />
                        {renderScrollBtn()}
                    </div>

                    <div className={`${styles.slide} ${styles.centerContent}`}>
                        <LottieSlide
                            lottieStyle={{ maxWidth: '350px' }}
                            animationData={animationTeacherBook}
                            title={{ text: 'Ask your teacher how you can get a copy of the book', position: 'bottom' }}
                            buttonTxt={'Continue'}
                            onBtnClick={() => history.push(`/`)}
                        />
                    </div>
                </ReactPageScroller>
            </div>
        </>
    );
}

export default PublishScroller;
