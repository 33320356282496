import _ from 'lodash';
import moment from 'moment';
import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-selectionsaverestore';

// Project Types
export const STORY_WRITER = 3;
export const WORD_WRITER = 2;
export const PROJECT_WRITER = 1;

// Unit Phases
export const DRAFT = 1;
export const WRITING = 2;
export const REVIEW = 3;
export const VOTING = 4;
export const TIE_BREAK = 5;
export const COMPLETED = 7;
export const VOTING_COMPLETE = 8;
export const ARCHIVED = 9;
export const START_NEXT = 10;
export const SCHEDULED = 21;
export const AWAITING_START = 31;

// Tickle Types 
export const STARTED_TICKLE = 1;
export const SUBMITTED_TICKLE = 4;
export const VOTING_TICKLE = 2;
export const BADGE_TICKLE = 6;
export const USER_TICKLE = 7;

// USER RANKS
export const RANK1 = 100;
export const RANK2 = 200;

export const getProjectTypeString = (type) => {
  switch(type) {
    case STORY_WRITER:
      return "StoryWriter";
    case WORD_WRITER:
      return "WordWriter";
    case PROJECT_WRITER:
      return "ProjectWriter";
    default:
      return "";
  }
}

export const getUnitPhaseString = (phase, type) => {
  switch(phase) {
    case DRAFT:
      return "Draft";
    case WRITING:
      return "Writing";
    case REVIEW:
      return "Entries Under Review";
    case VOTING:
      return "Voting";
    case COMPLETED:
    case START_NEXT:
      return type === WORD_WRITER ? "Complete" : type === STORY_WRITER ? "Chapter Complete" : "Section Complete";
    default:
      return "";
  }
}

export const orderedProjectsDash = (setProjects, projects) => {
  let newProjects = _.filter(projects, project => {
    const { archived, currentUnitData } = project;
    return !archived && currentUnitData.phase !== DRAFT;
  });
  newProjects = newProjects.reverse();
  setProjects({
    ogProjects: newProjects,
    reorderedProjects: responsiveCheck(newProjects)
  });
}

export const orderedProjectsPortfolio = (setProjects, filteredProjects) => {
  let newProjects = _.filter(filteredProjects, project => {
    return project.currentUnitData.phase !== DRAFT;
  })
  newProjects = newProjects.reverse();
  setProjects({
    ogProjects: newProjects,
    reorderedProjects: responsiveCheck(newProjects)
  });
}

export const responsiveCheck = projects => {
  let winWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  if (winWidth < 700) {
      return reorderOnResize(projects, 1);
  } else if (winWidth < 750) {
      return reorderOnResize(projects, 2);
  } else if (winWidth < 840) {
      return reorderOnResize(projects, 1);                
  } else if (winWidth < 1000) {
      return reorderOnResize(projects, 2);
  } else if (winWidth < 1260) {
      return reorderOnResize(projects, 1);
  } else if (winWidth < 1600) {
      return reorderOnResize(projects, 2);
  } else if (winWidth >= 1600) {
      return reorderOnResize(projects, 3);
  }
}

export const reorderOnResize = (projects, cols) => {
  return reorder(projects, cols);
}

export const reorder = (arr, columns) => {
  const cols = columns;
  const out = [];
  let col = 0;
  while(col < cols) {
    for(let i = 0; i < arr.length; i += cols) {
      let _val = arr[i + col];
      if (_val !== undefined)
        out.push(_val);
    }
    col++;
  }
  return out;     
}

export const formattedDate = (dateObject) => {
  return moment(dateObject).format("Do MMMM, YYYY");
}

export const entryMarkup = (entryText) => {
  return {__html: entryText}
}

// Tickles
export const getTickleType = (phase) => {
  if (phase >= COMPLETED) {
      return [BADGE_TICKLE, USER_TICKLE]
  } else if (phase === VOTING || phase === TIE_BREAK) {
      return [VOTING_TICKLE]
  } else {
      return [STARTED_TICKLE, SUBMITTED_TICKLE]
  }
}


//vocab highlighter 

export const checkVocab = (view, editor) => {
  let vocab = view.state.vocab;
  let text = rangy.innerText(editor);
  let savedSelection = rangy.saveSelection();

  unwrap(find(editor, ":not(br):not(div):not(.rangySelectionBoundary"));

  remove(find(editor, ".tagvocab:empty"));
  removeBlanks(find(editor, ".tagvocab"));
  let tagApplier = rangy.createClassApplier("tagvocab");
  _.map(vocab, (word) => {
      tagMatch(editor, text, word, tagApplier);
  })
  rangy.restoreSelection(savedSelection);
  rangy.removeMarkers(savedSelection);
}

const tagMatch =(el, text, match, tagApplier) => {
    if (/\S/.test(match.word)) { 
        let entry = el;
        let txt = escapeRegExp(match.word);
        let re = new RegExp("\\b" + txt + "\\b", "i");
        applyTagMatch(entry, text, match.word, re, tagApplier);

        /*let pluralText = txt + "s";
        let plural = new RegExp("\\b" + pluralText + "\\b", "i");
        applyTagMatch(entry, text, pluralText, plural, tagApplier);*/
        _.map(find(entry, ".tag-vocab"), (tag) => {
            tag.setAttribute("unselectable", "on");
            /*tag.setAttribute("ContentEditable", "false");*/
        })
    }
}

const applyTagMatch = (entry, text, match, regExp, tagApplier) => {
    var start = text.search(regExp);
    while (start > -1) {
        var end = start + match.length;
        var range = rangy.createRange();
        range.selectCharacters(entry, start, end);
        tagApplier.applyToRange(range);
        var idx = text.substr(end).search(regExp);
        start = (idx > -1) ? idx + end : idx;
    }
}

const escapeRegExp = (str) => {
    return str.replace(/[-[]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

const removeBlanks = (items) => {
    _.map(items, (element) => {
        if (!/\S/.test(element.textContent))
            {
                return element.remove();
            }
    })
    return null;
}

const remove = (items) => {
    _.map(items, (element) => {
        element.remove()
    })
}

const find = (ele, target) => {
    var found_elements = [];
    var elements_in_outer = ele.querySelectorAll(target);
    elements_in_outer = Array.prototype.slice.call(elements_in_outer);            
    found_elements = found_elements.concat(elements_in_outer);
    return found_elements;       
}

const unwrap = (items) => {
        // select element to unwrap
    _.map(items, (element) => {
        var el = element;

        // get the element's parent node
        var parent = el.parentNode;

        // move all children out of the element
        while (el.firstChild) parent.insertBefore(el.firstChild, el);

        // remove the empty element
        parent.removeChild(el);
    })
}
